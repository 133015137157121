import React, { useState, useEffect } from 'react';
import { Button, Flex } from '@chakra-ui/react';

const PublishToggleButton = ({ initialPublished, defaultColor, index, activityIndex=undefined, handleToggleIndex=undefined, toggleActivityStatus=undefined }) => {
  const [isPublished, setIsPublished] = useState(initialPublished);

  const handlePublish = () => {
    
    if(!initialPublished && handleToggleIndex){
        setIsPublished(true);
        handleToggleIndex(index)
    }
    else if(!initialPublished && toggleActivityStatus){
        setIsPublished(true);
        toggleActivityStatus(index, activityIndex)
    }
  };

  const handleUnpublish = () => {
    if(initialPublished && handleToggleIndex){
        setIsPublished(false);
        handleToggleIndex(index)
    }
    else if(initialPublished && toggleActivityStatus){
        setIsPublished(false);
        toggleActivityStatus(index, activityIndex)
    }
  };

  useEffect(() => {
    setIsPublished(initialPublished);
  }, [initialPublished]);

  return (
    <Flex key={(index && activityIndex)?`${index}${activityIndex}`:`${index}`} maxW={"120px"} border="1px solid" borderColor="gray.300" borderRadius="md" overflow="hidden">
      <Button
        onClick={handlePublish}
        variant="ghost"
        flex="1"
        size='xs'
        px={2}
        borderRadius="none"
        borderRight="1px solid"
        borderColor="gray.300"
        fontWeight={isPublished ? '500' : "200"}
        bgColor={isPublished ? 'green.400' : 'none'}
        color={isPublished ? 'white' : defaultColor}
      >
        เผยแพร่
      </Button>
      <Button
        onClick={handleUnpublish}
        variant="ghost"
        flex="1"
        px={2}
        borderRadius="none"
        size='xs'
        fontWeight={!isPublished ? '500' : "200"}
        bgColor={!isPublished ? 'red.400' : 'none'}
        color={!isPublished ? 'white' : defaultColor}
      >
        ไม่เผยแพร่
      </Button>
    </Flex>
  );
};

export default PublishToggleButton;
