import React, { useState } from 'react';
import { Box, Button, Collapse, Flex, Icon, IconButton, ModalFooter, Stack, Table, Tbody, Td, Text, Th, Thead, Tr, useColorModeValue } from '@chakra-ui/react';
import { MdExpandMore, MdExpandLess } from 'react-icons/md'; // Import icons for expand and collapse
import { HSeparator } from 'components/Separator/Separator';
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import { IoAddCircle, IoBookOutline, IoHelpCircle, IoPencil, IoRemoveCircle, IoTrashBin, IoTrashBinOutline } from 'react-icons/io5';
import GradeLevelSelectionCard from './widgets/GradeLevelSelectionCard';
import SubjectSelectionCard from './widgets/SubjectSelectionCard';
import { EditIcon } from '@chakra-ui/icons';
import { BiRightArrow } from 'react-icons/bi';
import { FaArrowRight, FaEye, FaPencilAlt } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalCloseButton } from '@chakra-ui/react';
import Spreadsheet from 'react-spreadsheet';
import { gradeBookList } from 'variables/general';


const ClassModel = () => {
    const [openIndex, setOpenIndex] = useState(0);
    const borderColor = useColorModeValue("gray.300", "gray.600");
    const bgColor = useColorModeValue("gray.100", "blue.600");
    const secondaryColor = useColorModeValue("gray.800", "white");
    const history = useHistory();
    const [isModalOpen, setIsModalOpen] = useState(false);

    // Define dataArray internally
    const dataArray = [
        {
            title: 'คณิตศาสตร์มัธยมศึกษา',
            bookName: 'คณิตศาสตร์ ม.1 เล่ม 1',
            lessons: [
                { title: "บทเรียนที่ 1" },
                { title: "บทเรียนที่ 2" },
                { title: "บทเรียนที่ 3" },
                { title: "บทเรียนที่ 4" },
            ]
        },
        {
            title: 'ฟิสิกส์',
            bookName: 'ฟิสิกส์ ม.1 เล่ม 1',
            lessons: [
                { title: "บทเรียนที่ 1" },
                { title: "บทเรียนที่ 2" },
                { title: "บทเรียนที่ 3" },
                { title: "บทเรียนที่ 4" },
            ]
        },
        {
            title: 'เคมี',
            bookName: 'เคมี ม.1 เล่ม 1',
            lessons: [
                { title: "บทเรียนที่ 1" },
                { title: "บทเรียนที่ 2" },
                { title: "บทเรียนที่ 3" },
                { title: "บทเรียนที่ 4" },
            ]
        },
    ];

    const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

    const toggleCollapse = (index) => {
        setOpenIndex((prevIndex) => (prevIndex === index ? null : index));
    };

    const handleOpenExcel = () => {
        // Replace 'example.xlsx' with the path to your Excel file
        const excelPath = 'excel/grade.xlsx';
        
        // Open the Excel file in a new tab
        window.open(excelPath, '_blank');
      };

      const DataModal = ({ isOpen, onClose, data }) => {
        const [rows, setRows] = useState(data);
      
        const onChange = (changes) => {
          const newRows = rows.map((row) => [...row]);
          changes.forEach(({ row, col, value }) => {
            newRows[row][col] = value;
          });
          setRows(newRows);
        };
      
        return (
            <Modal size='full' isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader bgColor={"blue.600"} textAlign="center" color="white">
                Grade book สำหรับบทเรียนที่ 1
              </ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <Flex w="100%" overflow={"scroll"} justify={"center"}>
                  <Table maxW={"400px"} maxH={"200px"} mr={2} variant="striped">
                    <Tbody>
                      <Tr>
                        <Td>ชื่อชั้นเรียนx:</Td>
                        <Td>ชั้นเรียน 1A</Td>
                      </Tr>
                      <Tr>
                        <Td>ระดับชั้น:</Td>
                        <Td>มัธยมศึกษาปีที่ 1</Td>
                      </Tr>
                      <Tr>
                        <Td>วิชา:</Td>
                        <Td>คณิตศาสตร์</Td>
                      </Tr>
                      <Tr>
                        <Td>ชื่อบทเรียน:</Td>
                        <Td>บทเรียนที่ 1: พื้นฐานการบวกและการลบ</Td>
                      </Tr>
                      <Tr>
                        <Td>ผู้สร้างชั้นเรียน:</Td>
                        <Td>ครูสมศรี สุขสมบัติ</Td>
                      </Tr>
                    </Tbody>
                  </Table>
                    <Spreadsheet data={rows} onChange={onChange}></Spreadsheet>
                </Flex>
              </ModalBody>
              <ModalFooter>
                <Flex w="100%" justify={"center"}>
                  <Button variant='outline' colorScheme='green' mr={2}>Export CSV</Button>
                  <Button variant='outline' colorScheme='blue' onClick={onClose}>ออก</Button>
                </Flex>
              </ModalFooter>
            </ModalContent>
          </Modal>
        );
      };

    return (
        <Flex direction="column" pt={{ base: '65px', sm: '85px' }}>
            <DataModal isOpen={isModalOpen} onClose={closeModal} data={gradeBookList} />
            <Flex w="100%">
                <Flex w="80%" direction={"column"} mr={5}>
                    <Card>
                        <CardHeader>
                            <Flex justify={"space-between"}>
                                <Text>รายการต้นแบบรายวิชา</Text>
                                <Button leftIcon={<IoAddCircle />} colorScheme='blue' variant='outline'>เพิ่มต้นแบบรายวิชา</Button>
                            </Flex>
                            <HSeparator mt="5px" mb="5px" />
                        </CardHeader>
                        <CardBody>
                            {dataArray.map((item, index) => (
                                <Box key={index} mb={1} borderRadius="lg" overflow="hidden">
                                    <Flex
                                        onClick={() => toggleCollapse(index)}
                                        bg={openIndex === index ? 'blue.700' : 'blue.700'}
                                        color="white"
                                        fontWeight="bold"
                                        p={4}
                                        borderTopLeftRadius="5px"
                                        borderTopRightRadius="5px"
                                        cursor="pointer"
                                        justifyContent="space-between"
                                        alignItems="center"
                                    >
                                        <Box>
                                            <Text>{item.bookName}</Text>
                                        </Box>
                                        <Flex>
                                            <Text mr={5} fontWeight={400} fontSize={14}>{item.title}</Text>
                                            <Icon as={openIndex === index ? MdExpandLess : MdExpandMore} fontSize="20px" />
                                        </Flex>

                                    </Flex>
                                    <Collapse in={openIndex === index} style={{ width: '100%' }}>
                                        <Box bg={bgColor} p={4}>
                                            <p>
                                                <Box overflowX={{ sm: "scroll", lg: "hidden" }}>
                                                    <Table mb="10px" overflowX={{ sm: "scroll", lg: "hidden" }}>
                                                        <Thead>
                                                            <Tr>
                                                                <Th
                                                                    borderColor={borderColor}
                                                                    color="gray.400"
                                                                    fontSize="sm"
                                                                    fontWeight="normal"
                                                                    ps="0px"
                                                                >
                                                                    รายชื่อบทเรียน
                                                                </Th>
                                                                <Th
                                                                    textAlign={"center"}
                                                                    borderColor={borderColor}
                                                                    color="gray.400"
                                                                    fontSize="sm"
                                                                    fontWeight="normal"
                                                                >
                                                                    จำนวนชุดกิจกรรม
                                                                </Th>
                                                                <Th
                                                                    textAlign={"center"}
                                                                    borderColor={borderColor}
                                                                    color="gray.400"
                                                                    fontSize="sm"
                                                                    fontWeight="normal"
                                                                >
                                                                    Gradebook
                                                                </Th>
                                                                <Th
                                                                    textAlign={"center"}
                                                                    borderColor={borderColor}
                                                                    color="gray.400"
                                                                    fontSize="sm"
                                                                    fontWeight="normal"
                                                                    p={1}
                                                                >
                                                                    ลบ
                                                                </Th>
                                                                <Th
                                                                    textAlign={"center"}
                                                                    borderColor={borderColor}
                                                                    color="gray.400"
                                                                    fontSize="sm"
                                                                    fontWeight="normal"
                                                                    p={1}
                                                                >
                                                                    แก้ไข
                                                                </Th>
                                                                <Th
                                                                    textAlign={"center"}
                                                                    borderColor={borderColor}
                                                                    color="gray.400"
                                                                    fontSize="sm"
                                                                    fontWeight="normal"
                                                                    p={1}
                                                                >
                                                                    มุมมองนักเรียน
                                                                </Th>
                                                                <Th
                                                                    textAlign={"center"}
                                                                    borderColor={borderColor}
                                                                    color="gray.400"
                                                                    fontSize="sm"
                                                                    fontWeight="normal"
                                                                    p={1}
                                                                >
                                                                    เข้าดู
                                                                </Th>
                                                            </Tr>
                                                        </Thead>
                                                        <Tbody>
                                                            {(item.lessons).map((lesson, index) => (
                                                                <Tr>
                                                                    <Td
                                                                        borderColor={borderColor}
                                                                        ps="0px"
                                                                        minW={{ sm: "300px" }}
                                                                    >
                                                                        <Text
                                                                            color={secondaryColor}
                                                                            fontWeight="normal"
                                                                            fontSize="md"
                                                                        >
                                                                            {lesson.title}
                                                                        </Text>
                                                                    </Td>
                                                                    <Td borderColor={borderColor} textAlign={"center"}>
                                                                        <Text
                                                                            color={secondaryColor}
                                                                            fontWeight="normal"
                                                                            fontSize="md"
                                                                        >
                                                                            1
                                                                        </Text>
                                                                    </Td>
                                                                    <Td
                                                                        textAlign={"center"}
                                                                        borderColor={borderColor}
                                                                        w="25px"
                                                                        p={1}
                                                                    >
                                                                        <IconButton onClick={openModal} color={"navy"} ml={2} icon={<IoBookOutline/>}/>
                                                                    </Td>
                                                                    <Td
                                                                        borderColor={borderColor}
                                                                        w="25px"
                                                                        p={1}
                                                                    >
                                                                        <IconButton color={"red"} ml={1} icon={<IoTrashBinOutline/>}/>
                                                                    </Td>
                                                                    <Td borderColor={borderColor}  w="25px" p={1}>
                                                                        <IconButton color={"brand"} mr={1} icon={<EditIcon/>}/>
                                                                    </Td>
                                                                    <Td borderColor={borderColor}  w="25px" p={1}>
                                                                        <IconButton onClick={()=>{history.push('activity-list')}} color={"blue"} icon={<FaEye/>}/>
                                                                    </Td>
                                                                    <Td borderColor={borderColor}  w="25px" p={1}>
                                                                        <IconButton onClick={()=>{history.push('activity-list')}} color={"green"} icon={<FaEye/>}/>
                                                                    </Td>
                                                                </Tr>
                                                            ))}
                                                            
                                                        </Tbody>
                                                    </Table>
                                                    <Flex justify={"end"} w="100%">
                                                        <Button mr={1} fontWeight={400} leftIcon={<IoAddCircle color='navy'/>} variant='ghost'>เพิ่มบทเรียน</Button>
                                                        <Button mr={1} fontWeight={400} leftIcon={<IoRemoveCircle color='red'/>} variant='ghost'>ลบบทเรียน</Button>
                                                        <Button fontWeight={400} leftIcon={<FaEye color='teal'/>} variant='ghost'>แก้ไข</Button>
                                                    </Flex>
                                                </Box>
                                                
                                            </p>
                                        </Box>
                                    </Collapse>
                                </Box>
                            ))}


                        </CardBody>
                    </Card>
                </Flex>
                <Flex w="20%" bgColor="white" overflow={"hidden"}>
                    <Stack w="100%" direction="column" spacing="24px">
                        <SubjectSelectionCard />
                    </Stack>
                </Flex>
            </Flex>



        </Flex>
    );
};

export default ClassModel;
