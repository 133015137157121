import React, { useEffect, useRef } from 'react';

function TerrainAnimation({ widthPercent, heightPercent, starColor }) {
  const shootingStarRef = useRef(null);

  useEffect(() => {
    const shootingStarCanvas = shootingStarRef.current;
    const shootingStarCtx = shootingStarCanvas.getContext('2d');

    const parentWidth = shootingStarCanvas.parentElement.clientWidth;
    const parentHeight = shootingStarCanvas.parentElement.clientHeight;

    const width = (parentWidth * widthPercent) / 100;
    const height = (parentHeight * heightPercent) / 100;

    console.log(`width: ${width}`)
    console.log(`height: ${height}`)

    shootingStarCanvas.width = width;
    shootingStarCanvas.height = height;

    // Shooting stars
    function ShootingStar() {
      this.reset();
    }

    ShootingStar.prototype.reset = function () {
      this.x = Math.random() * width;
      this.y = 0;
      this.len = Math.random() * 80 + 10;
      this.speed = Math.random() * 10 + 6;
      this.size = Math.random() * 1 + 0.1;
      this.waitTime = new Date().getTime() + (Math.random() * 3000) + 500;
      this.active = false;
    }

    ShootingStar.prototype.update = function () {
      if (this.active) {
        this.x -= this.speed;
        this.y += this.speed;
        if (this.x < 0 || this.y >= height) {
          this.reset();
        } else {
          shootingStarCtx.strokeStyle = starColor;
          shootingStarCtx.lineWidth = this.size;
          shootingStarCtx.beginPath();
          shootingStarCtx.moveTo(this.x, this.y);
          shootingStarCtx.lineTo(this.x + this.len, this.y - this.len);
          shootingStarCtx.stroke();
        }
      } else {
        if (this.waitTime < new Date().getTime()) {
          this.active = true;
        }
      }
    }

    const entities = [];

    // Add 2 shooting stars that just cycle.
    entities.push(new ShootingStar());
    entities.push(new ShootingStar());

    // Star class
    function Star(options) {
      this.size = Math.random() * 2;
      this.speed = Math.random() * 0.1;
      this.x = options.x;
      this.y = options.y;
    }

    Star.prototype.update = function () {
      this.x -= this.speed;
      if (this.x < 0) {
        this.x = width;
        this.y = Math.random() * height;
      } else {
        shootingStarCtx.fillStyle = starColor;
        shootingStarCtx.fillRect(this.x, this.y, this.size, this.size);
      }
    }

    // Add stars entities
    for (let i = 0; i < height; i++) {
      entities.push(new Star({ x: Math.random() * width, y: Math.random() * height }));
    }

    // Animation function for shooting stars and small stars
    function animate() {
      shootingStarCtx.clearRect(0, 0, width, height);

      let entLen = entities.length;
      while (entLen--) {
        entities[entLen].update();
      }

      requestAnimationFrame(animate);
    }
    animate();
  }, [widthPercent, heightPercent, starColor]);

  return (
    <canvas
      ref={shootingStarRef}
      style={{
        width: `${widthPercent}%`,
        height: `${heightPercent}%`,
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: 1,
        pointerEvents: 'none'
      }}
    />
  );
}

export default TerrainAnimation;
