import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Spinner,
  Text,
  useDisclosure,
} from '@chakra-ui/react';

const LoadingModal = ({ isOpen, onClose, message = "Loading..." }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader textAlign="center">กำลังโหลด...</ModalHeader>
        <ModalBody display="flex" flexDirection="column" alignItems="center" justifyContent="center">
          <Spinner size="xl" thickness="4px" speed="0.65s" color="blue.500" />
          <Text mt={4}>{message}</Text>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default LoadingModal;
