import axios from 'axios';
import { API_URL } from 'variables/constant';

export const getSubjects = async () => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/subjects/list_all_subjects`); // Adjust the URL if different
        if (response.data.status === "success") {
            //console.log('Subjects:', response.data.subjects);
            return response.data.subjects;
        } else {
            console.log('Failed to fetch subjects');
            return [];
        }
    } catch (error) {
        console.error('Error fetching subjects:', error);
        return [];
    }
};

export const getClasses = async () => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/subjects/list_all_classes`, {}, { withCredentials: true });
        if (response.data.status === "success") {
            //console.log('Subjects:', response.data.subjects);
            return response.data.classes;
        } else {
            console.log('Failed to fetch classes');
            return [];
        }
    } catch (error) {
        console.error('Error fetching classes:', error);
        return [];
    }
};
