/*!

=========================================================
* Argon Dashboard Chakra PRO - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-chakra-pro
* Copyright 2022 Creative Tim (https://www.creative-tim.com/)

* Designed and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

/*import React from "react";
import ReactDOM from "react-dom";
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";
import { ChakraProvider } from "@chakra-ui/provider";
import theme from "theme/theme.js";

import AuthLayout from "layouts/Auth.js";
import AdminLayout from "layouts/Admin.js";
import RTLLayout from "layouts/RTL.js";
import LandingLayout from "layouts/Landing";

ReactDOM.render(
  <ChakraProvider theme={theme} resetCss={false} position="relative">
    <HashRouter>
      <Switch>
        <Route path={`/auth`} component={AuthLayout} />
        <Route path={`/user`} component={AdminLayout} />
        <Route path={`/landing`} component={LandingLayout} />
        <Route path={`/rtl`} component={RTLLayout} />
        <Redirect from={`/`} to="/user/classroom-list" />
      </Switch>
    </HashRouter>
  </ChakraProvider>,
  document.getElementById("root")
);
*/

import React from "react";
import { createRoot } from "react-dom/client";
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";
import { ChakraProvider } from "@chakra-ui/provider";
import theme from "theme/theme.js";

import AuthLayout from "layouts/Auth.js";
import AdminLayout from "layouts/Admin.js";
import RTLLayout from "layouts/RTL.js";
import LandingLayout from "layouts/Landing";
import { AuthProvider } from "auth/AuthProvider";

// Get the root element
const rootElement = document.getElementById("root");

// Create a root
const root = createRoot(rootElement);

// Render your app
root.render(
  <ChakraProvider theme={theme} resetCss={false} position="relative">
    <AuthProvider>
      <HashRouter>
        <Switch>
          <Route path={`/auth`} component={AuthLayout} />
          <Route path={`/user`} component={AdminLayout} />
          <Route path={`/landing`} component={LandingLayout} />
          <Route path={`/rtl`} component={RTLLayout} />
          <Redirect from={`/`} to="/user/classroom-list" />
          <Redirect from={`/login`} to="/user/login" />
          <Redirect from={`/teacher_login`} to="/user/teacher_login" />
          <Redirect from={`/student_login`} to="/user/student_login" />
        </Switch>
      </HashRouter>
    </AuthProvider>
  </ChakraProvider>
);
