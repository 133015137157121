import React, { useEffect, useState } from 'react';
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Select,
  Flex,
  FormControl,
  FormLabel
} from '@chakra-ui/react';

const ScoreSelectorModal = ({ initScore, isOpen, onClose, onSave }) => {
  const [selectedScore, setSelectedScore] = useState();
  const scores = Array.from({ length: 100 }, (_, i) => (i));

  const handleSave = () => {
    onSave(selectedScore);
    onClose();
  };

  useEffect(() => {
    if (isOpen && initScore != null && initScore != undefined) {
        setSelectedScore(initScore);
    }
  }, [isOpen]);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>เลือกคะแนน</ModalHeader>
        <ModalBody>
         <Flex>
          <FormControl>
            <FormLabel>คะแนนสำหรับคำถามนี้</FormLabel>
            <Select mr={1}  value={selectedScore} onChange={(e) => setSelectedScore(e.target.value)}>
                {scores.map((score) => (
                <option key={score} value={score}>{score} คะแนน</option>
                ))}
            </Select>
          </FormControl>
         </Flex>
        </ModalBody>
        <ModalFooter>
          <Button onClick={onClose}>ยกเลิก</Button>
          <Button colorScheme="blue" ml={2} onClick={()=>{handleSave()}}>
            บันทึก
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ScoreSelectorModal;
