import { Center, Box, Text  } from "@chakra-ui/react";
import { CheckIcon } from '@chakra-ui/icons';

export const LineBox = (props) => {
    const {isLastItem, isCorrect, ...rest} = props;
    return (
      <Box
        {...rest}
        position="relative"
        minW="40px"
        minH="40px"
        border="none" // Hide all borders
        textAlign="center"
      >
        {/* Line from left-top to left-center */}
        <Box
          position="absolute"
          top="0%"
          left="0%"
          width="1px"
          height={isLastItem?"50%":"100%"}
          backgroundColor="gray.300"
          transform="translateX(-50%)"
        />
  
        {/* Line from left-center to right-center */}
        <Box
          position="absolute"
          top="50%"
          left="0"
          width="100%"
          height="1px"
          backgroundColor="gray.300"
        />

        {isCorrect && <Box
          position="absolute"
          top="calc(50% - 15px)"
          right="0"
          w="30px"
          h="30px"
          borderRadius="50%"
          bgColor="white"
          borderWidth="1px"
        >
            <CheckIcon color="green" />
        </Box>}
      </Box>
    );
  };
  

export const CircleWithNumber = ({ number }) => {
    return (
      <Center>
        <Box
          w="50px"
          h="50px"
          borderRadius="50%"
          color="white"
          bgColor="blue.100"
          display="flex"
          borderWidth="2px"
          borderColor="gray.200"
          justifyContent="center"
          alignItems="center"
          fontSize="2xl"
          fontWeight="bold"
        >
          <Text color="black">{number}</Text>
        </Box>
      </Center>
    );
  };

  export const BoxWithNumber = ({ number }) => {
    return (
      <Center>
        <Box
          w="30px"
          h="30px"
          borderRadius="20%"
          color="white"
          display="flex"
          borderWidth="2px"
          borderColor="gray.200"
          justifyContent="center"
          alignItems="center"
          fontSize="sm"
          fontWeight="bold"
        >
          <Text color="black">{number}</Text>
        </Box>
      </Center>
    );
  };
