import React, { useRef, useEffect, useState, useMemo, useCallback } from 'react';
import { Flex, Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalFooter, Button } from '@chakra-ui/react';
import 'quill/dist/quill.snow.css'; // Add Quill styles
import QuillEditor, { Quill } from "react-quill";
import ImageResize from 'quill-image-resize-module-react';
import QuillResizeImage from 'quill-resize-image';

const QuillEditing = ({ isOpen, onClose, onUpdate, initialValue }) => {
  // Editor state
  const quill = useRef();
  const [value, setValue] = useState();
  Quill.register("modules/imageResize", ImageResize);
  //Quill.register("modules/resize", QuillResizeImage);

  useEffect(()=>{
    //if(initialValue){
      setValue(initialValue)
    //}
  },[isOpen])

  const openMath = () => {
    localStorage['is_equation_inserted'] = 'false';
    var textarea = 'txtEquation1';
    var win = window.open(
      'VisualMathEditor.html?runLocal&codeType=Latex&encloseAllFormula=false&textarea=' + textarea,
      'VisualMathEditor',
      'height=580,width=780,top=100,left=100,status=yes,toolbar=no,menubar=no,location=no,resizable=no,scrollbars=no,modal=no,dependable=yes'
    );

    const intervalId = setInterval(() => {
        if (win.closed) {
            //win.focus();
          clearInterval(intervalId);
          updateEquation()
          // Window is closed, perform actions
          console.log('Window closed');
        }
    }, 500);
    
  };

  function updateEquation(){
    if (localStorage['is_equation_inserted'] === 'true') {
        if (localStorage['error'] !== '') {
          alert(localStorage['error']);
          localStorage['error'] = '';
        } else if (localStorage['math_equation'] !== '') {
          console.log(localStorage['math_equation']);

          const quillEditor = quill.current.getEditor();
          const selection = quillEditor.getSelection();
          
          // Check if there's an active selection
          if (!selection) {
            // If no selection, append math to the end
            const content = quillEditor.getContents();
            if(content && content.length > 0){
              quillEditor.setContents(content.push('$' + localStorage['math_equation'] + '$'));
            }
            else{
              quillEditor.insertText(0, '$' + localStorage['math_equation'] + '$');
            }
          } else {
            // If there's a selection, insert math at the selection index
            const range = quillEditor.getSelection(true);
            quillEditor.insertText(range.index, '$' + localStorage['math_equation'] + '$');
          }

          localStorage['math_equation'] = '';
        }

        localStorage['is_equation_inserted'] = 'false';
      }
  }

  const imageHandler = useCallback(() => {
    // Create an input element of type 'file'
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();
  
    // When a file is selected
    input.onchange = () => {
      const file = input.files[0];
      const reader = new FileReader();
  
      // Read the selected file as a data URL
      reader.onload = () => {
        const imageUrl = reader.result;
        const image = new Image();
  
        // When the image is loaded
        image.onload = () => {
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");
  
          let width = image.width;
          let height = image.height;
          console.log("loading...")
  
          // Calculate new dimensions while maintaining aspect ratio
          if (width > height) {
            if (width > 600) {
              height *= 600 / width;
              width = 600;
            }
          } else {
            if (height > 600) {
              width *= 600 / height;
              height = 600;
            }
          }
  
          // Set canvas dimensions
          canvas.width = width;
          canvas.height = height;
  
          // Draw image onto canvas
          ctx.drawImage(image, 0, 0, width, height);
  
          // Get the data URL of the resized image
          const resizedImageUrl = canvas.toDataURL(file.type);
  
          const quillEditor = quill.current.getEditor();
  
          // Get the current selection range and insert the image at that index
          const range = quillEditor.getSelection(true);
          quillEditor.insertEmbed(range.index, "image", resizedImageUrl, "user");
        };
  
        image.src = imageUrl;
      };
  
      reader.readAsDataURL(file);
    };
  }, []);
  

  // Define the Quill modules
  const modules = useMemo(() => ({
    toolbar: {
      container: [
        [{ header: [2, 3, 4, false] }],
        ["bold", "italic", "underline", "blockquote"],
        [{ color: [] }],
        [{ align: [] }],
        [
          { list: "ordered" },
          { list: "bullet" },
          { indent: "-1" },
          { indent: "+1" },
        ],
        ["link", "image"],
        ["clean"],
      ],
      handlers: {
        image: imageHandler
      },
    },
    clipboard: {
      matchVisual: true,
    },
    /*resize: {
      locale: {},
    },*/
    imageResize: {
      parchment: Quill.import('parchment'),
      modules: ['Resize', 'DisplaySize']
    }
    
  }), [imageHandler]);

  // Define Quill formats
  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "color",
    "align",
    "clean",
  ];

  const customButtonLabelStyle = `
  .ql-insertMath .ql-picker-label {
    color: black; /* Adjust the color to ensure visibility */
  }
`;

const replaceImageWithResizedSrc = (text) => {
  // Remove style attribute from the text
  const styleRegex = /style="[^"]*"/g;
  text = text.replace(styleRegex, '');

  const imgRegex = /<img\s+[^>]*src="([^"]+)"[^>]*width="(\d+)"[^>]*\/?>/gi;

  // Iterate over all matches of <img> elements
  return text.replace(imgRegex, (match, src, width) => {
      // Create a new image element to get the image dimensions
      const img = new Image();
      img.src = src;

      // Convert width attribute to number
      const targetWidth = parseInt(width);

      // Create a canvas to resize the image
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');

      // Calculate new height while maintaining aspect ratio
      const aspectRatio = img.width / img.height;
      const targetHeight = targetWidth / aspectRatio;

      // Set canvas dimensions
      canvas.width = targetWidth;
      canvas.height = targetHeight;

      // Draw image onto canvas with new dimensions
      ctx.drawImage(img, 0, 0, targetWidth, targetHeight);

      // Get data URL of resized image
      const resizedImageUrl = canvas.toDataURL('image/jpeg');

      // Return the modified <img> element with resized src
      return `<img src="${resizedImageUrl}" width="${targetWidth}" />`;
  });
}

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="4xl">
      <style>{customButtonLabelStyle}</style> 
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>จัดการเนื้อหา</ModalHeader>
        <ModalBody>
          <Flex direction={{ base: 'column', md: 'column' }} w="100%">
            <Flex><Button onClick={openMath}>เพิ่มสมการคณิตศาสตร์</Button></Flex>
            <textarea
                style={{ visibility: 'hidden' }}
                id="txtEquation1"
                name="equation1"
                cols="50"
                rows="1"
                defaultValue="a^x = 1"
              />
            <QuillEditor
              className={"ql-container"}
              ref={(el) => (quill.current = el)}
              theme="snow"
              value={value}
              formats={formats}
              modules={modules}
              onChange={(value) => setValue(value)}
              
            />
          </Flex>
        </ModalBody>
        <ModalFooter>
          <Button variant='outline' mr={3} onClick={onClose}>
            ยกเลิก
          </Button>
          <Button colorScheme="blue" mr={3} onClick={() => {
              const originalText = quill.current.getEditor().root.innerHTML;
              const modifiedText = replaceImageWithResizedSrc(originalText);
              onUpdate(modifiedText)
            }}>
            บันทึก
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default QuillEditing;
