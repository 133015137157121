import React, { useEffect, useMemo, useState } from 'react';
import {
  Avatar,
  Box,
  Button,
  Flex,
  Icon,
  Input,
  Grid,
  Text,
  Th,
  Thead,
  Tr,
  Tbody,
  Table,
  useColorModeValue,
  Stack,
  Td,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  useDisclosure,
  Select,
  useToast,
} from '@chakra-ui/react';
import { AiOutlinePlus, AiOutlineSearch, AiFillEdit, AiFillDelete } from 'react-icons/ai';
import Swal from 'sweetalert2';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import CardHeader from 'components/Card/CardHeader.js';
import { HSeparator } from 'components/Separator/Separator.js';
import SubjectSelectionCard from './widgets/SubjectSelectionCard';
import ReactPaginate from 'react-paginate';
import { useTable, useSortBy, usePagination } from 'react-table';
import axios from 'axios';
import { API_URL } from 'variables/constant';
import BookSVG from 'assets/svg/book.svg';
import { getSubjects } from 'apis/subjectAPI';


function BookList() {
  const [selectedSubjectIDs, setSelectedSubjectIDs] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [data, setData] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [totalBooks, setTotalBooks] = useState(0);
  const [bookName, setBookName] = useState('');
  const [subjectId, setSubjectId] = useState('');
  const [searchKey, setSearchKey] = useState('');
  const [subjects, setSubjects] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isEditing, setIsEditing] = useState(false);
  const [currentBookId, setCurrentBookId] = useState(null);
  const toast = useToast();

  const textColor = useColorModeValue('gray.700', 'white');
  const borderColor = useColorModeValue('gray.200', 'gray.600');

  const columns = useMemo(
    () => [
      {
        Header: 'No.',
        accessor: 'no',
        Cell: ({ row }) => row.index + 1 + currentPage * pageSize,
      },
      {
        Header: 'ชื่อเล่มหนังสือ',
        accessor: 'title',
      },
      {
        Header: 'วิชา',
        accessor: 'subjectThai',
      },
      {
        Header: 'Actions',
        accessor: 'action',
        Cell: ({ row }) => (
          <Flex>
            <Button
              leftIcon={<AiFillEdit />}
              colorScheme="yellow"
              variant="outline"
              size="sm"
              mr={2}
              onClick={() => handleEdit(row.original)}
            >
              แก้ไข
            </Button>
            <Button
              leftIcon={<AiFillDelete />}
              colorScheme="red"
              variant="outline"
              size="sm"
              onClick={() => confirmDelete(row.original.id)}
            >
              ลบ
            </Button>
          </Flex>
        ),
      },
    ],
    [currentPage, pageSize]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    nextPage,
    pageCount,
    previousPage,
    pageOptions,
    state: { pageIndex },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize },
      manualPagination: true,
      pageCount: Math.ceil(totalBooks / pageSize),
    },
    useSortBy,
    usePagination
  );

  const handlePageClick = ({ selected: selectedPage }) => {
    setCurrentPage(selectedPage);
  };

  const handleEdit = (book) => {
    setBookName(book.title);
    setSubjectId(book.subject_id);
    setIsEditing(true);
    setCurrentBookId(book.id);
    onOpen();
  };

  const confirmDelete = (bookId) => {
    Swal.fire({
      title: 'คุณแน่ใจไหม?',
      text: "คุณต้องการลบหนังสือเล่มนี้หรือไม่?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'ใช่',
      cancelButtonText: 'ยกเลิก',
      denyButtonColor: "#6CB4EE",
      confirmButtonColor: "#ED0A3F",
    }).then((result) => {
      if (result.isConfirmed) {
        handleDelete(bookId);
      }
    });
  };

  const handleDelete = (bookId) => {
    axios.post(`${process.env.REACT_APP_API_URL}/book/delete_book`, { book_id: bookId }, { withCredentials: true })
      .then(response => {
        if (response.data.status === 'success') {
          fetchBooks();
          toast({
            title: "หนังสือถูกลบแล้ว",
            description: "หนังสือถูกลบเรียบร้อยแล้ว",
            status: "success",
            duration: 5000,
            isClosable: true,
          });
        } else {
          console.error("Error deleting book:", response.data.status);
        }
      })
      .catch(error => {
        console.error("There was an error deleting the book!", error);
      });
  };

  const handleInsert = () => {
    if (!bookName || !subjectId) {
      toast({
        title: "เกิดข้อผิดพลาดในการตรวจสอบ",
        description: "กรุณากรอกชื่อหนังสือและเลือกวิชา",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    const bookOpt = isEditing ? "update" : "insert";
    const val = { book_name: bookName, subject_id: subjectId, book_id: currentBookId, book_opt: bookOpt };

    axios.post(`${process.env.REACT_APP_API_URL}/book/add_book`, val, { withCredentials: true })
      .then(response => {
        if (response.data.status === 'success') {
          setBookName('');
          setSubjectId('');
          onClose();
          fetchBooks();
          toast({
            title: `หนังสือถูก${isEditing ? 'แก้ไข' : 'เพิ่ม'}แล้ว`,
            description: `หนังสือถูก${isEditing ? 'แก้ไข' : 'เพิ่ม'}เรียบร้อยแล้ว`,
            status: "success",
            duration: 5000,
            isClosable: true,
          });
        } else {
          console.error(`Error ${isEditing ? 'updating' : 'adding'} book:`, response.data.status);
        }
      })
      .catch(error => {
        console.error(`There was an error ${isEditing ? 'updating' : 'adding'} the book!`, error);
      });
  };

  const fetchBooks = () => {
    const offset = currentPage * pageSize;
    const limit = pageSize;

    axios.post(`${process.env.REACT_APP_API_URL}/book/load_all_books`, { offset, limit, subject_ids: selectedSubjectIDs, search_key: searchKey }, { withCredentials: true })
      .then(response => {
        if (response.data.status === 'success') {
          setData(response.data.books);
          setTotalBooks(response.data.total);
        }
      })
      .catch(error => {
        console.error("There was an error fetching the books data!", error);
      });
  };

  useEffect(() => {
    fetchBooks();
  }, [currentPage, pageSize, selectedSubjectIDs, searchKey]);

  useEffect(() => {
    const fetchSubjects = async () => {
      const subs = await getSubjects();
      setSubjects(subs);
    };

    fetchSubjects();
  }, []);

  return (
    <Flex direction="column" mt={{ sm: '45px', md: '40px' }} overflowX="hidden">
      <Grid
        templateColumns={{ sm: '1fr', lg: '3.1fr 1fr' }}
        templateRows="auto 1fr"
        mt="24px"
        gap="24px"
      >
        <Card alignSelf={{ lg: 'flex-start', '2xl': 'stretch' }}>
          <CardHeader w="100%">
            <Flex justify="space-between" align="center">
              <Flex>
                <Box>
                  <Avatar src={BookSVG} p={1} w="40px" h="40px" borderRadius="12px" me="15px" />
                </Box>
                <Flex direction="column">
                  <Text fontSize="md" color={textColor} fontWeight="bold">
                    รายชื่อเล่มหนังสือเรียน
                  </Text>
                  <Text fontSize="sm" color="gray.500" fontWeight="normal">
                    {totalBooks} เล่ม
                  </Text>
                </Flex>
              </Flex>
              <Flex align="center">
                <Icon as={AiOutlineSearch} color="gray.400" w="20px" h="20px" me="8px" />
                <Input
                  mr={2}
                  placeholder="ค้นหาหนังสือ..."
                  border="1px solid"
                  borderColor="gray.200"
                  value={searchKey}
                  onChange={(e) => setSearchKey(e.target.value)}
                />
                <Button variant="primary" p="8px 32px" onClick={() => { 
                  setIsEditing(false);
                  onOpen();
                  setCurrentBookId(null)
                  setBookName('')
                  setSubjectId('')
                  }}>
                  <Flex align="center" color="#fff" justifyContent="center">
                    <Icon as={AiOutlinePlus} w="18px" h="18px" fontWeight="bold" me="4px" />
                    <Text fontSize="14px">
                      เพิ่มเล่ม
                    </Text>
                  </Flex>
                </Button>
              </Flex>
            </Flex>
          </CardHeader>
          <HSeparator my="16px" />
          <CardBody>
            <Flex direction="column">
              <Card overflowX={{ sm: 'scroll', lg: 'scroll' }}>
                <CardBody>
                  <Table
                    overflowX={{ sm: 'scroll', lg: 'scroll' }}
                    variant="simple"
                    color={textColor}
                    {...getTableProps()}
                  >
                    <Thead>
                      {headerGroups.map(headerGroup => (
                        <Tr {...headerGroup.getHeaderGroupProps()}>
                          {headerGroup.headers.map(column => (
                            <Th {...column.getHeaderProps(column.getSortByToggleProps())}>
                              {column.render('Header')}
                            </Th>
                          ))}
                        </Tr>
                      ))}
                    </Thead>
                    <Tbody {...getTableBodyProps()}>
                      {page.map((row, index) => {
                        prepareRow(row);
                        return (
                          <Tr {...row.getRowProps()}>
                            <Td {...row.cells[0].getCellProps()}>{index + 1 + currentPage * pageSize}</Td>
                            {row.cells.slice(1).map(cell => (
                              <Td {...cell.getCellProps()}>{cell.render('Cell')}</Td>
                            ))}
                          </Tr>
                        );
                      })}
                    </Tbody>
                  </Table>
                </CardBody>
              </Card>
              <Flex pt={1} w="100%" justify="flex-end">
                <ReactPaginate
                  pageCount={Math.ceil(totalBooks / pageSize)}
                  onPageChange={handlePageClick}
                  forcePage={currentPage}
                  containerClassName={'pagination'}
                  activeClassName={'active'}
                  breakLabel="..."
                  nextLabel="next >"
                  previousLabel="< previous"
                />
              </Flex>
            </Flex>
          </CardBody>
        </Card>
        <Stack direction="column" spacing="24px">
          <SubjectSelectionCard setSelectedSubjectIDs={(ids)=>{
            setCurrentPage(0)
            setSelectedSubjectIDs(ids)
          }} />
        </Stack>
      </Grid>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{isEditing ? 'แก้ไขเล่มหนังสือ' : 'เพิ่มเล่มหนังสือ'}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl id="bookName" mb={4}>
              <FormLabel>ชื่อเล่มหนังสือ</FormLabel>
              <Input
                value={bookName}
                onChange={(e) => setBookName(e.target.value)}
                placeholder="Enter book name"
              />
            </FormControl>
            <FormControl id="subjectId">
              <FormLabel>วิชา</FormLabel>
              <Select
                placeholder="-- เลือกเล่มหนังสือ --"
                value={subjectId}
                onChange={(e) => setSubjectId(e.target.value)}
              >
                {subjects.map(subject => (
                  <option key={subject.id} value={subject.id}>
                    {subject.subjectThai}
                  </option>
                ))}
              </Select>
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handleInsert}>
              บันทึก
            </Button>
            <Button variant="ghost" onClick={onClose}>ยกเลิก</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  );
}

export default BookList;
