import { useEffect, useState } from "react";
import { Box, Button, Text, Stack, Flex, Input } from "@chakra-ui/react";
import { BiLeftArrow, BiRightArrow } from "react-icons/bi";
import axios from 'axios';
import { API_URL } from "variables/constant";
import { MathJax, MathJaxContext } from "better-react-mathjax";
import { loadQuestion } from "util/questionUtil";

const QuestionPreviewBox = ({ questionList, initialIndex, handlePrevNextClick, selectAllByDefault = false }) => {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(initialIndex || 0);
  const [questions, setQuestions] = useState([]);
  const [selectedLeft, setSelectedLeft] = useState(null);
  const [selectedPairs, setSelectedPairs] = useState({});
  const [pairCount, setPairCount] = useState(1);

  const currentQuestion = questions[currentQuestionIndex];
  const [fillAnswers, setFillAnswers] = useState(currentQuestion?.answers ? currentQuestion.answers.map(answer => answer.answer_text) : []);

  const config = {
    loader: { load: ["[tex]/html"] },
    tex: {
      packages: { "[+]": ["html"] },
      inlineMath: [
        ["$", "$"],
        ["\\(", "\\)"]
      ],
      displayMath: [
        ["$$", "$$"],
        ["\\[", "\\]"]
      ]
    }
  };

  useEffect(() => {
    //alert('b')
    const fetchQuestions = async () => {
      const fetchedQuestions = [];
      for (const { id, question_type } of questionList) {
        const question = await loadQuestion(id, question_type);
        if (question) {
          //console.log(`${JSON.stringify(question)}`)
          fetchedQuestions.push(question);
        }
      }
      setQuestions(fetchedQuestions);
    };

    fetchQuestions();
  }, [questionList]);

  useEffect(() => {
    setCurrentQuestionIndex(initialIndex);
  }, [initialIndex]);

  useEffect(() => {
    if (selectAllByDefault) {
      initializeSelections();
    } else {
      resetSelection();
    }
  }, [currentQuestionIndex, selectAllByDefault]);

  useEffect(() => {
    if (currentQuestion?.question_type === 'fill' && currentQuestion?.answers) {
      setFillAnswers(currentQuestion.answers.map(answer => answer.answer_text));
    }
  }, [currentQuestionIndex]);

  const handleNextQuestion = () => {
    if (currentQuestionIndex < questions.length - 1) {
      handlePrevNextClick('next');
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    }
  };

  const handlePreviousQuestion = () => {
    if (currentQuestionIndex > 0) {
      handlePrevNextClick('prev');
      setCurrentQuestionIndex(currentQuestionIndex - 1);
    }
  };

  const resetSelection = () => {
    setSelectedLeft(null);
    setSelectedPairs({});
    setPairCount(1);
  };

  const initializeSelections = () => {
    if (currentQuestion.question_type === 'matching') {
      const initialPairs = {};
      let count = 1;
      currentQuestion.matching_items.ItemMatchingQuestion.forEach((item, index) => {
        initialPairs[item.ItemLeftSideSeq] = currentQuestion.matching_items.ItemMatchingChoice[index].ItemRightSideSeq;
        count++;
      });
      setSelectedPairs(initialPairs);
      setPairCount(count);
    }
  };

  const handleLeftItemClick = (index) => {
    if (selectedPairs[index]) {
      const rightIndex = selectedPairs[index];
      const newPairs = { ...selectedPairs };
      delete newPairs[index];
      setSelectedPairs(newPairs);
      setSelectedLeft(null);
    } else {
      setSelectedLeft(index);
    }
  };

  const handleRightItemClick = (index) => {
    const leftIndex = Object.keys(selectedPairs).find(key => selectedPairs[key] === index);
    if (leftIndex) {
      const newPairs = { ...selectedPairs };
      delete newPairs[leftIndex];
      setSelectedPairs(newPairs);
    } else if (selectedLeft !== null) {
      const newPairs = { ...selectedPairs, [selectedLeft]: index };
      setSelectedPairs(newPairs);
      setPairCount(pairCount + 1);
    }
    setSelectedLeft(null);
  };

  const handleFillAnswerChange = (index, value) => {
    const newFillAnswers = [...fillAnswers];
    newFillAnswers[index] = value;
    setFillAnswers(newFillAnswers);
  };

  const renderMatchingQuestion = (question) => {
    return (
      <Stack spacing={4}>
        <Box>
          {JSON.parse(question.matching_items).ItemMatchingQuestion.map((item) => (
            <Box
              key={item.ItemLeftSideSeq}
              borderWidth={1}
              p={4}
              rounded="md"
              borderColor={selectedPairs[item.ItemLeftSideSeq] ? "blue.500" : (selectedLeft === item.ItemLeftSideSeq ? "blue.500" : "gray.300")}
              bg={selectedPairs[item.ItemLeftSideSeq] ? "blue.50" : (selectedLeft === item.ItemLeftSideSeq ? "blue.50" : "white")}
              onClick={() => handleLeftItemClick(item.ItemLeftSideSeq)}
              cursor="pointer"
              position="relative"
            >
              <MathJax>
                <Box dangerouslySetInnerHTML={{ __html: item.ItemLeftSide }}></Box>
              </MathJax>
              
              {selectedPairs[item.ItemLeftSideSeq] && (
                <Box
                  position="absolute"
                  top="50%"
                  left={0}
                  transform="translate(-50%, -50%)"
                  borderRadius="full"
                  bg="blue.500"
                  color="white"
                  width="20px"
                  height="20px"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  fontSize="12px"
                >
                  {Object.keys(selectedPairs).find(key => selectedPairs[key] === selectedPairs[item.ItemLeftSideSeq])}
                </Box>
              )}
            </Box>
          ))}
        </Box>
        <Box>
          {JSON.parse(question.matching_items).ItemMatchingChoice.map((choice) => (
            <Box
              key={choice.ItemRightSideSeq}
              borderWidth={1}
              p={4}
              rounded="md"
              borderColor={Object.values(selectedPairs).includes(choice.ItemRightSideSeq) ? "blue.500" : "gray.300"}
              bg={Object.values(selectedPairs).includes(choice.ItemRightSideSeq) ? "blue.50" : "white"}
              onClick={() => handleRightItemClick(choice.ItemRightSideSeq)}
              cursor="pointer"
              position="relative"
            >
              <MathJax>
                <Box dangerouslySetInnerHTML={{ __html: choice.ItemRightSide }}></Box>
              </MathJax>
              {Object.keys(selectedPairs).find(key => selectedPairs[key] === choice.ItemRightSideSeq) && (
                <Box
                  position="absolute"
                  top="50%"
                  left={0}
                  transform="translate(-50%, -50%)"
                  borderRadius="full"
                  bg="blue.500"
                  color="white"
                  width="20px"
                  height="20px"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  fontSize="12px"
                >
                  {Object.keys(selectedPairs).find(key => selectedPairs[key] === choice.ItemRightSideSeq)}
                </Box>
              )}
            </Box>
          ))}
        </Box>
      </Stack>
    );
  };

  const renderFillQuestion = (question) => {
    return (
      <Stack spacing={4}>
        {question.answers.map((answer, index) => (
          index == 0 && <Input
            key={answer.id}
            placeholder="กรุณากรอกคำตอบของคุณที่นี่."
            value={fillAnswers[index]}
            onChange={(e) => handleFillAnswerChange(index, e.target.value)}
            borderWidth={1}
            p={4}
            rounded="md"
            borderColor="gray.300"
            bg="white"
          />
        ))}
      </Stack>
    );
  };

  const renderChoiceQuestion = (question) => {
    return (
      <>
        {question.choices.map((choice) => (
          <MathJax>
            <Box key={`ch_${choice.id}`} borderWidth={1} p={4} rounded="md" borderColor={choice.is_answer ? "green.500" : "gray.300"} bg={choice.is_answer ? "green.50" : "white"} dangerouslySetInnerHTML={{ __html: choice.anwer_text }}>
            </Box>
          </MathJax>
        ))}
      </>
    );
  };

  const renderQuestionContent = (question) => {
    if(question && question !== undefined){
      console.log(`${JSON.stringify(question)}`)
      switch (question.question_type) {
        case 'matching':
          return renderMatchingQuestion(question);
        case 'fill':
          return renderFillQuestion(question);
        case 'choice':
          console.log("=======================")
          //console.log(`---cur: ${JSON.stringify(question)}`)
          return renderChoiceQuestion(question);
        default:
          return null;
      }
    }
  };

  /*const updateImageSrc = (htmlContent, apiUrl) => {
    const regex = /(<img\s+[^>]*src=["'])(\/images\/[^"']*)(["'][^>]*>)/gi;
    const updatedContent = htmlContent.replace(regex, `$1${apiUrl}$2$3`);
    return updatedContent;
  };*/

  const updateImageSrc = (htmlContent, apiUrl) => {
    // Regex to match any URL that ends with an image file (e.g., .jpg, .png)
    const regex = /<img\s+[^>]*src=["']([^"']*\/([^\/]+\.(jpg|jpeg|png|gif|bmp)))["'][^>]*>/gi;
    const updatedContent = htmlContent.replace(regex, (match, fullUrl, fileName) => {
        return match.replace(fullUrl, `${apiUrl}/images/${fileName}`);
    });
    console.log(updatedContent);
    return updatedContent;
};
  

  return (
    <>
      <Box bt={5} bgColor={"blue.50"} roundedTop={"xl"}>
        <Flex p={1} align="center" justify="space-between">
          <Button leftIcon={<BiLeftArrow />} variant="ghost" colorScheme="blue" onClick={handlePreviousQuestion} disabled={currentQuestionIndex === 0}>ก่อนหน้า</Button>
          <Text color="navy">{`ข้อที่ ${currentQuestionIndex + 1}/${questions.length}`}</Text>
          <Button rightIcon={<BiRightArrow />} variant="ghost" colorScheme="blue" onClick={handleNextQuestion} disabled={currentQuestionIndex === questions.length - 1}>ถัดไป</Button>
        </Flex>
      </Box>
      <MathJaxContext version={3} config={config}>
        <Box borderWidth={1} p={6} roundedBottom={"xl"} bg="white">
          {currentQuestion && (
            <>
              <MathJax>
                  <Box dangerouslySetInnerHTML={{ __html: updateImageSrc(currentQuestion.content, `${process.env.REACT_APP_API_URL}`) }} w="100%" />
              </MathJax> 
              <Stack spacing={4} mt={4}>
                {renderQuestionContent(currentQuestion)}
              </Stack>
            </>
          )}
        </Box>
        <Box mt={4} p={4} borderWidth={1} rounded="md" borderColor="gray.300" bg="gray.100">
          <Text fontSize={"18"} fontWeight={600}>คำอธิบาย</Text>
            {currentQuestion && currentQuestion.otim_explanation && <MathJax><Text dangerouslySetInnerHTML={{ __html: currentQuestion.otim_explanation }} /></MathJax>}
          </Box>
      </MathJaxContext>
      
    </>
  );
};

export default QuestionPreviewBox;




/*import { useEffect, useState } from "react";
import { Box, Button, Text, Stack, Flex, Input } from "@chakra-ui/react";
import { BiLeftArrow, BiRightArrow } from "react-icons/bi";
import axios from "axios";

const questionsData = [
  {
    id: 63571,
    content: "กำหนดให้ a, b และ c เป็นจำนวนเต็ม จงจับคู่จำนวนที่เป็นคำตอบของคำถามต่อไปนี้",
    matching_items: {
      ItemMatchingQuestion: [
        {
          ItemLeftSideSeq: 1,
          ItemLeftSide: "1. ถ้า a × b = –7 และ a × c = 4 แล้ว a × (b – c) เท่ากับเท่าใด",
          ItemRightSideKey: 2
        },
        {
          ItemLeftSideSeq: 2,
          ItemLeftSide: "2. ถ้า (a × b) + (a × c) = 24 และ b + c = 4 แล้ว a เท่ากับเท่าใด",
          ItemRightSideKey: 4
        }
      ],
      ItemMatchingChoice: [
        { ItemRightSideSeq: 1, ItemRightSide: "-28" },
        { ItemRightSideSeq: 2, ItemRightSide: "-11" },
        { ItemRightSideSeq: 3, ItemRightSide: "-3" },
        { ItemRightSideSeq: 4, ItemRightSide: "6" },
        { ItemRightSideSeq: 5, ItemRightSide: "20" },
        { ItemRightSideSeq: 6, ItemRightSide: "28" }
      ]
    },
    question_type: "matching",
  },
  {
    id: 63569,
    content: "ถ้าปีนี้พ่ออายุ 51 ปี และลูกสาวอายุ 23 ปี อีกกี่ปี อายุพ่อจะเป็น 2 เท่าของอายุลูกสาว",
    matching_items: {},
    question_type: "fill",
    answers: [{
      id: 250865,
      answer_text: "3",
      image: "",
      is_answer: 1,
      sequence: 1,
      ipst_question_id: 63575,
      create_datetime: "2024-08-01T08:17:10.000Z"
    }]
  },
  {
    id: 63568,
    content: "ถ้านำ 3 คูณกับผลบวกของ x กับ 42 ได้ผลลัพธ์เป็น 312 แล้วค่าของ x เท่ากับเท่าใด เขียนสมการเพื่อหาค่าของ x ได้ดังข้อใด",
    matching_items: {},
    question_type: "choice",
    choices: [
      { id: "1", text: "x + 42 = 104", isCorrect: false },
      { id: "2", text: "3(x + 42) = 312", isCorrect: true },
      { id: "3", text: "3x + 42 = 312", isCorrect: false },
      { id: "4", text: "3x = 270", isCorrect: false }
    ]
  }
];

const QuestionPreviewBox = ({initialIndex, handlePrevNextClick, selectAllByDefault = true }) => {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(initialIndex || 0);
  const [selectedLeft, setSelectedLeft] = useState(null);
  const [selectedPairs, setSelectedPairs] = useState({});
  const [pairCount, setPairCount] = useState(1);
  const currentQuestion = questionsData[currentQuestionIndex];
  const [fillAnswers, setFillAnswers] = useState(
    currentQuestion.answers ? currentQuestion.answers.map(answer => answer.answer_text) : []
  );

  useEffect(() => {
    setCurrentQuestionIndex(initialIndex);
  }, [initialIndex]);

  useEffect(() => {
    if (selectAllByDefault) {
      initializeSelections();
    } else {
      resetSelection();
    }
  }, [currentQuestionIndex, selectAllByDefault]);

  useEffect(() => {
    if (currentQuestion.question_type === 'fill' && currentQuestion.answers) {
      setFillAnswers(currentQuestion.answers.map(answer => answer.answer_text));
    }
  }, [currentQuestionIndex]);

  const loadAllQuestionItem = async (question_id) => {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/questions/load_question_item'`, { question_id })
    const res = response.data;
    if (res && res.status === "success") {
      return res.item
    }
    return null;
  }

  const loadQuestion = async (question_id, question_type) => {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/questions/load_question`, { question_id })
    
    const res = response.data;
    if (res && res.status === "success") {
      const question = res.question

      if(question_type == "fill"){
        const items = await loadAllQuestionItem(question_id)
        question.answers = items;
      }
    }
  }

  const handleNextQuestion = () => {
    if (currentQuestionIndex < questionsData.length - 1) {
      handlePrevNextClick('next');
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    }
  };

  const handlePreviousQuestion = () => {
    if (currentQuestionIndex > 0) {
      handlePrevNextClick('prev');
      setCurrentQuestionIndex(currentQuestionIndex - 1);
    }
  };

  const resetSelection = () => {
    setSelectedLeft(null);
    setSelectedPairs({});
    setPairCount(1);
  };

  const initializeSelections = () => {
    if (currentQuestion.question_type === 'matching') {
      const initialPairs = {};
      let count = 1;
      currentQuestion.matching_items.ItemMatchingQuestion.forEach((item, index) => {
        initialPairs[item.ItemLeftSideSeq] = currentQuestion.matching_items.ItemMatchingChoice[index].ItemRightSideSeq;
        count++;
      });
      setSelectedPairs(initialPairs);
      setPairCount(count);
    }
  };

  const handleLeftItemClick = (index) => {
    if (selectedPairs[index]) {
      const rightIndex = selectedPairs[index];
      const newPairs = { ...selectedPairs };
      delete newPairs[index];
      setSelectedPairs(newPairs);
      setSelectedLeft(null);
    } else {
      setSelectedLeft(index);
    }
  };

  const handleRightItemClick = (index) => {
    const leftIndex = Object.keys(selectedPairs).find(key => selectedPairs[key] === index);
    if (leftIndex) {
      const newPairs = { ...selectedPairs };
      delete newPairs[leftIndex];
      setSelectedPairs(newPairs);
    } else if (selectedLeft !== null) {
      const newPairs = { ...selectedPairs, [selectedLeft]: index };
      setSelectedPairs(newPairs);
      setPairCount(pairCount + 1);
    }
    setSelectedLeft(null);
  };

  const handleFillAnswerChange = (index, value) => {
    const newFillAnswers = [...fillAnswers];
    newFillAnswers[index] = value;
    setFillAnswers(newFillAnswers);
  };

  const renderMatchingQuestion = (question) => {
    return (
      <Stack spacing={4}>
        <Box>
          {question.matching_items.ItemMatchingQuestion.map((item) => (
            <Box
              key={item.ItemLeftSideSeq}
              borderWidth={1}
              p={4}
              rounded="md"
              borderColor={selectedPairs[item.ItemLeftSideSeq] ? "blue.500" : (selectedLeft === item.ItemLeftSideSeq ? "blue.500" : "gray.300")}
              bg={selectedPairs[item.ItemLeftSideSeq] ? "blue.50" : (selectedLeft === item.ItemLeftSideSeq ? "blue.50" : "white")}
              onClick={() => handleLeftItemClick(item.ItemLeftSideSeq)}
              cursor="pointer"
              position="relative"
            >
              {item.ItemLeftSide}
              {selectedPairs[item.ItemLeftSideSeq] && (
                <Box
                  position="absolute"
                  top="50%"
                  left={0}
                  transform="translate(-50%, -50%)"
                  borderRadius="full"
                  bg="blue.500"
                  color="white"
                  width="20px"
                  height="20px"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  fontSize="12px"
                >
                  {Object.keys(selectedPairs).find(key => selectedPairs[key] === selectedPairs[item.ItemLeftSideSeq])}
                </Box>
              )}
            </Box>
          ))}
        </Box>
        <Box>
          {question.matching_items.ItemMatchingChoice.map((choice) => (
            <Box
              key={choice.ItemRightSideSeq}
              borderWidth={1}
              p={4}
              rounded="md"
              borderColor={Object.values(selectedPairs).includes(choice.ItemRightSideSeq) ? "blue.500" : "gray.300"}
              bg={Object.values(selectedPairs).includes(choice.ItemRightSideSeq) ? "blue.50" : "white"}
              onClick={() => handleRightItemClick(choice.ItemRightSideSeq)}
              cursor="pointer"
              position="relative"
            >
              {choice.ItemRightSide}
              {Object.keys(selectedPairs).find(key => selectedPairs[key] === choice.ItemRightSideSeq) && (
                <Box
                  position="absolute"
                  top="50%"
                  left={0}
                  transform="translate(-50%, -50%)"
                  borderRadius="full"
                  bg="blue.500"
                  color="white"
                  width="20px"
                  height="20px"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  fontSize="12px"
                >
                  {Object.keys(selectedPairs).find(key => selectedPairs[key] === choice.ItemRightSideSeq)}
                </Box>
              )}
            </Box>
          ))}
        </Box>
      </Stack>
    );
  };

  const renderFillQuestion = (question) => {
    return (
      <Stack spacing={4}>
        {question.answers.map((answer, index) => (
          <Input
            key={answer.id}
            placeholder="กรุณากรอกคำตอบของคุณที่นี่"
            value={fillAnswers[index]}
            onChange={(e) => handleFillAnswerChange(index, e.target.value)}
            borderWidth={1}
            p={4}
            rounded="md"
            borderColor="gray.300"
            bg="white"
          />
        ))}
      </Stack>
    );
  };

  const renderChoiceQuestion = (question) => {
    return (
      <>
        {question.choices.map((choice) => (
          <Box key={choice.id} borderWidth={1} p={4} rounded="md" borderColor={choice.isCorrect ? "green.500" : "gray.300"} bg={choice.isCorrect ? "green.50" : "white"}>
            {choice.text}
          </Box>
        ))}
      </>
    );
  };

  const renderQuestionContent = (question) => {
    switch (question.question_type) {
      case 'matching':
        return renderMatchingQuestion(question);
      case 'fill':
        return renderFillQuestion(question);
      case 'choice':
        return renderChoiceQuestion(question);
      default:
        return null;
    }
  };

  return (
    <>
      <Box borderWidth={1} p={6} rounded="md" bg="white">
        <Text mb={4} fontWeight={600}>{currentQuestion.content}</Text>
        <Stack spacing={4}>
          {renderQuestionContent(currentQuestion)}
        </Stack>
      </Box>
      <Box mt={2}>
        <Flex align="center" justify="space-between">
          <Button leftIcon={<BiLeftArrow />} variant="ghost" colorScheme="blue" onClick={handlePreviousQuestion} disabled={currentQuestionIndex === 0}>ก่อนหน้า</Button>
          <Text color="navy">{`ข้อที่ ${currentQuestionIndex + 1}/${questionsData.length}`}</Text>
          <Button rightIcon={<BiRightArrow />} variant="ghost" colorScheme="blue" onClick={handleNextQuestion} disabled={currentQuestionIndex === questionsData.length - 1}>ถัดไป</Button>
        </Flex>
      </Box>
    </>
  );
};

export default QuestionPreviewBox;
*/




/*import { useEffect, useState } from "react";
import { Box, Button, Text, Stack, Flex } from "@chakra-ui/react";
import { BiLeftArrow, BiRightArrow } from "react-icons/bi";

const questionsData = [
    {
      type: "choice",
      question: "สารใดมีสูตรเคมี H₂SO₄ และมีความสำคัญในการผลิตปุ๋ยเคมีและกระบวนการเคมีอื่นๆ ในอุตสาหกรรม?",
      description: "กรดกำมะถัน หรือ กรดซัลฟิวริก (sulfuric acid) มีสูตรเคมีว่า H₂SO₄ เป็นกรดแร่ (mineral acid) และมีความสำคัญในการผลิตปุ๋ยเคมีและกระบวนการเคมีอื่นๆ ในอุตสาหกรรม",
      choices: [
        { id: "1", text: "กรดไฮโดรคลอริก (Hidrocloric acid)", isCorrect: false },
        { id: "2", text: "กรดไนเตรก (Nitric acid)", isCorrect: false },
        { id: "3", text: "กรดกำมะถัน (Sulfuric acid)", isCorrect: true },
        { id: "4", text: "กรดฟอสฟอริก (Phosphoric acid)", isCorrect: false },
      ]
    },
    {
      type: "choice",
      question: "ชนิดของเซลล์ที่มีแสงสีเขียวสามารถสังเคราะห์แสงเป็นอาหารได้คือ?",
      description: "เซลล์โคโลฟิลล์ (Chlorophyll) ในเซลล์พืชและสัตว์ที่มีแสงสีเขียวสามารถสังเคราะห์แสงเป็นอาหารโดยใช้กระบวนการสังเคราะห์แสง",
      choices: [
        { id: "1", text: "เซลล์กรีนโคโลฟิลล์ (Green chlorophyll cell)", isCorrect: false },
        { id: "2", text: "เซลล์เยลโคโลฟิลล์ (Yellow chlorophyll cell)", isCorrect: false },
        { id: "3", text: "เซลล์ไซแอนท์โคโลฟิลล์ (Cyan chlorophyll cell)", isCorrect: false },
        { id: "4", text: "เซลล์โคโลฟิลล์ (Chlorophyll cell)", isCorrect: true },
      ]
    },
    {
      type: "choice",
      question: "วัดระดับเสียงใช้หน่วยอะไร?",
      description: "ระดับเสียงถูกวัดด้วยหน่วยเดซิเบล (Decibel) ซึ่งเป็นหน่วยการวัดระดับเสียง",
      choices: [
        { id: "1", text: "เฮิรตซ์ (Hertz)", isCorrect: false },
        { id: "2", text: "เดซิเบล (Decibel)", isCorrect: true },
        { id: "3", text: "วัตต์ (Watt)", isCorrect: false },
        { id: "4", text: "แอมป์ (Ampere)", isCorrect: false },
      ]
    },
    {
      type: "choice",
      question: "ถ้า 2x = 16, x มีค่าเท่าไร?",
      description: "เราสามารถหาค่าของ x ได้โดยการแยกด้วย 2 ทั้งสองข้างของสมการ",
      choices: [
        { id: "1", text: "4", isCorrect: true },
        { id: "2", text: "8", isCorrect: false },
        { id: "3", text: "16", isCorrect: false },
        { id: "4", text: "32", isCorrect: false },
      ]
    },
    {
      type: "fill",
      question: "อุณหภูมิของอากาศที่กรุงเทพมหานครและกรุงปักกิ่งเท่ากับ 38 และ –6 องศาเซลเซียส ตามลำดับ   อุณหภูมิของทั้งสองแห่งนี้ต่างกันกี่องศาเซลเซียส",
      description: "",
      choices: [
        { id: "1", text: "44", isCorrect: true }
      ]
    }
  ];  

const QuestionPreviewBox = ({initialIndex, handlePrevNextClick}) => {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(initialIndex || 0);
  const currentQuestion = questionsData[currentQuestionIndex];

  const handleNextQuestion = () => {
    if (currentQuestionIndex < questionsData.length - 1) {
        handlePrevNextClick('next')
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    }
  };

  const handlePreviousQuestion = () => {
    if (currentQuestionIndex > 0) {
      handlePrevNextClick('prev')
      setCurrentQuestionIndex(currentQuestionIndex - 1);
    }
  };

  useEffect(()=>{
    setCurrentQuestionIndex(initialIndex)
  },[initialIndex])

  return (
    <>
      <Box borderWidth={1} p={6} rounded="md" bg="white">
        <Text mb={4} fontWeight={600}>{currentQuestion.question}</Text>
        <Stack spacing={4}>
          {currentQuestion.choices.map((choice) => (
            <Box
              key={choice.id}
              borderWidth={1}
              p={4}
              rounded="md"
              borderColor={choice.isCorrect ? "green.500" : "gray.300"}
              bg={choice.isCorrect ? "green.50" : "white"}
            >
              {choice.text}
            </Box>
          ))}
        </Stack>
        <Box mt={4} p={4} borderWidth={1} rounded="md" borderColor="gray.300" bg="gray.100">
          <Text fontSize={"18"} fontWeight={600}>คำอธิบาย</Text>
          <Text>{currentQuestion.description}</Text>
        </Box>
      </Box>
      <Box mt={2}>
        <Flex align="center" justify="space-between">
          <Button leftIcon={<BiLeftArrow />} variant="ghost" colorScheme="blue" onClick={handlePreviousQuestion} disabled={currentQuestionIndex === 0}>ก่อนหน้า</Button>
          <Text color="navy">{`ข้อที่ ${currentQuestionIndex + 1}/${questionsData.length}`}</Text>
          <Button rightIcon={<BiRightArrow />} variant="ghost" colorScheme="blue" onClick={handleNextQuestion} disabled={currentQuestionIndex === questionsData.length - 1}>ถัดไป</Button>
        </Flex>
      </Box>
    </>
  );
};

export default QuestionPreviewBox;
*/