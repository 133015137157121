import React from 'react';
import { Box, Text } from '@chakra-ui/react';

export const LabelValueWidget = ({ label, value, onClick, ...rest }) => {
  return (
    <Box cursor={"pointer"} {...rest} onClick={onClick && (() => onClick())} textAlign="center" py={0} px={1} border="1px" borderColor="gray.200" borderRadius="md">
      <Text py={0} fontSize="xs" color="gray.500">
        {label}
      </Text>
      <Text fontSize="xs" fontWeight="bold" color="black">
        {value?value:"-"}
      </Text>
    </Box>
  );
};
