import React from 'react';
import ReactQuill from 'react-quill';
import { Box, Button, useColorModeValue } from '@chakra-ui/react';

const CustomToolbar = () => (
  <Box bg={useColorModeValue('transparent', 'navy.900')}>
    <Box
      id='toolbar'
      borderTopStartRadius='8px'
      borderTopEndRadius='8px'
      borderBottom='0px solid transparent !important'
    >
      <select
        className='ql-header'
        defaultValue={''}
        onChange={(e) => e.persist()}
      >
        <option value='1'></option>
        <option value='2'></option>
        <option value='3'></option>
        <option value='4'></option>
      </select>

      <select className="ql-color">
        <option value="red"></option>
        <option value="green"></option>
        <option value="blue"></option>
        <option value="navy"></option>
        <option value="orange"></option>
        <option value="#C35214"></option>
        <option value="violet"></option>
        <option value="#d0d1d2"></option>
        <option selected></option>
    </select>

      <Button
        display='flex !important'
        justifyContent='center !important'
        alignItems='center !important'
        me='5px !important'
        className='ql-bold'
      ></Button>
      <Button
        display='flex !important'
        justifyContent='center !important'
        alignItems='center !important'
        me='5px !important'
        className='ql-italic'
      ></Button>
      <Button
        display='flex !important'
        justifyContent='center !important'
        alignItems='center !important'
        me='5px !important'
        className='ql-underline'
      ></Button>
      <Button
        display='flex !important'
        justifyContent='center !important'
        alignItems='center !important'
        me='5px !important'
        className='ql-list'
        value='ordered'
      ></Button>
      <Button
        display='flex !important'
        justifyContent='center !important'
        alignItems='center !important'
        className='ql-list'
        value='bullet'
      ></Button>
      <Button
        display='flex !important'
        justifyContent='center !important'
        alignItems='center !important'
        className='ql-link'
        value='link'
      ></Button>
    </Box>
  </Box>
);

export default class Editor extends React.Component {
  constructor(props) {
    super(props);
    this.state = { editorHtml: props.defaultValue || '' }; // Set initial state from defaultValue prop
    //this.handleChange = this.handleChange.bind(this);
  }

  componentDidUpdate(prevProps) {
    // Check if defaultValue prop has changed
    if (this.props.defaultValue !== prevProps.defaultValue) {
      // Update the state with new defaultValue
      this.setState({ editorHtml: this.props.defaultValue || '' });
    }
  }

  handleChange(html) {
    this.setState({ editorHtml: html });
    this.props.handleChange(html)
  }

  render() {
    return (
      <div className='text-editor'>
        <CustomToolbar />
        <ReactQuill
          style={{height:"200px"}}
          value={this.state.editorHtml} // Set value prop to editorHtml state
          onChange={(value)=>{this.handleChange(value)}}
          placeholder={this.props.placeholder}
          modules={Editor.modules}
        />
      </div>
    );
  }
}

Editor.modules = {
  toolbar: [
    [{ size: [] }],
    ['bold', 'italic', 'underline'],
    [{ list: 'ordered' }, { list: 'bullet' }, 'link'],
  ],
  clipboard: {
    matchVisual: false,
  },
};

Editor.modules = {
  toolbar: {
    container: '#toolbar',
  },
  clipboard: {
    matchVisual: false,
  },
};

Editor.formats = [
  'header',
  'font',
  'size',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'link',
  'image',
  'color',
];
