

// Chakra imports
import { Portal, Box, useColorMode, Stack, useDisclosure, useColorModeValue, Flex, Text, Image, Spacer, Link } from '@chakra-ui/react';
import 'assets/css/plugin-styles.css';
import Configurator from 'components/Configurator/Configurator';
import FixedPlugin from 'components/FixedPlugin/FixedPlugin';
import Footer from 'components/Footer/Footer.js';
// Custom components
import MainPanel from 'components/Layout/MainPanel';
import PanelContainer from 'components/Layout/PanelContainer';
import PanelContent from 'components/Layout/PanelContent';
// Layout components
import AdminNavbar from 'components/Navbars/AdminNavbar.js';
import Sidebar from 'components/Sidebar/Sidebar.js';
import { SidebarContext } from 'contexts/SidebarContext';
import React, { useEffect, useState } from 'react';
import 'react-quill/dist/quill.snow.css'; // ES6
import { Redirect, Route, Switch } from 'react-router-dom';
import routes from 'routes.js';
import loginRoutes from 'login_routes';
import ipstLightLogo from "assets/svg/ipst.svg";

import {
	ArgonLogoDark,
	ArgonLogoLight,
	ChakraLogoDark,
	ChakraLogoLight,
	ArgonLogoMinifiedDark,
	ArgonLogoMinifiedLight
} from 'components/Icons/Icons';
import { useAuth } from 'auth/AuthProvider';
import { FaRegUserCircle, FaSignInAlt, FaSignOutAlt, FaUserAlt } from 'react-icons/fa';
// Custom Chakra theme
export default function Dashboard(props) {
	const { ...rest } = props;
	// states and functions
	const [ fixed, setFixed ] = useState(false);
	const [ toggleSidebar, setToggleSidebar ] = useState(false);
	const [ sidebarWidth, setSidebarWidth ] = useState(275);
	const { user, login, logout, isUserExist } = useAuth();
	const [ currentRoutes, setCurrentRoutes ] = useState(routes)

	useEffect(()=>{
		//console.log(`user: ${JSON.stringify(user)}`)
		if(!isUserExist()){
			setCurrentRoutes(loginRoutes)
		}
		else{
			setCurrentRoutes(routes)
		}
	},[user])

	// ref for main panel div
	const mainPanel = React.createRef();
	// functions for changing the states from components
	const getRoute = () => {
		return window.location.pathname !== '/user/full-screen-maps';
	};
	const getActiveRoute = (routes) => {
		let activeRoute = 'Default Brand Text';
		for (let i = 0; i < routes.length; i++) {
			if (routes[i].collapse) {
				let collapseActiveRoute = getActiveRoute(routes[i].items);
				if (collapseActiveRoute !== activeRoute) {
					return collapseActiveRoute;
				}
			} else if (routes[i].category) {
				let categoryActiveRoute = getActiveRoute(routes[i].items);
				if (categoryActiveRoute !== activeRoute) {
					return categoryActiveRoute;
				}
			} else {
				if (window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1) {
					return routes[i].name;
				}
			}
		}
		return activeRoute;
	};

	const getRouteNavType = (routes) => {
		let activeRoute = 'Default Brand Text';
		for (let i = 0; i < routes.length; i++) {
			if (routes[i].collapse) {
				let collapseActiveRoute = getRouteNavType(routes[i].items);
				if (collapseActiveRoute !== activeRoute) {
					return collapseActiveRoute;
				}
			} else if (routes[i].category) {
				let categoryActiveRoute = getRouteNavType(routes[i].items);
				if (categoryActiveRoute !== activeRoute) {
					return categoryActiveRoute;
				}
			} else {
				if (window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1) {
					return routes[i].navLayout;
				}
			}
		}
		return activeRoute;
	};

	const isShownRoute = (routes) => {
		let activeRoute = 'Default Brand Text';
		for (let i = 0; i < routes.length; i++) {
			if (routes[i].collapse) {
				let collapseActiveRoute = isShownRoute(routes[i].items);
				if (collapseActiveRoute !== activeRoute) {
					return collapseActiveRoute;
				}
			} else if (routes[i].category) {
				let categoryActiveRoute = isShownRoute(routes[i].items);
				if (categoryActiveRoute !== activeRoute) {
					return categoryActiveRoute;
				}
			} else {
				if (window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1) {
					return routes[i].isVisible;
				}
			}
		}
		return activeRoute;
	};

	const getActiveNavbar = (routes) => {
		let activeNavbar = false;
		for (let i = 0; i < routes.length; i++) {
			if (routes[i].collapse) {
				let collapseActiveNavbar = getActiveNavbar(routes[i].items);
				if (collapseActiveNavbar !== activeNavbar) {
					return collapseActiveNavbar;
				}
			} else if (routes[i].category) {
				let categoryActiveNavbar = getActiveNavbar(routes[i].items);
				if (categoryActiveNavbar !== activeNavbar) {
					return categoryActiveNavbar;
				}
			} else {
				if (window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1) {
					return routes[i].secondaryNavbar;
				}
			}
		}
		return activeNavbar;
	};
	const getRoutes = (routes) => {
		return routes.map((prop, key) => {
			if (prop.layout === '/user') {
				return <Route path={prop.layout + prop.path} component={prop.component} key={key} />;
			}
			if (prop.collapse) {
				return getRoutes(prop.items);
			}
			if (prop.category) {
				return getRoutes(prop.items);
			} else {
				return null;
			}
		});
	};
	//let bgBoxHeight = '40vh';
	let bgBoxHeight = '10vh';

	let bgBoxColor = useColorModeValue('blue.500', 'navy.900');

	const { isOpen, onOpen, onClose } = useDisclosure();
	const { colorMode } = useColorMode();
	document.documentElement.dir = 'ltr';
	document.documentElement.layout = 'admin';

	// Chakra Color Mode
	return (
		<Box>
			<SidebarContext.Provider
				value={{
					sidebarWidth,
					setSidebarWidth,
					toggleSidebar,
					setToggleSidebar
				}}>
					
				{isUserExist() && <Box minH={bgBoxHeight} h='100% !important' w='100%' position='absolute' bg={bgBoxColor} top='0' />}

				{!isUserExist() && <Flex direction={"column"} align={"center"} w="100%" justify={"center"} mt={40}>
					<Text fontSize={21}>คุณได้ออกจาก classroom แล้ว</Text>
					<Flex align={"center"} mt={2}>
						<FaSignOutAlt />
						<Link ml={2} fontSize={20} fontWeight={600} color="blue.600" href={`${process.env.REACT_APP_MYIPST_URL}`}>ไปที่ MyIPST</Link>
					</Flex>
					
				</Flex>}
				
				<MainPanel
				    //bg={bgBoxColor}
					w={{
						base: '100%',
						xl: `calc(100% - ${sidebarWidth}px)`
					}}>
					<Portal>
						<Box>
							<AdminNavbar
								onOpen={onOpen}
								logoText={'Argon Dashboard Chakra PRO'}
								brandText={getActiveRoute(currentRoutes)}
								secondary={getActiveNavbar(currentRoutes)}
								layoutType={getRouteNavType(currentRoutes)}
								fixed={fixed}
								{...rest}
							/>
						</Box>
					</Portal>

					{getRoute() ? (
						<PanelContent>
							<PanelContainer>
								<Switch>
									{getRoutes(currentRoutes)}
									<Redirect from='/user' to='/user/dashboard/default' />
								</Switch>
							</PanelContainer>
						</PanelContent>
					) : null}
					{isUserExist() && <Box> <Footer /></Box>}
					{isUserExist() && <Portal>
						<Box>
							<FixedPlugin fixed={fixed} onOpen={onOpen} />
						</Box>
					</Portal>}
					<Configurator
						secondary={getActiveNavbar(currentRoutes)}
						isOpen={isOpen}
						onClose={onClose}
						isChecked={fixed}
						onSwitch={(value) => {
							setFixed(value);
						}}
					/>
				</MainPanel>

				<Sidebar
					routes={currentRoutes}
					logo={
						sidebarWidth === 275 ? (
							<Stack direction='row' spacing='12px' align='center' justify='center'>
								<Flex>
									<Image mr={2} src={ipstLightLogo} w="27px" h="27px"/>
									<Text>My IPST</Text>
								</Flex>
							</Stack>
						) : colorMode === 'light' ? (
							<ArgonLogoMinifiedDark w='36px' h='36px' />
						) : (
							<ArgonLogoMinifiedLight w='36px' h='36px' />
						)
					}
					display='none'
					{...rest}
				/>
			</SidebarContext.Provider>
		</Box>
	);
}
