import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Box,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Checkbox,
  CheckboxGroup,
  Stack,
} from '@chakra-ui/react';
import Swal from 'sweetalert2';

const GroupNumberSelector = ({ activityID, initialGroups = undefined }) => {
  const [selectedGroups, setSelectedGroups] = useState([]); // Initial selected groups
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    if (initialGroups) {
      setSelectedGroups(initialGroups);
    }

    // Fetch the group data from the server using Axios
    const fetchGroupData = async () => {
      try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/activity_group/get_student_group_activity`, {
          ipst_activity_id: activityID,
        });

        if (response.data && response.data.status === 'success') {
          const groups = response.data.groups;
          if (groups.length > 0) {
            const groupNumbers = groups.map((g) => g.group_num);
            setSelectedGroups(groupNumbers);
          } else {
            setSelectedGroups([]);
          }
        }
      } catch (error) {
        Swal.fire('Error!', 'Cannot connect to the server!', 'warning');
      }
    };

    fetchGroupData();
  }, [activityID, initialGroups]);

  const handleCheckboxChange = (selectedValues) => {
    setSelectedGroups(selectedValues.map(Number));
  };

  const openModal = () => setIsModalOpen(true);
  const closeModal = async () => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/activity_group/save_student_group_activity`, {
        groups: JSON.stringify(selectedGroups),
        ipst_activity_id: activityID,
      });

      if (response.data && response.data.status === 'success') {
        Swal.fire({
          title: 'Success!',
          text: 'บันทึกเรียบร้อย',
          icon: 'success',
          padding: '2em',
        }).then(() => {
          setIsModalOpen(false); // Close the modal
        });
      } else {
        Swal.fire('Error!', 'ไม่สามารถบันทึกข้อมูลได้!', 'warning');
      }
    } catch (error) {
      Swal.fire('Error!', 'Cannot connect to the server!', 'warning');
    }
  };

  return (
    <Box key={`b${activityID}`}>
      <Button variant="unstyled" key={`but${activityID}`} onClick={openModal}>
        {selectedGroups.length > 0 ? selectedGroups.join(', ') : 'ทุกกลุ่ม'}
      </Button>

      <Modal closeOnOverlayClick={false} key={`modal_${activityID}`} isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>เลือกกลุ่ม</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <CheckboxGroup value={selectedGroups.map(String)} onChange={handleCheckboxChange}>
              <Stack spacing={3}>
                {Array.from({ length: 10 }, (_, i) => (
                  <Checkbox key={i + 1} value={(i + 1).toString()}>
                    กลุ่ม {i + 1}
                  </Checkbox>
                ))}
              </Stack>
            </CheckboxGroup>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" onClick={closeModal}>
              ปิด
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default GroupNumberSelector;
