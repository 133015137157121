export const isAccessAllowed = (role, name, visible, allowRoles) => {
    //console.log(`allowRoles: ${allowRoles}`)
    if(!allowRoles || allowRoles == undefined || allowRoles.length <= 0){
        return true;
    }

    if(role){
        var val = false;
        allowRoles.forEach(r => {
            if(r.toLowerCase() == "teacher"){
                //console.log(`${role.toLowerCase()}, ${r.toLowerCase()}`)
                //console.log(`name: --> ${name}`)
                //console.log(`visible: --> ${visible}`)
                //console.log(`val: --> ${(role.toLowerCase().includes(r.toLowerCase()))}`)
            }
            
            if(role.toLowerCase().includes(r.toLowerCase())){
                //console.log("-----------------------------")
                val = true;
            }
        });

        return val;
    }
    return false;
}
