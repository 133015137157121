// AddEditActivityModal.js
import React, { useEffect, useState } from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    Input,
    useToast,
} from '@chakra-ui/react';
import axios from 'axios';
import { API_URL } from 'variables/constant';

const AddEditActivityModal = ({ isOpen, onClose, activity, groupId, setActivity, isEditing, activityIndex, setLessons }) => {
    const toast = useToast();
    const [activityName, setActivityName] = useState(activity ? activity.activity : '');

    useEffect(() => {
        if (isEditing && isOpen && activity) {
            setActivityName(activity.activity);
        } else {
            setActivityName('');
        }
    }, [isEditing, isOpen, activity]);

    const handleSaveActivity = async () => {
        if (!activityName) {
            toast({
                title: "กรุณากรอกชื่อกิจกรรม",
                status: "warning",
                duration: 3000,
                isClosable: true,
            });
            return;
        }

        const payload = {
            activity_opt: isEditing ? 'edit' : 'insert',
            activity_id: isEditing ? activity.id : null,
            group_id: groupId,
            activity: activityName,
        };

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/activity_group/new_activity_insert`, payload);
            if (response.data && response.data.status === "success") {
                setLessons(prevLessons => {
                    const updatedLessons = [...prevLessons];
                    const groupIndex = updatedLessons.findIndex(group => group.id === groupId);

                    if (groupIndex !== -1) {
                        if (isEditing) {
                            updatedLessons[groupIndex].activities[activityIndex] = { ...activity, activity: activityName };
                        } else {
                            updatedLessons[groupIndex].activities.push({
                                id: response.data.activity_id, // assuming API returns new activity id
                                activity: activityName,
                                status: 'draft',
                            });
                        }
                    }

                    return updatedLessons;
                });

                toast({
                    title: "บันทึกข้อมูลเรียบร้อย",
                    status: "success",
                    duration: 3000,
                    isClosable: true,
                });
                onClose();
            } else {
                throw new Error("Failed to save the activity");
            }
        } catch (error) {
            toast({
                title: 'Error!',
                description: "Cannot connect to the server!",
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
        }
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>{isEditing ? 'แก้ไขกิจกรรม' : 'เพิ่มกิจกรรม'}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Input
                        placeholder="ชื่อกิจกรรม"
                        value={activityName}
                        onChange={(e) => setActivityName(e.target.value)}
                    />
                </ModalBody>
                <ModalFooter>
                    <Button colorScheme="blue" mr={3} onClick={handleSaveActivity}>
                        {isEditing ? 'บันทึก' : 'เพิ่ม'}
                    </Button>
                    <Button variant="ghost" onClick={onClose}>ยกเลิก</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default AddEditActivityModal;
