import Swal from 'sweetalert2'

export function confirmDeleteDialog(title, msg, onConfirmedFunc){
    Swal.fire({
        title: `${title}`,
        text: `${msg}`,
        icon: `warning`,
        showCancelButton: true,
        denyButtonColor: "#6CB4EE",
        confirmButtonColor: "#ED0A3F",
        confirmButtonText: `ยืนยัน (ลบ)`,
        cancelButtonText: "ยกเลิก"
      }).then((result) => {
        if (result.isConfirmed && onConfirmedFunc) {
            onConfirmedFunc()
        }
      });
}

export function showSuccessDialog(title, msg){
  Swal.fire({
    title: title,
    text: msg,
    icon: 'success',
    timer: 5000
  });
}

export function showInfoDialog(title, msg) {
  Swal.fire({
    title: `${title}`,
    text: `${msg}`,
    status: 'info',
    confirmButtonColor: '#0076CE',
    timer: 3000,
    isClosable: true
  });
}

export function showWarningDialog(title, msg) {
  Swal.fire({
    title: `${title}`,
    text: `${msg}`,
    status: 'warning',
    confirmButtonColor: '#0076CE',
    timer: 3000,
    isClosable: true
  });
}

export function showErrorDialog(title, msg){
  Swal.fire({
    title: `${title}`,
    text: `${msg}`,
    status: 'error',
    timer: 3000,
    isClosable: true
  });
}

export function showWarningToast(toast, title, msg) {
  toast({
    title: `${title}`,
    description: `${msg}`,
    status: 'warning',
    duration: 5000,
    isClosable: true
  });
}

export function showInfoToast(toast, title, msg) {
  toast({
    title: `${title}`,
    description: `${msg}`,
    status: 'info',
    duration: 3000,
    isClosable: true
  });
}

export function showSuccessToast(toast, title, msg) {
  toast({
    title: `${title}`,
    description: `${msg}`,
    status: 'success',
    duration: 3000,
    isClosable: true
  });
}

export function showErrorToast(toast, title, msg) {
  toast({
    title: `${title}`,
    description: `${msg}`,
    status: 'error',
    duration: 3000,
    isClosable: true
  });
}

export function showCenterToast(toast, title, msg) {
  toast({
    title: `${title}`,
    description: `${msg}`,
    position: 'center',
    status: 'success',
    duration: 3000,
    isClosable: true
  });
}