import { useEffect, useState } from "react";
import { Box, Button, Text, Stack, Flex, Input, useToast, Spinner, AlertDialog, AlertDialogBody, AlertDialogFooter, AlertDialogHeader, AlertDialogContent, AlertDialogOverlay, Progress, Image } from "@chakra-ui/react";
import { BiLeftArrow, BiRightArrow } from "react-icons/bi";
import { MathJax, MathJaxContext } from "better-react-mathjax";
import Swal from "sweetalert2";
import { FaSave } from "react-icons/fa";
import { loadQuestion } from "util/questionUtil";
import { showWarningToast } from "util/alertUtil";
import { showSuccessToast } from "util/alertUtil";

const ExamPreview = ({ 
    exam_id, 
    studentScores=undefined,
    allowAnswerEdit=true,
    submitExercise = undefined, 
    saveQuestionAnswer = undefined, 
    showScoreRightPanel = true, 
    studentAnswer = undefined,
    onQuestionChange = undefined,
    countAllAnswers = undefined,
    isStudent = undefined
}) => {
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [questions, setQuestions] = useState([]);
    const [completedQuestions, setCompletedQuestions] = useState(new Set());
    const [selectedPairs, setSelectedPairs] = useState({});
    const [selectedLeft, setSelectedLeft] = useState(null);
    const [questionList, setQuestionList] = useState([]);
    const [showExplanation, setShowExplanation] = useState(false);
    const [showSubmitButton, setShowSubmitButton] = useState(true);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [score, setScore] = useState(0);
    const [selectedChoices, setSelectedChoices] = useState({});
    const [fillAnswers, setFillAnswers] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const toast = useToast();
    const [startTime, setStartTime] = useState(null);
    const [endTime, setEndTime] = useState(null);
    const [studentPoints, setStudentPoint] = useState(studentScores);
    const [answerCount, setAnswerCount] = useState(countAllAnswers)
    const currentQuestion = questions[currentQuestionIndex];

    const config = {
        loader: { load: ["[tex]/html"] },
        tex: {
            packages: { "[+]": ["html"] },
            inlineMath: [
                ["$", "$"],
                ["\\(", "\\)"]
            ],
            displayMath: [
                ["$$", "$$"],
                ["\\[", "\\]"]
            ]
        }
    };

    const loadAllQuestion = (exam_id) => {
        setIsLoading(true);
        fetch(`${process.env.REACT_APP_API_URL}/exams/list_all_question`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ exam_id }),
        })
            .then(response => response.json())
            .then(res => {
                if (res && res.status === "success") {
                    const qs = res.question;
                    setQuestionList(qs);
                }
            })
            .catch((error) => {
                showWarningToast(toast, "Error!.", `${error}`, 'warning');
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    useEffect(() => {
        if(countAllAnswers){
            setAnswerCount(countAllAnswers)
        }
    }, [countAllAnswers])

    useEffect(() => {
        if(studentScores){
            setStudentPoint(studentScores)
        }
    },[studentScores])

    useEffect(() => {
        setStartTime(Date.now());
        setEndTime(null);
        if(onQuestionChange && questions && questions.length > 0){
            onQuestionChange(questions[currentQuestionIndex])
        }
    }, [currentQuestionIndex]);

    useEffect(() => {
        setQuestions([]);
        setCurrentQuestionIndex(0);
        setCompletedQuestions(new Set());
        setSelectedPairs({});
        setSelectedLeft(null);
        setQuestionList([]);
        setShowExplanation(!allowAnswerEdit);
        setShowSubmitButton(true);
        setSelectedChoices({});
        setFillAnswers({});
        //setScore(0);
        loadAllQuestion(exam_id);
    }, [exam_id]);

    useEffect(() => {
        const generatePairJSON = () => {
            if (currentQuestion && currentQuestion.matching_items && currentQuestion.question_type === "matching") {  // Ensure currentQuestion and matching_items are defined
                const matchingItems = JSON.parse(currentQuestion.matching_items);
    
                var durationInSeconds = 0;
                var isAnsweredCorrect = false;
    
                // Ensure that ItemMatchingQuestion exists and is an array
                if (Array.isArray(matchingItems.ItemMatchingQuestion)) {
                    const currentQuestionPairs = selectedPairs[currentQuestionIndex] || {};
                    const pairArray = matchingItems.ItemMatchingQuestion.map((item, index) => {
                        const leftSeq = item.ItemLeftSideSeq;
                        const rightSeq = currentQuestionPairs[leftSeq] || null;
    
                        if (rightSeq !== null) {  // Only add to array if rightSeq is not null
                            return {
                                item_left_seq: leftSeq,
                                item_right_seq: rightSeq,
                                pair_number: index + 1,
                                is_correct: `${rightSeq}` === item.ItemRightSideKey // Check if the selected right side matches the correct answer
                            };
                        } else {
                            return null;  // Return null for items that should not be included
                        }
                    }).filter(item => item !== null);  // Filter out the null values
    
                    console.log(JSON.stringify(pairArray, null, 2));
                    durationInSeconds = Math.floor((Date.now() - startTime) / 1000);
                    console.log(`Time taken to match all pairs: ${durationInSeconds} seconds`);
    
                    // Check if all left choices are selected
                    const allLeftSelected = matchingItems.ItemMatchingQuestion.every(item => currentQuestionPairs[item.ItemLeftSideSeq]);
    
                    if (allLeftSelected) {
                        // Check if all pairs are correct
                        const allCorrect = pairArray.every(pair => pair.is_correct);
                        if (allCorrect) {
                            isAnsweredCorrect = true;
                            console.log("The answer is correct for the entire matching question.");
                        } else {
                            console.log("The answer is incorrect for the entire matching question.");
                        }
                    } else {
                        console.log("Not all left choices have been selected.");
                    }
    
                    if (saveQuestionAnswer && currentQuestion.question_type === "matching") {
                        const testSetID = currentQuestion.test_set_id ?? "";
                        //alert(JSON.stringify(pairArray))
                        saveQuestionAnswer(testSetID, currentQuestion.id, "", JSON.stringify(pairArray), isAnsweredCorrect, durationInSeconds);
                    }
                } else {
                    console.warn("ItemMatchingQuestion is not an array or is undefined");
                }
            } else {
                console.warn("currentQuestion or matching_items is not defined");
            }
        };
    
        generatePairJSON();
    }, [selectedPairs, currentQuestionIndex]);
    
    useEffect(() => {
        const fetchQuestions = async () => {
            setIsLoading(true);
            const fetchedQuestions = [];
            for (const { id, question_type } of questionList) {
                const question = await loadQuestion(id, question_type);
                if (question) {
                    fetchedQuestions.push(question);
                }
            }
            setQuestions(fetchedQuestions);
            if(onQuestionChange && fetchedQuestions.length > 0){
                onQuestionChange(fetchedQuestions[0])
            }
            setIsLoading(false);
        };

        if (questionList.length > 0) {
            fetchQuestions();
        }
    }, [questionList]);

    useEffect(() => {
        setSelectedLeft(null);
        restoreSelections();
    }, [currentQuestionIndex, studentAnswer]);

    const markQuestionAsCompleted = () => {
        setCompletedQuestions((prev) => new Set(prev.add(currentQuestionIndex)));
    };

    const handleNextQuestion = () => {
        if (currentQuestionIndex < questions.length - 1) {
            setCurrentQuestionIndex(currentQuestionIndex + 1);
        }
    };

    const handlePreviousQuestion = () => {
        if (currentQuestionIndex > 0) {
            setCurrentQuestionIndex(currentQuestionIndex - 1);
        }
    };

    const restoreSelections = () => {
        if (currentQuestion) {
            //console.log(`studentAnswer ====> ${JSON.stringify(studentAnswer)}`)
            //console.log(`studentAnswer ====> ${typeof studentAnswer}`)
            const sForCurrentQuestion = studentAnswer ?? null;

            if (currentQuestion.question_type === 'matching' &&  Array.isArray(studentAnswer)) {
                console.log(`studentAnswer: ${JSON.stringify(studentAnswer)}`)
                let restoredPairs = {};
                if (sForCurrentQuestion) {
                    restoredPairs = {};
                    sForCurrentQuestion.forEach(pair => {
                        restoredPairs[pair.item_left_seq] = pair.item_right_seq;
                    });
                } else {
                    restoredPairs = selectedPairs[currentQuestionIndex] || {};
                }
                setSelectedPairs((prev) => ({ ...prev, [currentQuestionIndex]: restoredPairs }));
            }
    
            if (currentQuestion.question_type === 'fill') {
                //alert(sForCurrentQuestion.answer_text)
                const restoredAnswers = sForCurrentQuestion ? sForCurrentQuestion.answer_text: fillAnswers[currentQuestionIndex] || {};
                setFillAnswers((prev) => ({ ...prev, [currentQuestionIndex]: restoredAnswers }));
            }
    
            if (currentQuestion.question_type === 'choice') {
                const restoredChoice = sForCurrentQuestion ? sForCurrentQuestion.answered_id : selectedChoices[currentQuestionIndex] || null;
                setSelectedChoices((prev) => ({ ...prev, [currentQuestionIndex]: restoredChoice }));
            }
        }
    };
    

    const handleLeftItemClick = (leftSeq) => {
        const updatedPairs = { ...selectedPairs[currentQuestionIndex] };

        if (updatedPairs[leftSeq]) {
            delete updatedPairs[leftSeq];
            setSelectedPairs((prev) => ({ ...prev, [currentQuestionIndex]: updatedPairs }));
        } else if (selectedLeft !== null && selectedLeft === leftSeq) {
            setSelectedLeft(null);
        } else {
            setSelectedLeft(leftSeq);
        }
        markQuestionAsCompleted();
    };

    const handleRightItemClick = (rightSeq) => {
        const updatedPairs = { ...selectedPairs[currentQuestionIndex] };
        const leftSeq = Object.keys(updatedPairs).find(key => updatedPairs[key] === rightSeq);

        if (leftSeq) {
            delete updatedPairs[leftSeq];
            setSelectedPairs((prev) => ({ ...prev, [currentQuestionIndex]: updatedPairs }));
        } else if (selectedLeft !== null) {
            updatedPairs[selectedLeft] = rightSeq;
            setSelectedPairs((prev) => ({ ...prev, [currentQuestionIndex]: updatedPairs }));
            setSelectedLeft(null);
        }
    };

    const handleFillAnswerChange = (index, value) => {
        //alert('ab')
        setFillAnswers((prev) => ({
            ...prev,
            [currentQuestionIndex]: {
                ...prev[currentQuestionIndex],
                [index]: value,
            },
        }));
        markQuestionAsCompleted();
    
        if (saveQuestionAnswer && currentQuestion.question_type === "fill") {
            const testSetID = currentQuestion.test_set_id ?? "";
    
            // Check if the current answer is correct
            var userAnswer;
            const isAnsweredCorrect = currentQuestion.answers.every((answer, answerIndex) => {
                userAnswer = fillAnswers[currentQuestionIndex]?.[answerIndex] || "";
                return userAnswer.toString().trim() === answer.anwer_text.toString().trim();
            });
    
            // Calculate the time spent on the question
            const durationInSeconds = Math.floor((Date.now() - startTime) / 1000);
            console.log(`userAnswer ==> ${value}`)
    
            // Save the answer, passing the correctness and duration
            saveQuestionAnswer(testSetID, currentQuestion.id, "", value, isAnsweredCorrect, durationInSeconds);
        }
    };
    

    const handleChoiceSelection = (choiceId) => {
        setSelectedChoices((prev) => ({
            ...prev,
            [currentQuestionIndex]: choiceId,
        }));
        markQuestionAsCompleted();
    
        if (saveQuestionAnswer && currentQuestion.question_type === "choice") {
            const testSetID = currentQuestion.test_set_id ?? "";
    
            // Check if the selected choice is correct
            const isAnsweredCorrect = currentQuestion.choices.some(
                (choice) => choice.is_answer && choice.id === choiceId
            );
    
            // Calculate the time spent on the question
            const durationInSeconds = Math.floor((Date.now() - startTime) / 1000);
    
            // Save the answer, passing the correctness and duration
            saveQuestionAnswer(testSetID, currentQuestion.id, choiceId, "", isAnsweredCorrect, durationInSeconds);
        }
    };
    

    const renderMatchingQuestion = (question) => {
        const matchingItems = JSON.parse(question.matching_items);
        const currentPairs = selectedPairs[currentQuestionIndex] || {};
        return (
            <Stack spacing={4}>
                <Box>
                    {matchingItems.ItemMatchingQuestion.map((item) => (
                        <Box
                            key={item.ItemLeftSideSeq}
                            borderWidth={1}
                            p={4}
                            rounded="md"
                            borderColor={
                                currentPairs[item.ItemLeftSideSeq]
                                    ? "blue.500"
                                    : selectedLeft === item.ItemLeftSideSeq
                                        ? "blue.500"
                                        : "gray.300"
                            }
                            bg={
                                currentPairs[item.ItemLeftSideSeq]
                                    ? "blue.50"
                                    : selectedLeft === item.ItemLeftSideSeq
                                        ? "blue.50"
                                        : "white"
                            }
                            onClick={() => {
                                if(allowAnswerEdit){
                                    handleLeftItemClick(item.ItemLeftSideSeq)
                                }
                            }}
                            cursor="pointer"
                            position="relative"
                        >
                            <MathJax>
                                <Box textStyle="body1.default" dangerouslySetInnerHTML={{ __html: item.ItemLeftSide }} />
                            </MathJax>
                            {currentPairs[item.ItemLeftSideSeq] && (
                                <Box
                                    position="absolute"
                                    top="50%"
                                    left={0}
                                    transform="translate(-50%, -50%)"
                                    borderRadius="full"
                                    bg="blue.500"
                                    color="white"
                                    width="20px"
                                    height="20px"
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="center"
                                    fontSize="12px"
                                >
                                    {Object.keys(currentPairs).find(
                                        key => currentPairs[key] === currentPairs[item.ItemLeftSideSeq]
                                    )}
                                </Box>
                            )}
                        </Box>
                    ))}
                </Box>
                <Box>
                    {matchingItems.ItemMatchingChoice.map((choice) => (
                        <Box
                            key={choice.ItemRightSideSeq}
                            borderWidth={1}
                            p={4}
                            rounded="md"
                            borderColor={
                                Object.values(currentPairs).includes(choice.ItemRightSideSeq)
                                    ? "blue.500"
                                    : "gray.300"
                            }
                            bg={
                                Object.values(currentPairs).includes(choice.ItemRightSideSeq)
                                    ? "blue.50"
                                    : "white"
                            }
                            onClick={() => {
                                if(allowAnswerEdit){
                                    handleRightItemClick(choice.ItemRightSideSeq)}
                                }
                            }
                            cursor="pointer"
                            position="relative"
                        >
                            <MathJax>
                                <Box textStyle="body1.default" dangerouslySetInnerHTML={{ __html: choice.ItemRightSide }} />
                            </MathJax>
                            {Object.keys(currentPairs).find(
                                key => currentPairs[key] === choice.ItemRightSideSeq
                            ) && (
                                <Box
                                    position="absolute"
                                    top="50%"
                                    left={0}
                                    transform="translate(-50%, -50%)"
                                    borderRadius="full"
                                    bg="blue.500"
                                    color="white"
                                    width="20px"
                                    height="20px"
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="center"
                                    fontSize="12px"
                                >
                                    {Object.keys(currentPairs).find(
                                        key => currentPairs[key] === choice.ItemRightSideSeq
                                    )}
                                </Box>
                            )}
                        </Box>
                    ))}
                </Box>
            </Stack>
        );
    };

    const renderFillQuestion = (question) => {
        const answers = fillAnswers[currentQuestionIndex] || {};

        console.log(`Rendering fill answers: ${JSON.stringify(answers)}`);
        var val = ""
        if(typeof(answers)=="string"){
            val = answers
        }
        else{
            val = answers[0]
        }

        return (
            <Stack spacing={4}>
                {question.answers.map((answer, index) => (
                    <Input
                        isDisabled={!allowAnswerEdit}
                        key={answer.id}
                        placeholder="กรุณากรอกคำตอบของคุณที่นี่"
                        value={val || ""}
                        onChange={(e) => handleFillAnswerChange(index, e.target.value)}
                        borderWidth={1}
                        p={4}
                        rounded="md"
                        borderColor="gray.300"
                        bg="white"
                    />
                ))}
            </Stack>
        );
    };

    const renderChoiceQuestion = (question) => {
        const selectedChoice = selectedChoices[currentQuestionIndex];
        return (
            <>
                <MathJax>
                    {question.choices.map((choice) => (
                        <Flex direction={"column"}>
                            {choice && choice.image && <Image mb={1} maxH={"250px"} maxW={"400px"} src={`${REACT_APP_API_URL}/images/${choice.image}`} />}
                            <Box
                                key={`ch_${choice.id}`}
                                borderWidth={1}
                                mb={2}
                                p={3}
                                rounded="md"
                                borderColor={
                                    selectedChoice === choice.id ? "blue.500" : "gray.300"
                                }
                                bg={selectedChoice === choice.id ? "blue.50" : "white"}
                                onClick={() => {
                                    if(allowAnswerEdit){
                                        handleChoiceSelection(choice.id)
                                    }
                                }}
                                cursor="pointer"
                                dangerouslySetInnerHTML={{ __html: choice.anwer_text }}
                            />
                        </Flex>
                    ))}
                </MathJax>
            </>
        );
    };

    const renderQuestionContent = (question) => {
        if (question) {
            switch (question.question_type) {
                case 'matching':
                    return renderMatchingQuestion(question);
                case 'fill':
                    return renderFillQuestion(question);
                case 'choice':
                    return renderChoiceQuestion(question);
                default:
                    return null;
            }
        }
    };

    const updateImageSrc = (htmlContent, apiUrl) => {
        // Regex to match any URL that ends with an image file (e.g., .jpg, .png)
        const regex = /<img\s+[^>]*src=["']([^"']*\/([^\/]+\.(jpg|jpeg|png|gif|bmp)))["'][^>]*>/gi;
        const updatedContent = htmlContent.replace(regex, (match, fullUrl, fileName) => {
            return match.replace(fullUrl, `${apiUrl}/images/${fileName}`);
        });
        return updatedContent;
    };

    const handleSubmit = () => {
        /*let totalScore = 0;
        console.log(`filling detail ${JSON.stringify(fillAnswers)}`)

        questions.forEach((question, index) => {
            if (question.question_type === 'matching') {
                const correctPairs = JSON.parse(question.matching_items).ItemMatchingQuestion.every(
                    (item) =>
                        selectedPairs[index] &&
                        selectedPairs[index][item.ItemLeftSideSeq] &&
                        selectedPairs[index][item.ItemLeftSideSeq] === JSON.parse(question.matching_items).ItemMatchingChoice.find(
                            (choice) => choice.ItemRightSideSeq === selectedPairs[index][item.ItemLeftSideSeq]
                        )?.ItemRightSideSeq,
                );
                if (correctPairs) {
                    totalScore += 1;
                    console.log("matching point >> ")
                }
            } else if (question.question_type === 'fill') {

                const correctAnswers = question.answers.every(
                    (answer, answerIndex) =>
                        fillAnswers[index] &&
                        fillAnswers[index][answerIndex] &&
                        fillAnswers[index][answerIndex].toString().trim() === answer.anwer_text.toString().trim()
                );

                if (correctAnswers) {
                    totalScore += 1;
                    console.log("filling point >> ")
                }
            } else if (question.question_type === 'choice') {
                const correctChoice = question.choices.some(
                    (choice) => choice.is_answer && selectedChoices[index] === choice.id
                );
                if (correctChoice) {
                    totalScore += 1;
                    console.log("choice point >> ")
                }
            }
        });
        setScore(totalScore);*/

        setShowSubmitButton(false);
        setShowExplanation(true);
        setIsDialogOpen(false)

        if (typeof submitExercise === 'function' && submitExercise !== undefined) {
            submitExercise(questions.length)
        }

        Swal.fire({
            title: 'คุณทำข้อสอบเสร็จเรียบร้อย',
            text: `คะแนนของคุณคือ ${studentScores} คะแนน \n คุณสามารถย้อนกลับดูเฉลยได้ในส่วนของคำอธิบายในแต่ล่ะข้อ`,
            icon: 'info',
            confirmButtonText: 'OK'
        });
    };

    return (
        <Flex w="100%" justify={"center"} mb={5}>
            <Flex direction={"column"} w={{base: "100%", md: "840px"}} mr={1}>
                {isLoading ? (
                    <Flex justify="center" align="center" height="400px">
                        <Spinner size="xl" color="blue.500" />
                    </Flex>
                ) : (
                    <>
                        {allowAnswerEdit && <Flex align={"center"} mb={2}>
                            <Text mt={4} w={"130px"} textStyle="body1.default" fontSize={"xs"} mr={1}>ทำแล้ว {(answerCount ?? completedQuestions.size)} / {questions.length} ข้อ</Text>
                            <Box w="100%" mt={4} mr={3}>
                                <Progress borderWidth={"1px"} rounded={5} value={((answerCount ?? completedQuestions.size) / questions.length) * 100} colorScheme="blue" size="md" />
                            </Box>
                            {showSubmitButton && (
                                <Button leftIcon={<FaSave />} px={4} borderRadius="100px" size={"sm"} variant={"outline"} mt={4} colorScheme="blue" onClick={() => setIsDialogOpen(true)}>ส่งคำตอบ</Button>
                            )}
                        </Flex>}
                        {!allowAnswerEdit && isStudent != undefined && isStudent && <Flex justify={"center"} w="100%"><Text color="blue.600" mb={2}>คุณได้ส่งแบบฝึกหัดนี้ไปแล้ว (ไม่อนุญาติให้แก้ไขคำตอบ)</Text></Flex>}
                        {!allowAnswerEdit && showScoreRightPanel && showExplanation && isStudent != undefined && isStudent && <Flex direction="column" display={{ base: "flex", md: "none" }} mb={2} >
                            <Flex align={"center"} justify={"center"} p={4}>
                                <Text mr={2}>ผลการทดสอบ</Text>
                                <Text mr={2}>{studentPoints}/{questions.length}</Text>
                                <Text>คะแนน</Text>
                            </Flex>
                        </Flex>}
                        
                        <Box bt={5} bgColor={"blue.50"} roundedTop={"xl"}>
                            <Flex p={1} align="center" justify="space-between">
                                <Button textStyle="body1.default" leftIcon={<BiLeftArrow />} variant="ghost" colorScheme="blue" onClick={handlePreviousQuestion} disabled={currentQuestionIndex === 0}>ก่อนหน้า</Button>
                                <Text textStyle="body1.default" color="navy">{`ข้อที่ ${currentQuestionIndex + 1}/${questions.length}`}</Text>
                                <Button textStyle="body1.default" rightIcon={<BiRightArrow />} variant="ghost" colorScheme="blue" onClick={handleNextQuestion} disabled={currentQuestionIndex === questions.length - 1}>ถัดไป</Button>
                            </Flex>
                        </Box>
                        <MathJaxContext version={3} config={config}>
                            <Box borderWidth={1} p={6} roundedBottom={"xl"} bg="white">
                                {currentQuestion && currentQuestion.content && (
                                    <>
                                        <Box position="relative">
                                            <MathJax>
                                                <Box textStyle="body1.default" dangerouslySetInnerHTML={{ __html: updateImageSrc(currentQuestion.content, `${process.env.REACT_APP_API_URL}`) }} w="100%" />
                                            </MathJax>
                                            <Text
                                                position="absolute"
                                                bottom="5%"
                                                left="40%"
                                                color="gray.300"
                                                fontWeight="500"
                                                fontSize="8xl"
                                                transform="rotate(-30deg)"
                                                opacity="0.1"
                                            >
                                                สสวท.
                                            </Text>
                                        </Box>

                                        <Stack spacing={4} mt={4}>
                                            {renderQuestionContent(currentQuestion)}
                                        </Stack>
                                    </>
                                )}
                            </Box>
                            {showExplanation && !showSubmitButton && (
                                <Box mt={4} p={4} borderWidth={1} rounded="md" borderColor="gray.300" bg="gray.100">
                                    <Text fontSize={"18"} fontWeight={600}>คำอธิบาย</Text>
                                    {currentQuestion && currentQuestion.otim_explanation && <MathJax><Text dangerouslySetInnerHTML={{ __html: currentQuestion.otim_explanation }} /></MathJax>}
                                </Box>
                            )}
                        </MathJaxContext>

                        <AlertDialog
                            isOpen={isDialogOpen}
                            onClose={() => setIsDialogOpen(false)}
                        >
                            <AlertDialogOverlay>
                                <AlertDialogContent>
                                    <AlertDialogHeader textStyle="body1.default" fontSize="lg" fontWeight="bold">
                                        ยืนยันการส่งคำตอบ
                                    </AlertDialogHeader>

                                    <AlertDialogBody>
                                        <Flex direction={"column"}>
                                            {(answerCount ?? completedQuestions.size) !== questions.length && <Text textStyle="body1.default">คุณทำแบบทดสอบ {answerCount ?? completedQuestions.size} จากทั้งหมด {questions.length} ข้อ</Text>}
                                            <Text textStyle="body1.default">คุณแน่ใจหรือไม่ว่าต้องการส่งคำตอบของคุณ?</Text>
                                        </Flex>
                                    </AlertDialogBody>

                                    <AlertDialogFooter>
                                        <Button textStyle="body1.default" onClick={() => setIsDialogOpen(false)}>
                                            ยกเลิก
                                        </Button>
                                        <Button textStyle="body1.default" colorScheme="blue" onClick={handleSubmit} ml={3}>
                                            ส่งคำตอบ
                                        </Button>
                                    </AlertDialogFooter>
                                </AlertDialogContent>
                            </AlertDialogOverlay>
                        </AlertDialog>
                    </>
                )}
            </Flex>
            {!allowAnswerEdit && showScoreRightPanel && showExplanation && isStudent && <Flex direction="column" display={{ base: "none", md: "flex" }} mt={studentPoints ? "30px": "0px" } >
                <Flex direction="column" align={"center"} justify={"center"} borderTopRadius={15} w={"150px"} maxH={"130px"} p={4} borderWidth={2} borderColor={"blue.400"}>
                    <Text textStyle="h6" color="blue">ผลการทดสอบ</Text>
                    <Text textStyle="h3">{studentPoints}</Text>
                    <Text textStyle="body1.default">คะแนน</Text>
                </Flex>
                <Flex mt={"-2px"} direction="column" align={"center"} justify={"center"} borderBottomRadius={15} h={"20px"} py={4} borderWidth={2} borderColor={"blue.400"}>
                    <Text p={4} fontSize={12} textStyle="body1.default">เต็ม {questions.length} คะแนน</Text>
                </Flex>
            </Flex>}
        </Flex>
    );
};

export default ExamPreview;