import { createContext, useContext, useState, useEffect } from 'react';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(localStorage.getItem('user') ?? null);
  const [role, setRole] = useState(getUserRole(localStorage.getItem('user')) ?? null);

  const isUserExist = () => {
    const user_data = localStorage.getItem('user');

    if(user_data){
        //console.log(user_data)
        //setUser(JSON.parse(user_data))
        return JSON.parse(user_data);
    }
    else{
        return null;
    }
  }

  function getUserRole(data) {

    if(!data){
      return null;
    }
    var user = typeof data == "string" ? JSON.parse(data): data;

    try {
      //in case of teacher of student
      if(typeof user.profile == "string"){
        user.profile = JSON.parse(user.profile)
      }

      if (typeof user !== 'object' || typeof user.profile !== 'object') {
        console.log("cannot get user's role")
        return null;
      }
  
      if (typeof user.profile.role !== 'string') {
        return null;
      }
      //console.log(`user role: ${user.profile.role}`)
  
      return user.profile.role;
    } catch (error) {
      return null;
    }
  }

  const getUser = () => {
    const user_data = localStorage.getItem('user');

    if(user_data){
        setRole(getUserRole(user_data))
        return JSON.parse(user_data);
    }
    else{
        return null;
    }
  }

  const login = (userData) => {
    //alert("userData: "+userData)
    localStorage.setItem('user', JSON.stringify(userData));
    setUser(userData);
    setRole(getUserRole(userData))
  };

  const logout = () => {
    console.log("logout...")
    localStorage.setItem("user", null)
    window.location.href = "/"
    setUser(null);
  };

  return (
    <AuthContext.Provider value={{ user, role, getUser, isUserExist, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);