
  import { BiListOl } from "react-icons/bi";
  import Login from "views/MyIpstClass/Login";
import StudentLogin from "views/MyIpstClass/StudentLogin";
import TeacherLogin from "views/MyIpstClass/TeacherLogin";
  
  const loginRoutes = [
    {
      name: "เข้าสู่ระบบ",
      path: "/login",
      icon: <BiListOl color="inherit" />,
      authIcon: <BiListOl color="inherit" />,
      component: Login,
      layout: "/user",
      navLayout: "long_nav",
      isVisible: false
    },
    {
      name: "เข้าสู่ระบบ",
      path: "/teacher_login",
      icon: <BiListOl color="inherit" />,
      authIcon: <BiListOl color="inherit" />,
      component: TeacherLogin,
      layout: "/user",
      navLayout: "long_nav",
      isVisible: false
    },
    {
      name: "เข้าสู่ระบบ",
      path: "/student_login",
      icon: <BiListOl color="inherit" />,
      authIcon: <BiListOl color="inherit" />,
      component: StudentLogin,
      layout: "/user",
      navLayout: "long_nav",
      isVisible: false
    },
  ];
  
  export default loginRoutes;
  