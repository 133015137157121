import React, { useEffect, useState } from 'react';
import { Flex, Text, FormControl, Checkbox, VStack, useColorModeValue, Spinner } from '@chakra-ui/react';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import CardHeader from 'components/Card/CardHeader.js';
import { SlackLogo } from 'components/Icons/Icons';
import axios from 'axios';
import { API_URL } from 'variables/constant';

const ClassLevelSelectionCard = ({ setSelectedGradeLevels }) => {
  const textColor = useColorModeValue('gray.700', 'white');
  const [checkedItems, setCheckedItems] = useState([]);
  const [classes, setClasses] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleCheckboxChange = (id) => {
    const updatedCheckedItems = checkedItems.includes(id)
      ? checkedItems.filter(item => item !== id)
      : [...checkedItems, id];

    setCheckedItems(updatedCheckedItems);
    setSelectedGradeLevels(updatedCheckedItems);
  };

  const fetchClasses = async () => {
    try {
      setLoading(true);
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/subjects/list_all_classes`, {}, { withCredentials: true });
      const c = response.data.classes;
      setClasses(c);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error('Error fetching classes:', error);
    }
  };

  useEffect(() => {
    fetchClasses();
  }, []);

  return (
    <Card>
      <CardHeader>
        <Flex align="center">
          <SlackLogo w="34px" h="34px" me="14px" />
          <Flex direction="column">
            <Text fontSize="xl" color={textColor} fontWeight="bold">
              ระดับชั้นเรียน
            </Text>
          </Flex>
        </Flex>
      </CardHeader>
      <CardBody>
        <Flex direction="column" mt="16px">
          <FormControl>
            {loading && <Spinner />}
            {!loading && (
              <VStack align="start" mr={5}>
                {classes.map((option) => (
                  <Checkbox
                    key={option.name_eng}
                    mb={2}
                    value={option.id}
                    isChecked={checkedItems.includes(option.id)}
                    onChange={() => handleCheckboxChange(option.id)}
                  >
                    {option.name_thai}
                  </Checkbox>
                ))}
              </VStack>
            )}
          </FormControl>
        </Flex>
      </CardBody>
    </Card>
  );
};

export default ClassLevelSelectionCard;
