import React from 'react';
import { Box, Text } from '@chakra-ui/react';

export const TimeWidget = ({ value, onClick, ...rest }) => {
  return (
    <Box cursor={"pointer"} {...rest} onClick={onClick && (() => onClick())} textAlign="center" py={0} px={1} border="1px" borderColor="gray.200" borderRadius="md">
      <Text fontSize="sm" fontWeight="bold" color="black">
        {value?value:"HH:MM:SS"}
      </Text>
    </Box>
  );
};
