import React, { useEffect, useState } from 'react';
import { Flex, Text, Image, FormControl, Checkbox, CheckboxGroup, useColorModeValue } from '@chakra-ui/react';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import CardHeader from 'components/Card/CardHeader.js';
import ScienceSVG from 'assets/svg/science.svg';

const YearSelectionCard = ({ setSelectedYears }) => {
  const textColor = useColorModeValue('gray.700', 'white');
  const [checkedItems, setCheckedItems] = useState([]);
  const [years, setYears] = useState([]);

  useEffect(() => {
    const currentYear = new Date().getFullYear();
    const last15Years = Array.from({ length: 30 }, (_, i) => currentYear - i);
    setYears(last15Years);
  }, []);

  const handleChange = (checkedValues) => {
    console.log(checkedValues);
    setCheckedItems(checkedValues);
    setSelectedYears(checkedValues);
  };

  return (
    <Card>
      <CardHeader>
        <Flex align="center">
          <Image src={ScienceSVG} w="34px" h="34px" me="14px" alignSelf="center" justifySelf="center" />
          <Flex direction="column">
            <Text fontSize="xl" color={textColor} fontWeight="bold">
              ปีที่ต้องการแสดง
            </Text>
          </Flex>
        </Flex>
      </CardHeader>
      <CardBody>
        <Flex direction="column" mt="16px">
          <FormControl>
            <CheckboxGroup value={checkedItems} onChange={handleChange}>
              <Flex direction={'row'} wrap="wrap">
                {years.map((s, index) => (
                  <Checkbox key={index} mb={2} value={`${s}`} width="50%">
                    {s + 543}
                  </Checkbox>
                ))}
              </Flex>
            </CheckboxGroup>
          </FormControl>
        </Flex>
      </CardBody>
    </Card>
  );
};

export default YearSelectionCard;
