const {
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} = require('@chakra-ui/react');
import { Editor } from '@tinymce/tinymce-react';
import { useRef } from 'react';

const TinyMCEEditor = ({ isOpen, onClose, onUpdate, initialValue }) => {
  const editorRef = useRef(null);

  const openMath = () => {
    localStorage['is_equation_inserted'] = 'false';
    var textarea = 'txtEquation1';
    var win = window.open(
      'VisualMathEditor.html?runLocal&codeType=Latex&encloseAllFormula=false&textarea=' + textarea,
      'VisualMathEditor',
      'height=580,width=780,top=100,left=100,status=yes,toolbar=no,menubar=no,location=no,resizable=no,scrollbars=no,modal=no,dependable=yes'
    );

    const intervalId = setInterval(() => {
        if (win.closed) {
            //win.focus();
          clearInterval(intervalId);
          updateEquation()
          // Window is closed, perform actions
          console.log('Window closed');
        }
    }, 500);
    
  };

  function updateEquation(){
    if (localStorage['is_equation_inserted'] === 'true') {
        if (localStorage['error'] !== '') {
          alert(localStorage['error']);
          localStorage['error'] = '';
        } else if (localStorage['math_equation'] !== '') {
          console.log(localStorage['math_equation']);
          editorRef.current.execCommand(
            'mceInsertContent',
            false,
            '$' + localStorage['math_equation'] + '$'
          );
          localStorage['math_equation'] = '';
        }

        localStorage['is_equation_inserted'] = 'false';
      }
  }

  ////////////////////////////////////////////////////////////////////////////
  window.addEventListener(
    'focus',
    function (event) {
      updateEquation()
    },
    false
  );

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="3xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>จัดการเนื้อหา</ModalHeader>
        <ModalBody>
          <Flex direction={{ base: 'column', md: 'row' }} w="100%">
            <Flex justify={'center'} mb={1} direction={'column'}>
              <textarea
                style={{ visibility: 'hidden' }}
                id="txtEquation1"
                name="equation1"
                cols="50"
                rows="1"
                defaultValue="a^x = 1"
              />
              <Editor
                apiKey="5r3v7xeymcjhuylsm9dqnjsvkihvroc3t5rvbm6ned87iihu"
                onInit={(evt, editor) => (editorRef.current = editor)}
                initialValue={initialValue}
                init={{
                  width: '100%',
                  height: 500,
                  menubar: 'file edit view format table mathmenu imgmenu',
                  menu: {
                    edit: {
                      title: 'Edit',
                      items: 'undo redo | cut copy paste pastetext | selectall',
                    },
                    insert: { title: 'Insert', items: 'link media | template hr' },
                    view: { title: 'View', items: 'visualaid | code' },
                    format: {
                      title: 'Format',
                      items:
                        'bold italic underline strikethrough superscript subscript | formats | removeformat',
                    },
                    table: {
                      title: 'Table',
                      items: 'inserttable tableprops deletetable | cell row column',
                    },
                    mathmenu: { title: 'Equation', items: 'mathitem' },
                    imgmenu: { title: 'Image', items: 'imgitem' },
                  },
                  plugins: ['image', 'code', 'table', 'link', 'media', 'codesample'],
                  toolbar:
                    'undo redo | formatselect | ' +
                    'bold italic backcolor | alignleft aligncenter ' +
                    'alignright alignjustify | bullist numlist outdent indent | ' +
                    'removeformat | help' +
                    'table tabledelete | tableprops tablerowprops tablecellprops | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol',
                  content_style:
                    'body { font-family: Helvetica, Arial, sans-serif; font-size: 14px }',

                  setup: function (editor) {
                    function toTimeHtml(date) {
                      return (
                        '<time datetime="' +
                        date.toString() +
                        '">' +
                        date.toDateString() +
                        '</time>'
                      );
                    }

                    function insertMath() {
                      console.log('-000000-');
                      openMath();
                    }

                    function insertImg() {
                      alert("insert img")
                    }

                    editor.ui.registry.addMenuItem('mathitem', {
                      text: 'insert equation',
                      context: 'mathmenu',
                      onAction: () => insertMath(),
                    });

                    editor.ui.registry.addMenuItem('imgitem', {
                      text: 'insert image',
                      context: 'imgmenu',
                      onAction: () => insertImg(),
                    });

                    editor.on('init', function (args) {
                      
                      /*const load_qid = getParam("qid")
                      if (load_qid) {
                          loadQuestion(load_qid)
                      }*/
                    });
                  },
                }}
              />
            </Flex>
          </Flex>
        </ModalBody>
        <ModalFooter>
          <Button variant='outline' mr={3} onClick={onClose}>
            ยกเลิก
          </Button>
          <Button colorScheme="blue" mr={3} onClick={()=>{
            //onUpdate()
            onUpdate(editorRef.current.getContent())
          }}>
            บันทึก
          </Button>
          {/* Additional buttons or actions */}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default TinyMCEEditor;
