import axios from 'axios';
import Swal from 'sweetalert2';
import { API_URL } from 'variables/constant';

export const loadSubjects = (year_filter, subject_filter, classroom_id) => {
    return axios.post(`${process.env.REACT_APP_API_URL}/model_subject/list_all_subject`, {
        year_filter: year_filter,
        subject_filter: subject_filter,
        classroom_id: classroom_id
    }, { withCredentials: true })
    .then(function (response) {
        const res = response.data;
        if (res !== undefined && res.status === "success") {
            const subjects = res.subjects;
            return subjects;
        } else {
            return [];
        }
    })
    .catch(function (error) {
        Swal.fire('Error!', `${error}`, 'warning');
        return [];
    });
};

export const listBookBySubjectID = (subject_id) => {
    return axios.post(`${process.env.REACT_APP_API_URL}/subjects/list_all_books`, {
        subject_id
    }, { withCredentials: true })
    .then(function (response) {
        const res = response.data;
        if (res !== undefined && res.status === "success") {
            const books = res.books;
            return books;
        } else {
            return [];
        }
    })
    .catch(function (error) {
        Swal.fire('Error!', `${error}`, 'warning');
        return [];
    });
};

