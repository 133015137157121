import React, { useEffect, useState } from 'react';
import axios from 'axios';
import ReactPaginate from 'react-paginate';
import {
  Avatar,
  Box,
  Button,
  Flex,
  Grid,
  Icon,
  Input,
  Select,
  Spinner,
  Stack,
  Table,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  FormErrorMessage,
} from '@chakra-ui/react';
import { AiOutlinePlus, AiOutlineSearch } from 'react-icons/ai';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import CardHeader from 'components/Card/CardHeader.js';
import ExerciseSVG from 'assets/svg/exercise.svg';
import { HSeparator } from 'components/Separator/Separator.js';
import TableExamListRow from 'components/Tables/TableExamListRow';
import SubjectSelectionCard from './widgets/SubjectSelectionCard';
import GradeLevelSelectionCard from './widgets/GradeLevelSelectionCard';
import { API_URL } from 'variables/constant';
import useDebounce from 'util/useDebounce';
import ClassLevelSelectionCard from './widgets/ClassLevelSelectionCard';
import { getSubjects } from 'apis/subjectAPI';
import { getClasses } from 'apis/subjectAPI';
import { confirmDeleteDialog } from 'util/alertUtil';
import Breadcrumbs from "./widgets/Breadcrumbs";
import { useAuth } from 'auth/AuthProvider';

function ExamList() {
  const [examList, setExamList] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [pageCount, setPageCount] = useState(0);
  const [totalExams, setTotalExams] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const debouncedSearchTerm = useDebounce(searchTerm, 500);
  const textColor = useColorModeValue('gray.700', 'white');
  const borderColor = useColorModeValue('gray.200', 'gray.600');
  const [selectedGradeLevels, setSelectedGradeLevels] = useState([]);
  const [selectedSubjectIDs, setSelectedSubjectIDs] = useState([]);
  const [loading, setLoading] = useState(false);

  // State for modal and form inputs
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [examName, setExamName] = useState('');
  const [selectedSubject, setSelectedSubject] = useState('');
  const [selectedGrade, setSelectedGrade] = useState('');
  const [editMode, setEditMode] = useState(false);
  const [currentExamId, setCurrentExamId] = useState(null);
  const [subjects, setSubjects] = useState([]);
  const [classes, setClasses] = useState([]);

  // Validation states
  const [examNameError, setExamNameError] = useState(false);
  const [subjectError, setSubjectError] = useState(false);
  const [gradeError, setGradeError] = useState(false);

  const { user, role, isUserExist, login, logout } = useAuth();

  useEffect(() => {
    fetchExams(currentPage + 1, debouncedSearchTerm, itemsPerPage);
  }, [currentPage, debouncedSearchTerm, itemsPerPage, selectedGradeLevels, selectedSubjectIDs]);

  useEffect(() => {
    setCurrentPage(0)
  }, [debouncedSearchTerm])

  useEffect(() => {
    fetchClasses();
    loadSubjects();
  },[])

  async function loadSubjects(){
    try {
      setLoading(true);
      const subs = await getSubjects();
      setSubjects(subs);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }

  async function fetchClasses(){
    try {
      setLoading(true);
      const classes = await getClasses();
      setClasses(classes);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const fetchExams = async (page, searchTerm, limit) => {
    console.log(`selectedGradeLevels: ${selectedGradeLevels}`)
    try {
      setLoading(true);
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/exams/exam_list_all`, {
        selected_subject_ids: selectedSubjectIDs,
        selected_class_ids: selectedGradeLevels,
        page: page,
        limit: limit,
        search_term: searchTerm
      }, { withCredentials: true });
      setExamList(response.data.exams);
      setPageCount(response.data.totalPages);
      setTotalExams(response.data.totalRecords);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error('Error fetching exams:', error);
    }
  };

  const handlePageClick = (event) => {
    setCurrentPage(event.selected);
  };

  const handleAddExam = () => {
    setEditMode(false);
    setExamName('');
    setSelectedSubject('');
    setSelectedGrade('');
    onOpen();
  };

  const handleEditExam = (exam) => {
    setEditMode(true);
    setExamName(exam.exam_name);
    setSelectedSubject(exam.subject_id);
    setSelectedGrade(exam.class_id);
    setCurrentExamId(exam.id);
    onOpen();
  };

  const handleDeleteExam = async (exam_id, exam_name) => {
    confirmDeleteDialog("ยืนยันลบแบบฝึกหัด", `คุณต้องการลบแบบฝึกหัด "${exam_name}" ใช่หรือไม่`, async ()=>{
      try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/exams/delete_exam`, { exam_id }, { withCredentials: true });
        if (response.data.status === 'success') {
          fetchExams(currentPage + 1, debouncedSearchTerm, itemsPerPage);
        }
      } catch (error) {
        console.error('Error deleting exam:', error);
      }
    })
  };

  const handleSubmitExam = async () => {
    let hasError = false;
    if (!examName) {
      setExamNameError(true);
      hasError = true;
    } else {
      setExamNameError(false);
    }

    if (!selectedSubject) {
      setSubjectError(true);
      hasError = true;
    } else {
      setSubjectError(false);
    }

    if (!selectedGrade) {
      setGradeError(true);
      hasError = true;
    } else {
      setGradeError(false);
    }

    if (hasError) return;

    try {
      const endpoint = `${process.env.REACT_APP_API_URL}/exams/exam_new_insert`;
      const opt = editMode ? `update` : `insert`;
      var payload = { exam_name: examName, subject_id: selectedSubject, class_id: selectedGrade, exam_opt: opt};

      if (editMode) {
        payload = {
          exam_name: examName,
          subject_id: selectedSubject,
          class_id: selectedGrade,
          exam_id: currentExamId,
          exam_opt: opt
        }
      }

      const response = await axios.post(endpoint, payload, { withCredentials: true });
      if (response.data.status === 'success') {
        fetchExams(currentPage + 1, debouncedSearchTerm, itemsPerPage);
        onClose();
      }
    } catch (error) {
      console.error('Error saving exam:', error);
    }
  };

  return (
    <Flex direction="column" mt={{ sm: '45px', md: '40px' }} overflowX="hidden">
      <Grid
        templateColumns={{ sm: '1fr', lg: '3.1fr 1fr' }}
        templateRows="auto 1fr"
        mt="24px"
        gap="24px"
      >
        <Card alignSelf={{ lg: 'flex-start', '2xl': 'stretch' }}>
          <CardHeader w="100%">
            <Flex justify="space-between" align="center">
              <Flex>
                <Box>
                  <Avatar src={ExerciseSVG} p={1} w="40px" h="40px" borderRadius="12px" me="15px" />
                </Box>
                <Flex direction="column">
                  <Text fontSize="md" color={textColor} fontWeight="bold">
                    รายชื่อแบบฝึกหัด
                  </Text>
                  <Text fontSize="sm" color="gray.500" fontWeight="normal">
                    {totalExams} แบบฝึกหัด
                  </Text>
                </Flex>
              </Flex>
              <Flex>
                <Flex align="center">
                  <Icon as={AiOutlineSearch} color="gray.400" w="20px" h="20px" me="8px" />
                  <Input
                    mr={2}
                    placeholder={role == "TEACHER"? "ค้นหาแบบฝึกหัด...": "ค้นหาแบบฝึกหัด/email"}
                    border='1px solid'
                    borderColor={"gray.200"}
                    _hover={{}}
                    _focus={{}}
                    color="gray.600"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                </Flex>
                <Button variant="primary" p="8px 32px" onClick={handleAddExam}>
                  <Flex align="center" color="#fff" justifyContent="center">
                    <Icon as={AiOutlinePlus} w="18px" h="18px" fontWeight="bold" me="4px" />
                    <Text fontSize="14px">
                      เพิ่มแบบฝึกหัด
                    </Text>
                  </Flex>
                </Button>
              </Flex>
            </Flex>
          </CardHeader>
          <HSeparator my="16px" />
          <CardBody>
            {loading && <Flex w={"100%"} justify={"center"} p={5}><Spinner /></Flex>}
            {!loading && <Flex direction="column">
              <Card overflowX={{ sm: 'scroll', lg: 'scroll' }}>
                <CardBody>
                  <Table
                    overflowX={{ sm: 'scroll', lg: 'scroll' }}
                    variant="simple"
                    color={textColor}
                  >
                    <Thead>
                      <Tr my=".8rem" ps="0px" color="gray.400">
                        <Th ps="0px" color="gray.400" borderColor={borderColor}>
                          ที่
                        </Th>
                        <Th ps="0px" color="gray.400" borderColor={borderColor}>
                          ชื่อชุดแบบฝึกหัด
                        </Th>
                        {role != "TEACHER" && <Th color="gray.400" borderColor={borderColor}>
                          อีเมลผู้สร้าง
                        </Th>}
                        <Th color="gray.400" borderColor={borderColor}>
                          ระดับชั้น
                        </Th>
                        <Th color="gray.400" borderColor={borderColor}>
                          วิชา
                        </Th>
                        <Th color="gray.400" borderColor={borderColor}>
                          Actions
                        </Th>
                      </Tr>
                    </Thead>
                    <Tbody pb="0px">
                      {examList.map((row, index, arr) => (
                        <TableExamListRow
                          no={index + 1 + currentPage * itemsPerPage}
                          id={row.id}
                          name={row.exam_name}
                          email={row.administrator_email}
                          gradeLevel={row.class_thai}
                          subject={row.subject_thai}
                          isLast={index === arr.length - 1}
                          key={index}
                          onEdit={() => handleEditExam(row)}
                          onDelete={() => handleDeleteExam(row.id, row.exam_name)}
                        />
                      ))}
                    </Tbody>
                  </Table>
                </CardBody>
              </Card>
              <Flex mt={2} justify="space-between" align="center" mb="4">
                <Flex align={"center"}>
                  <Text mr={1}>แสดง:</Text>
                  <Select
                    value={itemsPerPage}
                    onChange={(e) => setItemsPerPage(parseInt(e.target.value))}
                    width="105px"
                  >
                    <option value={5}>5 แถว</option>
                    <option value={10}>10 แถว</option>
                    <option value={20}>20 แถว</option>
                    <option value={50}>50 แถว</option>
                  </Select>
                  <Text ml={1}>ต่อหน้า</Text>
                </Flex>
                <ReactPaginate
                  previousLabel={'previous'}
                  nextLabel={'next'}
                  breakLabel={'...'}
                  breakClassName={'break-me'}
                  pageCount={pageCount}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={handlePageClick}
                  containerClassName={'pagination'}
                  subContainerClassName={'pages pagination'}
                  activeClassName={'active'}
                  forcePage={currentPage} // Ensure ReactPaginate reflects the current page
                />
              </Flex>
            </Flex>}
          </CardBody>
        </Card>
        <Stack direction="column" spacing="24px">
          <ClassLevelSelectionCard setSelectedGradeLevels={(level)=>{
            setSelectedGradeLevels(level)
            setCurrentPage(0)
          }} />
          <SubjectSelectionCard setSelectedSubjectIDs={(subjectIDs)=>{
            setSelectedSubjectIDs(subjectIDs)
            setCurrentPage(0)
          }} />
        </Stack>
      </Grid>
      
      {/* Modal for Adding/Editing Exam */}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{editMode ? 'แก้ไขแบบฝึกหัด' : 'เพิ่มแบบฝึกหัด'}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl isRequired isInvalid={examNameError}>
              <FormLabel>ชื่อชุดแบบฝึกหัด</FormLabel>
              <Input value={examName} onChange={(e) => setExamName(e.target.value)} />
              {examNameError && <FormErrorMessage>กรุณากรอกชื่อชุดแบบฝึกหัด</FormErrorMessage>}
            </FormControl>
            <FormControl mt={4} isRequired isInvalid={subjectError}>
              <FormLabel>วิชา</FormLabel>
              <Select value={selectedSubject} onChange={(e) => setSelectedSubject(e.target.value)} placeholder='-- เลือกวิชา --'>
                {subjects.map((v,i)=>{
                  return <option key={i} value={v.id}>{v.subjectThai}</option>
                })}
              </Select>
              {subjectError && <FormErrorMessage>กรุณาเลือกวิชา</FormErrorMessage>}
            </FormControl>
            <FormControl mt={4} isRequired isInvalid={gradeError}>
              <FormLabel>ระดับชั้น</FormLabel>
              <Select value={selectedGrade} onChange={(e) => setSelectedGrade(e.target.value)} placeholder='-- เลือกระดับชั้น --'>
                {classes.map((c,i)=>{
                  return <option key={i} value={c.id}>{c.name_thai}</option>
                })}
              </Select>
              {gradeError && <FormErrorMessage>กรุณาเลือกระดับชั้น</FormErrorMessage>}
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handleSubmitExam}>
              {editMode ? 'แก้ไข' : 'เพิ่ม'}
            </Button>
            <Button variant="ghost" onClick={onClose}>ยกเลิก</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  );
}

export default ExamList;
