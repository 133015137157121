import React, { useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Flex,
  Button,
  Text,
} from '@chakra-ui/react';
import Spreadsheet from 'react-spreadsheet';

const GradeBookModal = ({ lessonName, isOpen, onClose, data }) => {
  const [rows, setRows] = useState(data);

  // Function to handle changes in the spreadsheet
  const onChange = (changes) => {
    const newRows = rows.map((row) => [...row]);
    changes.forEach(({ row, col, value }) => {
      newRows[row][col] = value;
    });
    setRows(newRows);
  };

  return (
    <Modal size='full' isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader bgColor={"blue.600"}>
          <Text color="white">Grade book สำหรับบท{lessonName}</Text>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Flex w="100%" overflow={"scroll"} justify={"center"}>
            <Spreadsheet data={rows} onChange={onChange}></Spreadsheet>
          </Flex>
        </ModalBody>
        <ModalFooter>
          <Flex w="100%" justify={"center"}>
            <Button variant='outline' colorScheme='green' mr={2}>
              Export CSV
            </Button>
            <Button variant='outline' colorScheme='blue' onClick={onClose}>
              ออก
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default GradeBookModal;
