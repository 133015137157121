import Card from 'components/Card/Card';
import QuestionBuilder from './QuestionBuilder';
import { Button, Flex } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { getParam } from 'util/common';
import { loadQuestion } from "util/questionUtil";
import Breadcrumbs from './widgets/Breadcrumbs';
import axios from 'axios';
import Swal from 'sweetalert2';
import moment from 'moment';

const ExamBuilder = () => {
  const history = useHistory();
  const [questions, setQuestions] = useState([]);
  const [saving, setSaving] = useState(false);
  const [qid, setQid] = useState(getParam("qid"))
  const [qtype, setQtype] = useState(getParam("qtype"))
  const [initialQuestions, setInitialQuestions] = useState([])

  useEffect(()=>{
    const initializeQuestion = async () => {
      const q = await loadQuestion(qid, qtype)
      const initQ = transformData(q)
      setInitialQuestions([initQ])
    }
    if(qid && qtype){
      initializeQuestion()
    }
  },[qid, qtype])

  useEffect(()=>{
    console.log(`q => ${JSON.stringify(initialQuestions)}`)
  }, [initialQuestions])

  const transformData = (data) => {
      const question = {
        question: data.content,
        question_type: data.question_type,
        appear_type: "between_vdo", // Default value, change if necessary
        question_items: data.question_type === "fill"
          ? data.answers.map(answer => ({
              text: answer.anwer_text,
              is_correct: answer.is_answer === 1
            }))
          : data.choices.map(choice => ({
              text: choice.anwer_text,
              is_correct: choice.is_answer === 1
            })),
        display_time: "", 
        back_time: "",
        score: 1,
        description: data.otim_explanation
      };
      return question;
  };

  const getBreadcum = () => {
    const eid = getParam("eid");
    const qid = getParam("qid");
    const ename = getParam("ename");

    return [
      { url: `/#/user/question-list?eid=${eid}&ename=${ename}`, text: 'รายการคำถาม' },
      { url: undefined, text: qid ? 'แก้ไขคำถาม':'สร้างคำถาม' }
    ];
  }

  const saveQuestions = async () => {
    const exam_id = getParam("eid");
    const exam_name = getParam("ename");
    setSaving(true);

    console.log(`question size: ${questions.length}`)

    for (const question of questions) {
      try {
        const data = {
            operation: qid?'update':'add',
            question_id: qid ?? "",
            question_type: question.question_type,
            exam_id,
            content: question.question,
            otim_item_id: '',
            test_set_id: '',
            imgArray: JSON.stringify(question.question_items.map(item => '')),
            ansArray: JSON.stringify(question.question_items.map(item => item.text)),
            correctArray: JSON.stringify(question.question_items.map(item => item.is_correct ? 1 : 0)),
            sequenceArray: JSON.stringify(question.question_items.map((_, index) => index + 1)),
            matching_items: JSON.stringify({}),
            explanation: question.description || '',
            modified_date: `${moment().format('YYYY-MM-DD HH:mm:ss')}`,
            source: 'custom'
        }
        console.log(`data: ${JSON.stringify(data)}`)
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/questions/question_insert`, data);
        if (response.data && response.data.status !== "success") {
          throw new Error("Failed to save question");
        }
      } catch (error) {
        console.error("Error saving question:", error);
        Swal.fire("Warning", "ไม่สามารถบันทึกข้อมูลได้", "warning");
        setSaving(false);
        return;
      }
    }

    Swal.fire({
      text: "ระบบได้ทำการบันทึกเรียบร้อย",
      icon: "success",
      buttonsStyling: false,
      confirmButtonText: "ตกลง",
      customClass: {
        confirmButton: "btn btn-primary"
      }
    }).then(result => {
      if (result.isConfirmed) {
        history.push(`question-list?eid=${exam_id}&ename=${exam_name}`);
      }
    });

    setSaving(false);
  };

  return (
    <Flex direction="column" pt={{ sm: '125px', lg: '75px' }}>
      <Breadcrumbs links={getBreadcum()} />

      <Card>
        <QuestionBuilder initailQuestions={initialQuestions} updateQuestions={setQuestions} showTime={false} showScore={false} isEditingSingleQuestion={qid?true:false} />
      </Card>
      <Flex borderRadius={10} bgColor="gray.100" mt={5} align={'center'} justify="center" p={5}>
        <Button mr={2} variant="outline" colorScheme="white" w="100px" h="35px" onClick={() => {
          window.history.back();
        }}>
          ยกเลิก
        </Button>
        <Button colorScheme="blue" variant="solid" w="100px" h="35px" onClick={saveQuestions} isLoading={saving}>
          บันทึก
        </Button>
      </Flex>
    </Flex>
  )
}

export default ExamBuilder;