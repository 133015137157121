import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Center,
  Flex,
  IconButton,
  Input,
  Menu,
  MenuButton,
  Select,
  Stack,
  Text,
  VStack,
  MenuList,
  MenuItem,
  FormLabel,
  FormControl
} from '@chakra-ui/react';
import {
  AddIcon,
  ArrowDownIcon,
  ArrowUpIcon,
  DeleteIcon,
  EditIcon,
  ChevronDownIcon,
} from '@chakra-ui/icons';
import TimeSelectorModal from './modals/TimeSelectorModal';
import TinyMCEEditor from './TinyMCEEditor';
import QuillEditing  from './QuillEditing'
import YouTube from 'react-youtube';
import { BoxWithNumber, CircleWithNumber, LineBox } from './widgets/YoutubeWidget';
import { MathJax, MathJaxContext } from "better-react-mathjax";
import { MdNoteAdd, MdPlaylistAdd } from 'react-icons/md';
import Swal from 'sweetalert2'
import Editor from 'components/Editor/Editor';

import { LabelValueWidget } from './widgets/LabelValueWidget';
import ScoreSelectorModal from './modals/ScoreSelectionModal';

const QuestionBuilder = ({ youtubeID, updateQuestions, initailQuestions, showTime, endQuestionTime, showScore=true, isEditingSingleQuestion=false }) => {
  const [questions, setQuestions] = useState(initailQuestions || []);
  const [isTimeModalOpen, setTimeModalOpen] = useState(false);
  const [isEndTimeModalOpen, setEndTimeModalOpen] = useState(false);
  const [isBackTimeModalOpen, setBackTimeModalOpen] = useState(false);
  const [isOpenQuestionEditor, setOpenQuestionEditor] = useState(false);
  const [isScoreModalOpen, setScoreModalOpen] = useState(false);

  const [selectedQuestionIndex, setSelectedQuestionIndex] = useState();
  const [selectedChoiceIndex, setSelectedChoiceIndex] = useState();
  const [selectedQuestionDescIndex, setSelectedQuestionDescIndex] = useState();
  const [initialValue, setInitialValue] = useState();
  const [editorKey, setEditorKey] = useState(0);

  const config = {
    loader: { load: ["[tex]/html"] },
    tex: {
      packages: { "[+]": ["html"] },
      inlineMath: [
        ["$", "$"],
        ["\\(", "\\)"]
      ],
      displayMath: [
        ["$$", "$$"],
        ["\\[", "\\]"]
      ]
    }
  };

  useEffect(() => {
    //console.log(`questions list: ${JSON.stringify(questions)}`)
    updateQuestions(questions)
  }, [questions])

  useEffect(() => {
    if(initailQuestions && initailQuestions.length > 0){
      setQuestions(initailQuestions)
      //console.log(`q: ${JSON.stringify(initailQuestions)}`)
    }
  }, [initailQuestions])

  const handleQuestionEditorClose = () => {
    setOpenQuestionEditor(false);
  };

  // Add a new question
  const addQuestionMultipleChoice = () => {
    const appear_type = showTime?"between_vdo":"after_vdo"
    setQuestions([
      ...questions,
      { question: '', question_type: 'choice', appear_type, question_items: [{ text: '', is_correct: false }] },
    ]);
  };

  const addQuestionFilling = () => {
    const appear_type = showTime?"between_vdo":"after_vdo"
    setQuestions([
      ...questions,
      { question: '', question_type: 'fill', appear_type, question_items: [{ text: '', is_correct: false }] },
    ]);
  };

  // Add a new choice to a specific question
  const addChoice = (questionIndex) => {
    const newQuestions = [...questions];
    newQuestions[questionIndex].question_items.push({ text: '', is_correct: false });
    setQuestions(newQuestions);
  };

  // Remove a question
  const removeQuestion = (questionIndex) => {
    const newQuestions = [...questions];
    newQuestions.splice(questionIndex, 1);
    setQuestions(newQuestions);
  };

  // Remove a choice from a specific question
  const removeChoice = (questionIndex, choiceIndex) => {
    const newQuestions = [...questions];
    newQuestions[questionIndex].question_items.splice(choiceIndex, 1);
    setQuestions(newQuestions);
  };

  // Handle input change for question text
  const handleQuestionChange = (e, questionIndex) => {
    const { value } = e.target;
    const newQuestions = [...questions];
    newQuestions[questionIndex].question = value;
    setQuestions(newQuestions);
  };

  // Handle input change for choice text
  const handleChoiceChange = (e, questionIndex, choiceIndex) => {
    const { value } = e.target;
    const newQuestions = [...questions];
    newQuestions[questionIndex].question_items[choiceIndex].text = value;
    setQuestions(newQuestions);
  };

  // Handle select change for choice correctness
  const handleCorrectnessChange = (value, questionIndex, choiceIndex) => {
    const newQuestions = [...questions];
    newQuestions[questionIndex].question_items[choiceIndex].is_correct = value === 'true';
    setQuestions(newQuestions);
  };

  const moveChoiceUp = (questionIndex, choiceIndex) => {
    if (choiceIndex > 0) {
      const newQuestions = [...questions];
      const temp = newQuestions[questionIndex].question_items[choiceIndex];
      newQuestions[questionIndex].question_items[choiceIndex] =
        newQuestions[questionIndex].question_items[choiceIndex - 1];
      newQuestions[questionIndex].question_items[choiceIndex - 1] = temp;
      setQuestions(newQuestions);
    }
  };

  const moveChoiceDown = (questionIndex, choiceIndex) => {
    const choicesLength = questions[questionIndex].question_items.length;
    if (choiceIndex < choicesLength - 1) {
      const newQuestions = [...questions];
      const temp = newQuestions[questionIndex].question_items[choiceIndex];
      newQuestions[questionIndex].question_items[choiceIndex] =
        newQuestions[questionIndex].question_items[choiceIndex + 1];
      newQuestions[questionIndex].question_items[choiceIndex + 1] = temp;
      setQuestions(newQuestions);
    }
  };

  const moveQuestionUp = (questionIndex) => {
    if (questionIndex > 0) {
      const newQuestions = [...questions];
      const temp = newQuestions[questionIndex];
      newQuestions[questionIndex] = newQuestions[questionIndex - 1];
      newQuestions[questionIndex - 1] = temp;
      setQuestions(newQuestions);
    }
  };

  const moveQuestionDown = (questionIndex) => {
    if (questionIndex < questions.length - 1) {
      const newQuestions = [...questions];
      const temp = newQuestions[questionIndex];
      newQuestions[questionIndex] = newQuestions[questionIndex + 1];
      newQuestions[questionIndex + 1] = temp;
      setQuestions(newQuestions);
    }
  };

  const handleSaveTime = (timeStr, secondInt) => {
    if (selectedQuestionIndex !== null) {
      const newQuestions = [...questions];
      newQuestions[selectedQuestionIndex].display_time = timeStr;
      //newQuestions[selectedQuestionIndex].seconds = secondInt;
      setQuestions(newQuestions);
      setSelectedQuestionIndex(null);
    }
  };

  const handleSaveBackTime = (timeStr, secondInt) => {
    if (selectedQuestionIndex !== null) {
      const newQuestions = [...questions];
      newQuestions[selectedQuestionIndex].back_time = timeStr;
      //newQuestions[selectedQuestionIndex].back_seconds = secondInt;
      setQuestions(newQuestions);
      setSelectedQuestionIndex(null);
    }
  };

  const handleSaveScore = (score) => {
    if (selectedQuestionIndex !== null) {
      const newQuestions = [...questions];
      newQuestions[selectedQuestionIndex].score = score;
      setQuestions(newQuestions);
      setSelectedQuestionIndex(null);
    }
  };

  const opts = {
    playerVars: {rel: 0, iv_load_policy: 3}
  };

  const updateQuestionText = (questionIndex, newText) => {
    setQuestions(prevQuestions => {
      const updatedQuestions = [...prevQuestions];
      updatedQuestions[questionIndex].question = newText;
      return updatedQuestions;
    });
  };

  const updateQuestionScore = (questionIndex, newScore) => {
    setQuestions(prevQuestions => {
      const updatedQuestions = [...prevQuestions];
      updatedQuestions[questionIndex].score = newScore;
      return updatedQuestions;
    });
  };

  const updateChoiceText = (questionIndex, choiceIndex, newText) => {
    setQuestions(prevQuestions => {
      const updatedQuestions = [...prevQuestions];
      updatedQuestions[questionIndex].question_items[choiceIndex].text = newText;
      return updatedQuestions;
    });
  };

  const updateQuestionDescription = (questionIndex, newText) => {
    setQuestions(prevQuestions => {
      const updatedQuestions = [...prevQuestions];
      updatedQuestions[questionIndex].description = newText;
      return updatedQuestions;
    });
  };

  const updateContent = (content) => {
    if(selectedQuestionDescIndex != null && selectedQuestionIndex == null && selectedChoiceIndex == null){
      updateQuestionDescription(selectedQuestionDescIndex, content)
    }
    else if(selectedQuestionIndex != null && selectedChoiceIndex != null){
      updateChoiceText(selectedQuestionIndex, selectedChoiceIndex, content)
    }
    else if(selectedQuestionIndex != null && selectedChoiceIndex == null){
      updateQuestionText(selectedQuestionIndex, content)
    }
    setSelectedQuestionIndex(null)
    setSelectedChoiceIndex(null)
    setSelectedQuestionDescIndex(null)
    setOpenQuestionEditor(false);
  }

  return (
    <Flex w="100%" direction="column" justify={'center'}>
      {/*<TinyMCEEditor initialValue={initialValue} isOpen={isOpenQuestionEditor} onClose={handleQuestionEditorClose} onUpdate={(newText) => {
        updateContent(newText)
      }} />*/}

      <QuillEditing key={editorKey} initialValue={initialValue} isOpen={isOpenQuestionEditor} onClose={handleQuestionEditorClose} onUpdate={(newText) => {
        updateContent(newText)
        setEditorKey(prevKey => prevKey + 1);
      }} />

      {youtubeID && <Flex bgColor="gray.100" w="100%" align="center" justify={'center'}>
        <Box align="center" w="40%" my={5}>
          <YouTube opts={opts} videoId={youtubeID} className={'youtubeContainer'} />
        </Box>
      </Flex>}

      <MathJaxContext version={3} config={config}>
        <Flex mt={5} justify={'center'} w="100%">
          <Stack spacing={4} w="80%">
            {questions && questions.length > 0 && questions.map((question, questionIndex) => (
              <VStack key={questionIndex} spacing={0} w="100%">
                <Flex w="100%" align={'center'}>
                  <CircleWithNumber number={questionIndex + 1} />
                  <Flex
                    position="relative"
                    p={5}
                    borderRadius="5px"
                    borderWidth="1px"
                    ml={1}
                    w="80%"
                    h="100%"
                    cursor="pointer"
                    onClick={() => {
                      console.log(" ------- question setting -------")
                      setInitialValue(question.question)
                      setSelectedQuestionIndex(questionIndex)
                      setSelectedChoiceIndex(null)
                      setOpenQuestionEditor(true);
                      setEditorKey(prevKey => prevKey + 1);
                    }}
                  >
                      <Box w="100%">
                        <MathJax>
                          <Box dangerouslySetInnerHTML={{ __html: question.question ? question.question: `<span style="color: #CCC">คำถามข้อที่ ${questionIndex + 1}</span>` }} w="100%" />
                        </MathJax>   
                      </Box>   
                      <Box px={2} bottom="0" right="0" position="absolute">
                        <Text fontSize="12" color="blue.300">{question.question_type=="choice"?"แบบ choice":"แบบเติมคำ"}</Text>
                      </Box>                  
                  </Flex>
                  <Stack>
                    {showScore && <Flex>
                      <LabelValueWidget w={"60px"} mr={1} ml={2} label={"คะแนน"} value={question.score} onClick={()=>{
                        setSelectedQuestionIndex(questionIndex);
                        setScoreModalOpen(true);
                      }} />

                      {showTime && <LabelValueWidget w={"80px"} mr={1} label={"เวลาแสดง"} value={question.display_time} onClick={()=>{
                        setSelectedQuestionIndex(questionIndex);
                        setTimeModalOpen(true);
                      }} />}

                      {showTime && <LabelValueWidget w={"90px"} label={"เวลาย้อนกลับ"} value={question.back_time} onClick={()=>{
                        setSelectedQuestionIndex(questionIndex);
                        setBackTimeModalOpen(true);
                      }} />}
                    </Flex>}

                    <Flex>
                      <Button
                        ml={2}
                        size="sm"
                        variant="outline"
                        leftIcon={<AddIcon />}
                        onClick={() => addChoice(questionIndex)}
                      ><Text fontWeight={400}>เพิ่มคำตอบ</Text></Button>

                      <Menu>
                        <MenuButton
                          ml={2}
                          as={IconButton}
                          aria-label="Options"
                          icon={<ChevronDownIcon />}
                          variant="outline"
                          size="sm"
                        />
                        <MenuList>
                          <MenuItem
                            icon={<DeleteIcon />}
                            onClick={() => removeQuestion(questionIndex)}
                          >
                            ลบ
                          </MenuItem>
                          <MenuItem
                            icon={<ArrowUpIcon />}
                            onClick={() => moveQuestionUp(questionIndex)}
                          >
                            เลื่อนขึ้น
                          </MenuItem>
                          <MenuItem
                            icon={<ArrowDownIcon />}
                            onClick={() => moveQuestionDown(questionIndex)}
                          >
                            เลื่อนลง
                          </MenuItem>
                        </MenuList>
                      </Menu>
                    </Flex>
                  </Stack>
                </Flex>
                {question.question_items.map((choice, choiceIndex) => (
                  <Flex ml={15} w="90%" key={choiceIndex} cursor="pointer" >
                    {/*<BoxWithNumber number={choiceIndex + 1} />*/}
                    <LineBox 
                      key={`l${choiceIndex}`}
                      isLastItem={choiceIndex==question.question_items.length-1?true:false} 
                      isCorrect={choice.is_correct} 
                      borderColor="gray" ml={"50px"}/>

                    <Flex key={`f${choiceIndex}`} w="100%" pt={choiceIndex==0?2:1} pb={1}>
                      {question.question_type != "choice" && <Input
                        variant="main"
                        placeholder={`คำตอบที่ ${choiceIndex + 1}`}
                        ml={1}
                        value={choice.text}
                        onChange={(e) => {
                          if(e.target && e.target.value.trim() != ""){
                            handleCorrectnessChange("true", questionIndex, choiceIndex)
                          }
                          else{
                            handleCorrectnessChange("false", questionIndex, choiceIndex)
                          }
                          handleChoiceChange(e, questionIndex, choiceIndex)
                        }}
                      />}
                      {question.question_type == "choice" && <Flex key={`flx${choiceIndex}`}
                        align="center" 
                        borderRadius="5px"
                        w="100%"
                        borderWidth="1px"
                        ml={1}
                        pl={5}
                        onClick={() => {
                          console.log(" ------- choice setting -------")
                          setInitialValue(choice.text)
                          setSelectedQuestionIndex(questionIndex)
                          setSelectedChoiceIndex(choiceIndex)
                          setOpenQuestionEditor(true);
                          setEditorKey(prevKey => prevKey + 1);
                        }} 
                      >
                        {choice.text && <Box key={`box${choiceIndex}`} w="80%" borderRadius="40px">
                          <MathJax>
                            <Box key={`bbox${choiceIndex}`} dangerouslySetInnerHTML={{ __html: choice.text ? choice.text: `` }} /> 
                          </MathJax>
                        </Box>}

                        {!choice.text && <Box w="80%">
                            <Text color="gray.400">ตัวเลือกที่ {choiceIndex+1}</Text>
                        </Box>}

                      </Flex>}

                      {question.question_type == "choice" && <Select
                        variant="main"
                        ml={1}
                        color="gray.400"
                        width={'100px'}
                        value={choice.is_correct.toString()}
                        onChange={(e) =>
                          handleCorrectnessChange(e.target.value, questionIndex, choiceIndex)
                        }
                      >
                        <option value="true">ถูก</option>
                        <option value="false">ผิด</option>
                      </Select>}

                      <Menu>
                        <MenuButton
                          ml={1}
                          as={IconButton}
                          aria-label="Options"
                          icon={<ChevronDownIcon />}
                          variant="outline"
                          size="md"
                        />
                        <MenuList>
                          <MenuItem
                            icon={<DeleteIcon />}
                            onClick={() => removeChoice(questionIndex, choiceIndex)}
                          >
                            ลบ
                          </MenuItem>
                          <MenuItem
                            icon={<ArrowUpIcon />}
                            onClick={() => moveChoiceUp(questionIndex, choiceIndex)}
                          >
                            เลื่อนขึ้น
                          </MenuItem>
                          <MenuItem
                            icon={<ArrowDownIcon />}
                            onClick={() => moveChoiceDown(questionIndex, choiceIndex)}
                          >
                            เลื่อนลง
                          </MenuItem>
                        </MenuList>
                      </Menu>
                    </Flex>
                  </Flex>
                ))}

                <Box w="90%">
                  <FormControl>
                    <FormLabel color="gray.500" fontSize="sm" mb="2px">
                      คำอธิบายเฉลยข้อ {questionIndex+1}
                    </FormLabel>
                    <Box w="100%">
                      <MathJax>
                        <Box 
                          key={`b${questionIndex}`}
                          cursor={"pointer"}
                          p={question.description=="" || question.description==null? 5:2} 
                          borderWidth={1} 
                          borderColor="blue.100" 
                          onClick={() => {
                            console.log("------------------->>>>>>>"+(question.description ?? ""))
                            setInitialValue(question.description ?? "")
                            setSelectedQuestionDescIndex(questionIndex)
                            setSelectedChoiceIndex(null)
                            setOpenQuestionEditor(true);
                            setEditorKey(prevKey => prevKey + 1);
                        }} dangerouslySetInnerHTML={{ __html: question.description ? question.description: `` }} /> 
                      </MathJax>
                    </Box>
                    {/*<Editor defaultValue={question.description} handleChange={(v)=>{updateQuestionDescription(v)}} height={20}  />*/}
                  </FormControl>
                </Box>
                
                <TimeSelectorModal
                  initTime={question.display_time}
                  isOpen={isTimeModalOpen}
                  onClose={() => setTimeModalOpen(false)}
                  onSave={handleSaveTime}
                />

                <TimeSelectorModal
                  initTime={question.back_time}
                  isOpen={isBackTimeModalOpen}
                  onClose={() => setBackTimeModalOpen(false)}
                  onSave={handleSaveBackTime}
                />

                <ScoreSelectorModal
                  initScore={question.score}
                  isOpen={isScoreModalOpen}
                  onClose={() => setScoreModalOpen(false)}
                  onSave={handleSaveScore}
                />
                
              </VStack>
            ))}
            {!isEditingSingleQuestion && <Flex p={8} w="100%" justify={"center"}>
              <Button
                variant="outline"
                colorScheme="blue"
                leftIcon={<AddIcon />}
                onClick={addQuestionMultipleChoice}
              >
                เพิ่มคำถามแบบ multiple choice
              </Button>
              <Button
                ml={2}
                variant="outline"
                colorScheme="blue"
                leftIcon={<AddIcon />}
                onClick={addQuestionFilling}
              >
                เพิ่มคำถามแบบเติมคำ
              </Button>
            </Flex>}
          </Stack>
          
          
        </Flex>
      </MathJaxContext>
    </Flex>
  );
};

export default QuestionBuilder;
