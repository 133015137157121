import React, { useState, useEffect } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Flex,
  Button,
  Text,
  Box,
  Spinner,
} from '@chakra-ui/react';
import Spreadsheet from 'react-spreadsheet';

const GradebookActivityModal = ({ activityGroupName, isOpen, onClose, data }) => {
  // Function to transform the initial data into the format expected by react-spreadsheet
  const transformData = (data) => data.map(row => row.map(cell => ({ value: cell })));

  // Remove index 1 from the data array
  const removeIndex = (data, index) => data.filter((_, i) => i !== index);

  const [rows, setRows] = useState(transformData(removeIndex(data, 1)));
  const [loading, setLoading] = useState(false);

  // Use useEffect to update rows state when data prop changes
  useEffect(() => {
    setRows(transformData(removeIndex(data, 1)));
  }, [data]);

  // Function to handle changes in the spreadsheet
  const onChange = (changes) => {
    const newRows = rows.map((row) => [...row]);
    changes.forEach(({ row, col, value }) => {
      newRows[row][col] = { value };
    });
    setRows(newRows);
  };

  // Function to export CSV
  const exportCSV = async () => {
    setLoading(true);

    const csvContent = rows.map(row =>
      row.map(cell => `"${cell.value}"`).join(',')
    ).join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    link.href = url;
    link.setAttribute('download', `gradebook_${activityGroupName}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    setLoading(false);
  };

  return (
    <Modal size='full' isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader bgColor={"blue.600"}>
          <Text color="white">Grade book สำหรับ {activityGroupName}</Text>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box overflow="auto" justify="center" p={4}>
            <div style={{ minWidth: 'max-content', width: '100%' }}>
              <Spreadsheet data={rows} onChange={onChange} />
            </div>
          </Box>
        </ModalBody>
        <ModalFooter>
          <Flex w="100%" justify="center">
            <Button
              variant='outline'
              colorScheme='green'
              mr={2}
              onClick={exportCSV}
              isDisabled={loading}
            >
              {loading ? <Spinner size="sm" /> : 'Export CSV'}
            </Button>
            <Button variant='outline' colorScheme='blue' onClick={onClose}>
              ออก
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default GradebookActivityModal;
