import React from 'react';
import { useHistory } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink, Text } from '@chakra-ui/react';

const Breadcrumbs = ({ links }) => {
  const history = useHistory();

  const handleLinkClick = (event, index) => {
    event.preventDefault();
    const pops = links.length - 1 - index;
    history.go(-pops);
  };

  return (
    <Breadcrumb spacing="8px" mb={1} separator=">">
      {links.map((link, index) => (
        <BreadcrumbItem key={index} isCurrentPage={links.length - 1 === index}>
          {links.length - 1 === index ? (
            <Text color={"gray.500"}>{link.text}</Text>
          ) : (
            <BreadcrumbLink 
              color={"blue.700"} 
              href={"#"} 
              onClick={(event) => handleLinkClick(event, index)}
            >
              {link.text}
            </BreadcrumbLink>
          )}
        </BreadcrumbItem>
      ))}
    </Breadcrumb>
  );
};

export default Breadcrumbs;


/*import React from 'react';
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink, Text } from '@chakra-ui/react';

const Breadcrumbs = ({ links }) => {
  return (
    <Breadcrumb spacing="8px" mb={1} separator=">">
      {links.map((link, index) => (
        <BreadcrumbItem key={index} isCurrentPage={links.length-1 == index?true:false}>
          {links.length-1 == index && <Text color={"gray.500"}>{link.text}</Text>}
          {links.length-1 != index && <BreadcrumbLink color={"blue.700"} href={link.url}>{link.text}</BreadcrumbLink>}
        </BreadcrumbItem>
      ))}
    </Breadcrumb>
  );
};

export default Breadcrumbs;
*/