import React, { useRef, useState, useEffect } from 'react';
import { Flex, Box, Stack, Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalFooter, Button, Text, Input, useToast, useDisclosure } from '@chakra-ui/react';
import YouTube from 'react-youtube';
import { useHistory } from 'react-router-dom';
import { MathJax, MathJaxContext } from "better-react-mathjax";
import Swal from 'sweetalert2';
import { loadYoutubeByYID } from 'apis/youtubeAPI';
import { showInfoDialog } from 'util/alertUtil';
import BetweenQuestionScoreModal from '../modals/BetweenQuestionScoreModal';

const QuestionYoutube = ({ yid, saveScore=undefined, isSubmitted=false, studentScores=undefined, endQuestionTime=undefined }) => {
  const [youtubeID, setYoutubeID] = useState();
  const [betweenQuestions, setBetweenQuestions] = useState({});
  const [endQuestions, setEndQuestions] = useState([]);

  const [selectedChoiceIds, setSelectedChoiceIds] = useState({});
  const [filledTexts, setFilledTexts] = useState({});
  const [totalScore, setTotalScore] = useState(0);
  const [maxScore, setMaxScore] = useState(0);  // <-- Added maxScore to state
  const [isSubmitFinalAnswer, setIsSubmitFinalAnswer] = useState(false);
  const [endQuestionTimeSeconds, setEndQuestionTimeSeconds] = useState();

  const [showExplanation, setShowExplanation] = useState(false);

  const playerRef = useRef(null);
  const [progressInterval, setProgressInterval] = useState(null);
  const [showQuestionModal, setShowQuestionModal] = useState(false);
  const [showEndQuestionsModal, setShowEndQuestionsModal] = useState(false);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(null);
  const [duration, setDuration] = useState();
  const history = useHistory();
  const [betweenQuestionSummary, setBetweenQuestionSummary] = useState(<></>)
  const [endQuestionSummary, setEndQuestionSummary] = useState([])
  const { isOpen: isModalOpen, onOpen: onModalOpen, onClose: onModalClose } = useDisclosure();
  const [curBetweenQuestionNum, setCurBetweenQuestionNum] = useState(0)

  const opts = {
    height: '600',
    width: '1000',
    playerVars: { rel: 0, iv_load_policy: 3, fs: 0, disablekb: 0 }
  };

  const config = {
    loader: { load: ["[tex]/html"] },
    tex: {
      packages: { "[+]": ["html"] },
      inlineMath: [
        ["$", "$"],
        ["\\(", "\\)"]
      ],
      displayMath: [
        ["$$", "$$"],
        ["\\[", "\\]"]
      ]
    }
  };

  const timeToSeconds = (timeString) => {
    const [hours, minutes, seconds] = timeString.split(':').map(Number);
    return hours * 3600 + minutes * 60 + seconds;
  };

  useEffect(() => {
    if(endQuestionTime && duration){
      const eTime = timeToSeconds(endQuestionTime);
      setEndQuestionTimeSeconds(duration - eTime);
    }
  }, [endQuestionTime, duration])

  useEffect(() => {
    if(studentScores){
      showInfoDialog("แจ้งเตือน", `คุณได้ชมวีดิทัศและทำแบบฝึกหัดท้ายวีดิทัศน์นี้แล้ว คะแนนของคุณคือ ${studentScores} คะแนน`)
    }
    else if(isSubmitted){
      showInfoDialog("แจ้งเตือน", "คุณได้ชมวีดิทัศและทำแบบฝึกหัดท้ายวีดิทัศน์นี้แล้ว")
    }
  },[studentScores, isSubmitted])

  useEffect(() => {
    async function loadYoutubeInfo() {
      const youtube = await loadYoutubeByYID(yid);

      if (youtube.status === "success") {
        const y = youtube.data.result.video;
        const betweenQs = youtube.data.result.between_questions;
        const endQs = youtube.data.result.end_questions;

        if(!y || !y.youtube_id){
          Swal.fire("แจ้งเตือน", `ไม่สามารถโหลด Youtube รหัส ${yid} ได้`, "warning")
        }

        const groupedQuestions = betweenQs.reduce((acc, question) => {
          const time = timeToSeconds(question.display_time);
          if (!acc[time]) {
            acc[time] = [];
          }
          acc[time].push(question);
          return acc;
        }, {});

        setYoutubeID(y.youtube_id);
        setDuration(y.duration)
        setBetweenQuestions(groupedQuestions);
        setEndQuestions(endQs);

        if (y.end_question_display_time) {
          const eTime = timeToSeconds(y.end_question_display_time);
          setEndQuestionTimeSeconds(y.duration - eTime);
        } else if (y.duration) {
          setEndQuestionTimeSeconds(5);
        }
      }
      else{
        Swal.fire("แจ้งเตือน", `ไม่สามารถโหลด Youtube รหัส ${yid} ได้`, "warning")
      }
    }

    loadYoutubeInfo();
  }, [yid]);

  useEffect(() => {
    const handleUnload = () => {
      clearInterval(progressInterval);
    };

    window.addEventListener('beforeunload', handleUnload);

    return () => {
      window.removeEventListener('beforeunload', handleUnload);
    };
  }, [progressInterval]);

  useEffect(() => {
    if (history) {
      const unlisten = history.listen(() => {
        clearInterval(progressInterval);
      });

      return () => {
        unlisten();
      };
    }
  }, [history, progressInterval]);

  const onReady = (event) => {
    playerRef.current = event.target;
  };

  const onPlay = () => {
    if(!isSubmitted){
      trackProgress();
    }
  };

  const onStateChange = (event) => {
    if (event.data === 1) {
      console.log('Video is playing');
    } else {
      console.log('Video is paused or ended');
      clearInterval(progressInterval);
    }
  };

  const getOrderRelativeToOtherKeys = (key) => {
    // Initialize a variable to count the total number of questions before the given key
    let questionCount = 0;
  
    // Sort the keys numerically
    const sortedKeys = Object.keys(betweenQuestions).sort((a, b) => Number(a) - Number(b));
  
    // Iterate through the sorted keys
    for (let currentKey of sortedKeys) {
      // Get the number of subquestions for the current key
      const subquestions = betweenQuestions[currentKey].length;
  
      // If the current key matches the input key, return the total count + 1
      if (currentKey === `${key}`) {
        return questionCount + 1; // Add 1 because question numbering starts from 1
      }
  
      // Otherwise, add the number of subquestions to the total count
      questionCount += subquestions;
    }
  
    // If the key is not found, return null
    return null;
  };
  

  const trackProgress = () => {
    const intervalId = setInterval(() => {
      const currentTime = Math.floor(playerRef.current.getCurrentTime());

      if (betweenQuestions[currentTime] && currentQuestionIndex !== currentTime) {
        setCurrentQuestionIndex(currentTime);
        setShowQuestionModal(true);

        console.log(`currentTime: ${currentTime}`)
        console.log(`betweenQuestions: ${JSON.stringify(betweenQuestions)}`)

        setCurBetweenQuestionNum(getOrderRelativeToOtherKeys(currentTime))
        playerRef.current.pauseVideo();
      }

      const videoDuration = playerRef.current.getDuration();
      const timeRemaining = videoDuration - currentTime;
      const timeRemainingInSeconds = Math.floor(timeRemaining);

      if (timeRemainingInSeconds === (endQuestionTimeSeconds ? endQuestionTimeSeconds : 5) && !showEndQuestionsModal && endQuestions.length > 0) {
        setShowEndQuestionsModal(true);
        playerRef.current.pauseVideo();
      }

    }, 1000);

    setProgressInterval(intervalId);
  };

  const closeModal = () => {
    setShowQuestionModal(false);
    setShowEndQuestionsModal(false);
    setCurrentQuestionIndex(null);
    playerRef.current.playVideo();
  };

  const goBackToTime = () => {
    if (!betweenQuestions[currentQuestionIndex]) return;

    const earliestBackTime = betweenQuestions[currentQuestionIndex]
      .map((q) => q.back_time)
      .filter(time => time !== null && time !== undefined)
      .map(timeToSeconds)
      .sort((a, b) => a - b)[0];

    if (earliestBackTime !== undefined && playerRef.current) {
      closeModal();
      playerRef.current.seekTo(earliestBackTime);
      playerRef.current.playVideo();
    }
  };

  const handleChoiceSelection = (questionIndex, choiceId) => {
    setSelectedChoiceIds((prevSelected) => ({
      ...prevSelected,
      [questionIndex]: choiceId,
    }));
  };

  const handleTextInputChange = (event, index) => {
    const { value } = event.target;
    setFilledTexts((prevTexts) => ({
      ...prevTexts,
      [index]: value,
    }));
  };

  /*const submitAnswer = () => {
    let isCorrectOverall = true;
    let betweenExplanations = [];
    let correctAnswersCount = 0;

    betweenQuestions[currentQuestionIndex].forEach((question, index) => {
      const correctAnswerIds = question.question_items.filter(item => item.is_correct).map(item => item.id);
      let isCorrect = true;

      if (question.question_type === "choice") {
        if (selectedChoiceIds[index] === undefined) {
          isCorrect = false;
        } else if (!correctAnswerIds.includes(selectedChoiceIds[index])) {
          isCorrect = false;
        }
      } else {
        const correctAnswers = question.question_items.filter(item => item.is_correct);
        const trimmedSelectedText = filledTexts[index]?.trim() || "";

        isCorrect = correctAnswers.some(answer => {
          const trimmedCorrectAnswerText = answer.text.trim();
          return trimmedSelectedText === trimmedCorrectAnswerText;
        });
      }

      if (isCorrect) {
        correctAnswersCount++;
      } else {
        isCorrectOverall = false;
      }

      // Store the result and explanation for each question
      betweenExplanations.push({
        order: index + 1,
        isCorrect: isCorrect,
        description: question.description
      });
    });

    const totalQuestions = betweenQuestions[currentQuestionIndex].length;
    const scoreMessage = `คุณตอบคำถามถูก ${correctAnswersCount} ข้อ จาก ${totalQuestions} ข้อ`;

    if (isCorrectOverall) {
      closeModal();
      setShowExplanation(false);
    } else {
      setExplanations(betweenExplanations);
      setShowExplanation(true);
    }

    Swal.fire({
      title: "ผลการตอบคำถาม",
      text: scoreMessage,
      confirmButtonText: 'ตกลง',
      confirmButtonColor: "gray",
      icon: "info",
    });
  };*/

  const submitAnswer = () => {
    let isCorrectOverall = true;
    let betweenExplanations = [];
    let correctAnswersCount = 0;
    let calculatedMaxScore = 0;

    betweenQuestions[currentQuestionIndex].forEach((question, index) => {
      const correctAnswerIds = question.question_items.filter(item => item.is_correct).map(item => item.id);
      let isCorrect = true;
      const questionScore = question.score ?? 1; // Default to 1 if score is not specified
      calculatedMaxScore += questionScore;  // Accumulate max score

      if (question.question_type === "choice") {
        if (selectedChoiceIds[index] === undefined) {
          isCorrect = false;
        } else if (!correctAnswerIds.includes(selectedChoiceIds[index])) {
          isCorrect = false;
        }
      } else {
        const correctAnswers = question.question_items.filter(item => item.is_correct);
        const trimmedSelectedText = filledTexts[index]?.trim() || "";

        isCorrect = correctAnswers.some(answer => {
          const trimmedCorrectAnswerText = answer.text.trim();
          return trimmedSelectedText === trimmedCorrectAnswerText;
        });
      }

      if (isCorrect) {
        correctAnswersCount += questionScore;
        
      } else {
        isCorrectOverall = false;
      }

      // Store the result and explanation for each question
      betweenExplanations.push({
        order: index + 1,
        isCorrect: isCorrect,
        description: question.description
      });
    });

    const scoreMessage = `คะแนนที่ได้ ${correctAnswersCount}/${calculatedMaxScore} คะแนน`;

    if (isCorrectOverall) {
      closeModal();
      setShowExplanation(false);
    } else {
      setShowExplanation(true);
    }

    const content = <Box mt={2} p={4} border="1px solid #ddd">
        <Text mb={4} fontWeight={"700"} fontSize={22}>คะแนนที่ได้ {correctAnswersCount}/{calculatedMaxScore} คะแนน</Text>
        {betweenExplanations.map((explanationObj, idx) => (
          <Box key={idx} mb={3}>
            <Text textStyle={"body1.default"} fontWeight="bold">
              ข้อ {curBetweenQuestionNum + idx} {explanationObj.isCorrect ? "ถูกต้อง" : "ไม่ถูกต้อง"}
            </Text>
            {explanationObj.description && <Flex>
              <Text w={"70px"} textStyle={"body1.default"} fontSize={"sm"} >คำอธิบาย:</Text>
              <MathJax key={`exp${idx}`}>
                <Box textStyle={"body1.default"} fontSize={"sm"} dangerouslySetInnerHTML={{ __html: explanationObj.description }} w="100%" />
              </MathJax>
            </Flex>}
          </Box>
        ))}
      </Box>

    if(betweenExplanations.length > 0){
      setBetweenQuestionSummary(content)
    }
    else{
      setBetweenQuestionSummary(<Text>{scoreMessage}</Text>)
    }
    onModalOpen()
  };

  const calculateMaxScore = (endQuestions) => {
    return endQuestions.reduce((totalMaxScore, question) => {
      const questionScore = question.score ?? 1; // Default to 1 if score is not specified
      return totalMaxScore + questionScore;
    }, 0);
  };

  const submitFinalAnswer = () => {
    Swal.fire({
      title: 'ยืนยันการส่งคำตอบ',
      text: 'คุณแน่ใจหรือไม่ว่าต้องการส่งคำตอบนี้? คุณจะไม่สามารถแก้ไขคำตอบได้อีกหลังจากนี้',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'ยืนยัน',
      cancelButtonText: 'ยกเลิก',
    }).then((result) => {
      if (result.isConfirmed) {
        // Your original submitFinalAnswer logic goes here
        console.log("submitFinalAnswer called"); // Debugging log
  
        let correctAnswersCount = 0;
        let calculatedMaxScore = 0;
        let endExplanations = [];
  
        const totalScore = endQuestions.reduce((total, question, index) => {
          let isCorrect = false;
          const questionScore = question.score ?? 1;
          calculatedMaxScore += questionScore;
  
          if (question.question_type === "choice") {
            const correctChoiceIds = question.question_items
              .filter(item => item.is_correct)
              .map(item => item.id);
  
            isCorrect = correctChoiceIds.some(id => selectedChoiceIds[index] === id);
          } else {
            const correctAnswerText = question.question_items
              .find(item => item.is_correct)
              .text.trim();
  
            const filledText = filledTexts[index]?.trim();
  
            isCorrect = filledText === correctAnswerText;
          }
  
          if (isCorrect) {
            correctAnswersCount += questionScore; // Add the score of the correct question
          }

          endExplanations.push({
            order: index + 1,
            isCorrect: isCorrect,
            description: question.description
          });
  
          return total + (isCorrect ? questionScore : 0); // Add the score of the correct answer to the total
        }, 0);
  
        console.log("correctAnswersCount:", correctAnswersCount); // Debugging log
        console.log("calculatedMaxScore:", calculatedMaxScore); // Debugging log
  
        setMaxScore(calculatedMaxScore);  // <-- Set the maxScore state
        setTotalScore(correctAnswersCount);
        setIsSubmitFinalAnswer(true);
        if(saveScore){
          saveScore(totalScore, calculatedMaxScore);
        }
  
        console.log(`Total Score: ${totalScore}/${calculatedMaxScore}`);
        let endSums = [];

        {endExplanations.map((explanationObj, idx) => (
          endSums.push(<Box key={idx} mb={3}>
            <Text textStyle={"body1.default"} fontWeight="bold">
              ข้อ {explanationObj.order} {explanationObj.isCorrect ? "ถูกต้อง" : "ไม่ถูกต้อง"}
            </Text>
            {explanationObj.description && <Flex>
              <Text w={"70px"} textStyle={"body1.default"} fontSize={"sm"} >คำอธิบาย:</Text>
              <MathJax key={`exp${idx}`}>
                <Box textStyle={"body1.default"} fontSize={"sm"} dangerouslySetInnerHTML={{ __html: explanationObj.description }} w="100%" />
              </MathJax>
            </Flex>}
          </Box>)
        ))}

        setEndQuestionSummary(endSums)
        setShowExplanation(true);
      }
    });
  };

  const closeAnswerModal = () => {
    closeModal();
    setShowExplanation(false);
    setTotalScore(0);  // Reset score
    setMaxScore(0);     // Reset max score
  };

  return (
    <Flex w="100%" direction="column" justify="center">
      <MathJaxContext version={3} config={config}>
        {youtubeID && (
          <YouTube
            videoId={youtubeID}
            onReady={onReady}
            onStateChange={onStateChange}
            onPlay={onPlay}
            opts={opts}
            className="youtubeContainer"
          />
        )}
        <Modal size="3xl" closeOnOverlayClick={false} isOpen={showQuestionModal} curBetweenQuestionNum={curBetweenQuestionNum} onClose={() => {
          setShowExplanation(false)
          closeModal()
        }}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader textStyle="h5">คำถามระหว่างวิดีทัศน์</ModalHeader>
            <ModalBody>
              {currentQuestionIndex !== null && betweenQuestions[currentQuestionIndex] && (
                betweenQuestions[currentQuestionIndex].map((question, index) => (
                  <Flex borderTop="1px solid #ddd" py={4}>
                    <Text textStyle={"body1.default"} w={"50px"} fontWeight={600} mr={2}>ข้อ {curBetweenQuestionNum + index}{")"}.</Text>
                    <Box textStyle={"body1.default"} mb={2} key={index} w={"100%"}>
                      <MathJax>
                        <Box dangerouslySetInnerHTML={{ __html: question.question }} w="100%" />
                      </MathJax>
                      <Stack spacing={2}>
                        {question.question_type === "choice" && question.question_items.map((choice) => (
                          <MathJax key={choice.id}>
                            <Box
                              cursor={"pointer"}
                              onClick={() => handleChoiceSelection(index, choice.id)}
                              borderColor={selectedChoiceIds[index] === choice.id ? "green.300" : "gray.300"}
                              borderWidth={"3px"}
                              dangerouslySetInnerHTML={{ __html: `<span style="color: black">${choice.text}</span>` }}
                              w="95%"
                              p={4}
                              rounded="md"
                              bg={"white"}
                            />
                          </MathJax>
                        ))}
                        {question.question_type !== "choice" && (
                          <MathJax>
                            <Input
                              onChange={(e) => handleTextInputChange(e, index)}
                              value={filledTexts[index] || ""}
                              w="100%"
                              key={question.id}
                              borderWidth={1}
                              mt={1}
                              p={4}
                              rounded="md"
                              placeholder='ระบุคำตอบ'
                              borderColor={"gray.300"}
                              bg={"white"}
                            />
                          </MathJax>
                        )}
                      </Stack>
                    </Box>
                  </Flex>
                ))
              )}


            </ModalBody>
            <ModalFooter>
              {!showExplanation && <Flex w="100%" justify={"space-between"}>
                <Flex>
                  {currentQuestionIndex !== null && betweenQuestions[currentQuestionIndex] &&
                    betweenQuestions[currentQuestionIndex].some(q => q.back_time !== null && q.back_time !== undefined) && (
                      <Button colorScheme="green" variant='outline' onClick={goBackToTime}>
                        ย้อนดูเนื้อหา
                      </Button>
                    )}
                </Flex>
                <Button colorScheme="blue" onClick={submitAnswer}>ส่งคำตอบ</Button>
              </Flex>}
              {showExplanation && <Flex w={"100%"} justify={"center"}>
                <Button colorScheme="blue" onClick={() => {
                  setShowExplanation(false)
                  closeModal()
                }}>ดูวิดีโอต่อ</Button>
              </Flex>}
            </ModalFooter>
          </ModalContent>
        </Modal>

        {endQuestions && endQuestions.length > 0 && <Modal size="3xl" closeOnOverlayClick={false} isOpen={showEndQuestionsModal} onClose={closeModal}>
          <ModalOverlay />

          <ModalContent>
            <ModalHeader textStyle="h5">คำถามท้ายวิดีทัศน์</ModalHeader>
            <ModalBody>
              {isSubmitFinalAnswer && (
                <Flex w="100%" mb={4} justifyContent="center">
                  <Text fontSize={24} fontWeight={700}>คะแนนที่ได้: {totalScore}/{maxScore}</Text>
                </Flex>
              )}
              {endQuestions.length > 0 && (
                <div>
                  {endQuestions.map((question, index) => (
                    <Box p={2} key={question.id} mb={4} borderWidth={showExplanation?2:0} borderColor={"blue.100"}>

                      <Flex>
                        <Text mb={2} w={"60px"} textStyle='body1.default' fontWeight={600}>ข้อ {index + 1}{")"}</Text>
                        <MathJax>
                          <Box dangerouslySetInnerHTML={{ __html: question.question }} w="100%" />
                        </MathJax>
                      </Flex>
                      <Stack spacing={2}>
                        {question.question_type === "choice" && question.question_items.map((choice) => (
                          <MathJax key={choice.id}>
                            <Box
                              onClick={() => (!isSubmitFinalAnswer) ? handleChoiceSelection(index, choice.id) : null}
                              dangerouslySetInnerHTML={{ __html: `<span style="color: black">${choice.text}</span>` }}
                              borderColor={selectedChoiceIds[index] === choice.id ? "green.300" : "gray.300"}
                              borderWidth={"3px"}
                              p={4}
                              rounded="md"
                              bg={"white"}
                              cursor="pointer"
                            />
                          </MathJax>
                        ))}

                        {question.question_type !== "choice" && (
                          <MathJax key={question.id}>
                            <Input
                              onChange={(e) => handleTextInputChange(e, index)}
                              value={filledTexts[index] || ""}
                              w="100%"
                              borderWidth={1}
                              p={4}
                              rounded="md"
                              borderColor={"gray.300"}
                              bg={"white"}
                            />
                          </MathJax>
                        )}

                        {showExplanation && endQuestionSummary && endQuestionSummary[index]}

                        {/*showExplanation && (
                          <Box w="100%" p={2} bgColor="blue.50" borderWidth="2px">
                            <Flex w="100%" mb={2}>
                              <Text minW="110px" mr={2} fontWeight={500}>คำอธิบายเฉลย:</Text>
                              <Box dangerouslySetInnerHTML={{ __html: question.description }} w="100%" />
                            </Flex>
                          </Box>
                        )*/}
                      </Stack>
                    </Box>
                  ))}
                </div>
              )}
            </ModalBody>
            <ModalFooter>
              <Flex w={"100%"} justify={"center"}>
                {isSubmitFinalAnswer && <Button colorScheme="red" onClick={closeAnswerModal}>ปิด</Button>}
                {!isSubmitFinalAnswer && <Button colorScheme="green" onClick={submitFinalAnswer} ml={7}>ส่งคำตอบ</Button>}
              </Flex>
            </ModalFooter>
          </ModalContent>
        </Modal>
        }

        <BetweenQuestionScoreModal isOpen={isModalOpen} onClose={onModalClose} title="ผลการทำแบบฝึกหัดระหว่างคลิป" content={betweenQuestionSummary} />
      </MathJaxContext>
      
    </Flex>
  );
};

export default QuestionYoutube;