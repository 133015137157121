import React, { useEffect, useState } from 'react';
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Select,
  Flex,
  FormControl,
  FormLabel,
  useToast
} from '@chakra-ui/react';
import { showWarningToast } from 'util/alertUtil';

const TimeSelectorModal = ({ initTime, isOpen, onClose, onSave }) => {
  const [selectedHour, setSelectedHour] = useState('');
  const [selectedMinute, setSelectedMinute] = useState('');
  const [selectedSecond, setSelectedSecond] = useState('');

  const hours = Array.from({ length: 24 }, (_, i) => i.toString().padStart(2, '0'));
  const minutes = Array.from({ length: 60 }, (_, i) => i.toString().padStart(2, '0'));
  const seconds = Array.from({ length: 60 }, (_, i) => i.toString().padStart(2, '0'));

  const toast = useToast();

  function timeToSeconds(timeString) {
    const [hours, minutes, seconds] = timeString.split(':').map(Number);
    return hours * 3600 + minutes * 60 + seconds;
  }

  const handleSave = () => {
    if(selectedSecond){
      const hh = (selectedHour)?selectedHour:'00';
      const mm = (selectedMinute)?selectedMinute:'00';
      const ss = (selectedSecond)?selectedSecond:'00';
      const time = `${hh}:${mm}:${ss}`;

      //const seconds = timeToSeconds(time)

      onSave(time, time);
      onClose();
    }
    else{
      showWarningToast(toast, "คุณยังไม่ได้ระบุเวลา", "กรุณาระบุเวลา")
    }
  };

  useEffect(() => {
    if (initTime) {
      const [hh, mm, ss] = initTime.split(':');
      setSelectedHour(hh);
      setSelectedMinute(mm);
      setSelectedSecond(ss);
    }
  }, [initTime]);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>เลือกเวลา</ModalHeader>
        <ModalBody>
         <Flex>
          <FormControl>
            <FormLabel>ชั่วโมง</FormLabel>
            <Select mr={1} value={selectedHour} onChange={(e) => setSelectedHour(e.target.value)}>
                <option key="hh" value={""}>ชั่วโมง</option>
                {hours.map((hour) => (
                <option key={hour} value={hour}>{hour}</option>
                ))}
            </Select>
          </FormControl>
          
          <FormControl>
            <FormLabel>นาที</FormLabel>
            <Select mr={1}  value={selectedMinute} onChange={(e) => setSelectedMinute(e.target.value)}>
              <option key="mm" value={""}>นาที</option>
              {minutes.map((minute) => (
              <option key={minute} value={minute}>{minute}</option>
              ))}
            </Select>
          </FormControl>
           
          <FormControl>
            <FormLabel>วินาที</FormLabel>
            <Select mr={1}  value={selectedSecond} onChange={(e) => setSelectedSecond(e.target.value)}>
                <option key="ss" value={""}>วินาที</option>
                {seconds.map((second) => (
                <option key={second} value={second}>{second}</option>
                ))}
            </Select>
          </FormControl>
         </Flex>
        </ModalBody>
        <ModalFooter>
          <Button onClick={onClose}>ยกเลิก</Button>
          <Button colorScheme="blue" ml={2} onClick={handleSave}>
            บันทึก
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default TimeSelectorModal;
