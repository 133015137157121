import React, { useState, useEffect } from 'react';
import {
  ChakraProvider,
  Box,
  Select,
  FormControl,
  FormLabel,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Flex
} from '@chakra-ui/react';
import { FaFileCsv } from 'react-icons/fa';
import { exportCSV } from 'util/common';
import moment from 'moment';

const DateRangeModal = ({isLoading, isOpen, onClose, onSelectDates }) => {
  const currentYear = new Date().getFullYear();
  const [startYear, setStartYear] = useState(currentYear);
  const [startMonth, setStartMonth] = useState(1);
  const [startDay, setStartDay] = useState(1);
  const [endYear, setEndYear] = useState(currentYear);
  const [endMonth, setEndMonth] = useState(12);
  const [endDay, setEndDay] = useState(31);

  const daysInMonth = (year, month) => new Date(year, month, 0).getDate();

  const generateDaysOptions = (year, month) => {
    const days = daysInMonth(year, month);
    return Array.from({ length: days }, (_, i) => i + 1);
  };

  useEffect(() => {
    if (startDay > daysInMonth(startYear, startMonth)) {
      setStartDay(daysInMonth(startYear, startMonth));
    }
  }, [startYear, startMonth]);

  useEffect(() => {
    if (endDay > daysInMonth(endYear, endMonth)) {
      setEndDay(daysInMonth(endYear, endMonth));
    }
  }, [endYear, endMonth]);

  const handleSelectDates = async () => {
    const startDate = new Date(startYear, startMonth - 1, startDay);
    const endDate = new Date(endYear, endMonth - 1, endDay);
    onSelectDates({ startDate, endDate });
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>เลือกช่วงวันที่</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl>
            <FormLabel>วันที่เริ่มต้น</FormLabel>
            <Flex>
              <Select value={startDay} onChange={(e) => setStartDay(Number(e.target.value))} mr={2}>
                {generateDaysOptions(startYear, startMonth).map((day) => (
                  <option key={day} value={day}>{day}</option>
                ))}
              </Select>
              <Select value={startMonth} onChange={(e) => setStartMonth(Number(e.target.value))} mr={2}>
                {Array.from({ length: 12 }, (_, i) => (
                  <option key={i + 1} value={i + 1}>{new Date(startYear, i, 1).toLocaleString('th-TH', { month: 'long' })}</option>
                ))}
              </Select>
              <Select value={startYear} onChange={(e) => setStartYear(Number(e.target.value))}>
                {Array.from({ length: 5 }, (_, i) => currentYear + i).map((yearOption) => (
                  <option key={yearOption} value={yearOption}>{yearOption + 543}</option>
                ))}
              </Select>
            </Flex>
          </FormControl>
          <FormControl mt={4}>
            <FormLabel>วันที่สิ้นสุด</FormLabel>
            <Flex>
              <Select value={endDay} onChange={(e) => setEndDay(Number(e.target.value))} mr={2}>
                {generateDaysOptions(endYear, endMonth).map((day) => (
                  <option key={day} value={day}>{day}</option>
                ))}
              </Select>
              <Select value={endMonth} onChange={(e) => setEndMonth(Number(e.target.value))} mr={2}>
                {Array.from({ length: 12 }, (_, i) => (
                  <option key={i + 1} value={i + 1}>{new Date(endYear, i, 1).toLocaleString('th-TH', { month: 'long' })}</option>
                ))}
              </Select>
              <Select value={endYear} onChange={(e) => setEndYear(Number(e.target.value))}>
                {Array.from({ length: 5 }, (_, i) => currentYear + i).map((yearOption) => (
                  <option key={yearOption} value={yearOption}>{yearOption + 543}</option> 
                ))}
              </Select>
            </Flex>
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button isLoading={isLoading} leftIcon={<FaFileCsv />} colorScheme="blue" mr={3} onClick={handleSelectDates}>Export CSV</Button>
          <Button variant="ghost" onClick={onClose}>ยกเลิก</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default DateRangeModal;