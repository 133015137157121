import React, { useEffect, useState, useRef } from 'react';
import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  FormControl,
  FormLabel,
  Image,
  Input,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Spinner,
  Text,
  Textarea,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { useDropzone } from 'react-dropzone';
import axios from 'axios';
import Swal from 'sweetalert2';
import { Editor } from '@tinymce/tinymce-react';
import { getClasses } from 'apis/subjectAPI';
import { getSubjects } from 'apis/subjectAPI';
import { useAuth } from 'auth/AuthProvider';

const ManageJobModal = ({ isOpen, operation, onClose, editJob, activityID }) => {
  const [jobType, setJobType] = useState('');
  const [files, setFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isUploadNewFile, setIsUploadNewFile] = useState(false);
  const [mediaTitle, setMediaTitle] = useState('');
  const [materialPDF, setMaterialPDF] = useState('');
  const [materialFile, setMaterialFile] = useState('');
  const [materialText, setMaterialText] = useState('');
  const [materialURL, setMaterialURL] = useState('');
  const [classLevel, setClassLevel] = useState();
  const [subject, setSubject] = useState();
  const [exercise, setExercise] = useState();
  const [submitOnce, setSubmitOnce] = useState('1');
  const [formErrors, setFormErrors] = useState({});
  const [isSubjectsLoading, setSubjectsLoading] = useState(false);
  const [isExerciseLoading, setExerciseLoading] = useState(false);
  const [isClassesLoading, setClassesLoading] = useState(false);
  const [subjects, setSubjects] = useState([]);
  const [classes, setClasses] = useState([]);
  const [exercises, setExercises] = useState([]);
  const [existingImage, setExistingImage] = useState('');
  const [isSourceMode, setIsSourceMode] = useState(false);
  const [isCheckingYoutube, setIsCheckingYoutube] = useState(false); // Spinner for YouTube ID check
  const editorRef = useRef(null);
  const toast = useToast();

  const [uploadingMaterial, setUploadingMaterial] = useState(false)
  const [uploadingPDF, setUploadingPDF] = useState(false)
  const [viewMode, setViewMode] = useState('editor');
  const { user, role, login, logout, isUserExist } = useAuth();

  useEffect(() => {
    resetForm();
    fetchSubjects();
    fetchClasses();
  }, [isOpen]);

  useEffect(() => {
    if (classLevel && subject) {
      loadExamList();
    }
  }, [classLevel, subject]);

  useEffect(() => {
    if (editJob) {
      prepareJobUpdate(editJob);
    } else {
      resetForm();
    }
  }, [editJob]);

  function loadExamList() {
    setExerciseLoading(true);
    axios.post(`${process.env.REACT_APP_API_URL}/exams/load_exam_by_subject_and_class`, {
      subject_id: subject,
      class_id: classLevel
    }, { withCredentials: true })
      .then(function (response) {
        if (response.data && response.data.status === "success") {
          setExercises(response.data.exams);
        }
      })
      .catch(function (error) {
        showWarningToast(toast, "แจ้งเตือน", "ไม่สามารถโหลดชุดข้อสอบได้");
      })
      .finally(function () {
        setExerciseLoading(false);
      });
  }

  const resetForm = () => {
    setViewMode('editor')
    setMediaTitle('');
    setFiles([]);
    setJobType('');
    setMaterialPDF('');
    setMaterialFile('');
    setMaterialText('');
    setClassLevel('');
    setSubject('');
    setExercise('');
    setSubmitOnce('1');
    setFormErrors({});
    setExistingImage('');
  };

  const prepareJobUpdate = (j) => {
    if (j) {
      setMediaTitle(j.media_title);
      if (j.thumbnail) {
        setFiles([]);
        setExistingImage(`${j.thumbnail}`);
      } else {
        setFiles([]);
        setExistingImage();
      }

      if (j.job_type === "exercise") {
        setJobType("exercise");
        setClassLevel(j.ipst_class_id);
        setSubject(j.ipst_subject_id);
        setExercise(j.ipst_exam_id);
        setSubmitOnce(j.is_submit_once);
      } else if (j.job_type === "uploaded_material") {
        setJobType("uploaded_material");
        setMaterialFile(j.material_file);
        setMaterialPDF(j.material_pdf);
      } else if (j.job_type === "outside_material") {
        setJobType("outside_material");
        setMaterialURL(j.material_url);
      } else if (j.job_type === "text") {
        setJobType("text");
        setMaterialText(j.material_text);
        if(j && j.material_text){
          setViewMode('preview')
        }

      } else if (j.job_type === "youtube_exercise") {
        setJobType("youtube_exercise");
        setMaterialText(j.material_text);
      }
    }
  };

  const handleJobTypeChange = (e) => {
    setJobType(e.target.value);
    setFormErrors({});
  };

  const onDrop = (acceptedFiles) => {
    setIsUploadNewFile(true);
    setFiles(
      acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      )
    );
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {'image/*': []},
    multiple: false,
  });

  const fetchSubjects = async () => {
    setSubjectsLoading(true);
    try {
      const subs = await getSubjects();
      setSubjects(subs);
    } catch (error) {
      console.error('Error fetching subjects:', error);
    } finally {
      setSubjectsLoading(false);
    }
  };

  const fetchClasses = async () => {
    setClassesLoading(true);
    try {
      const cls = await getClasses();
      setClasses(cls);
    } catch (error) {
      console.error('Error fetching classes:', error);
    } finally {
      setClassesLoading(false);
    }
  };

  const validateForm = () => {
    let errors = {};
    if (!mediaTitle) errors.mediaTitle = 'กรุณากรอกชื่อแบบฝึกหัด/สื่อการเรียน';

    if (jobType === 'exercise') {
      if (!classLevel) errors.classLevel = 'กรุณาเลือกระดับชั้น';
      if (!subject) errors.subject = 'กรุณาเลือกวิชา';
      if (!exercise) errors.exercise = 'กรุณาเลือกแบบฝึกหัด';
    }

    if (jobType === 'uploaded_material' && !materialPDF)
      errors.materialPDF = 'กรุณาอัพโหลดไฟล์ PDF';
    if (jobType === 'outside_material' && (!materialURL || !isValidUrl(materialURL)))
      errors.materialURL = 'กรุณากรอกลิงค์และเช็คความถูกต้อง';
    if (jobType === 'text' && !materialText) errors.materialText = 'กรุณากรอกข้อความ';
    if (jobType === 'youtube_exercise' && !materialText)
      errors.materialText = 'กรุณากรอก Youtube ID';

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSave = async () => {
    //if (editorRef.current) {
    //  setMaterialText(editorRef.current.getContent());
    //}

    if (!files[0] && !existingImage) {
      toast({
        title: 'แจ้งเตือน',
        description: 'กรุณาอัพโหลดไฟล์ภาพ',
        status: 'warning',
        duration: 5000,
        isClosable: true,
      });
    } else if (validateForm()) {
      if (jobType === 'youtube_exercise') {
        setIsCheckingYoutube(true);
        try {
          const response = await axios.post(`${process.env.REACT_APP_API_URL}/youtube/check_youtube_status`, { youtube_id: materialText });

          if (response.data.status === 'success' && response.data.video_status === 'published') {
            // Proceed to save if YouTube ID is valid and published
            if (files[0]) {
              uploadNewJob(files[0]);
            } else {
              saveMaterialInfo(existingImage);
            }
          } else {
            setFormErrors((prevErrors) => ({
              ...prevErrors,
              materialText: 'วิดีโอ YouTube ไม่ได้รับการเผยแพร่หรือไม่มีอยู่',
            }));
          }
        } catch (error) {
          setFormErrors((prevErrors) => ({
            ...prevErrors,
            materialText: `เกิดข้อผิดพลาด ${error}`,
          }));
        } finally {
          setIsCheckingYoutube(false);
        }
      } else {
        if (files[0]) {
          uploadNewJob(files[0]);
        } else {
          saveMaterialInfo(existingImage);
        }
      }
    } else {
      toast({
        title: 'แจ้งเตือน',
        description: 'กรุณากรอกข้อมูลให้ครบถ้วน.',
        status: 'warning',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const isValidUrl = (string) => {
    const pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
      '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
    return !!pattern.test(string);
  };

  const uploadNewJob = async (file) => {
    if (isUploadNewFile) {
      const formData = new FormData();
      formData.append('file', file);

      if (!jobType && jobType.trim() === '') {
        Swal.fire({
          title: 'แจ้งเตือน!',
          text: 'กรุณาเลือกประเภทงาน',
          icon: 'warning',
          padding: '2em',
        });
        return;
      }

      setIsLoading(true);

      try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/upload`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        if (response.data && response.data.image_file) {
          const image_file = response.data.image_file;
          saveMaterialInfo(image_file);
        } else {
          setIsLoading(false);
          toast({
            title: 'แจ้งเตือน',
            description: 'กรุณาเลือกภาพหน้าปก',
            status: 'warning',
            duration: 5000,
            isClosable: true,
          });
        }
      } catch (error) {
        setIsLoading(false);
        toast({
          title: 'เกิดข้อผิดพลาด',
          description: error.toString(),
          status: 'warning',
          duration: 5000,
          isClosable: true,
        });
      }
    } else {
      saveMaterialInfo('');
    }
  };

  const saveMaterialInfo = (img) => {
    let data = {};

    if (jobType === 'exercise') {
      data = {
        thumbnail: img,
        operation: operation,
        job_type: jobType,
        job_id: editJob ? editJob.id : '',
        ipst_activity_id: activityID,
        media_title: mediaTitle,
        ipst_class_id: classLevel,
        ipst_subject_id: subject,
        ipst_exam_id: exercise,
        is_submit_once: submitOnce,
      };
    } else if (jobType === 'uploaded_material') {
      data = {
        thumbnail: img,
        operation: operation,
        job_type: jobType,
        job_id: editJob ? editJob.id : '',
        ipst_activity_id: activityID,
        media_title: mediaTitle,
        material_pdf: materialPDF,
        material_file: materialFile,
      };
    } else if (jobType === 'text') {
      data = {
        thumbnail: img,
        operation: operation,
        job_type: jobType,
        job_id: editJob ? editJob.id : '',
        ipst_activity_id: activityID,
        media_title: mediaTitle,
        material_text: materialText,
      };
    } else if (jobType === 'outside_material') {
      data = {
        thumbnail: img,
        operation: operation,
        job_type: jobType,
        job_id: editJob ? editJob.id : '',
        ipst_activity_id: activityID,
        media_title: mediaTitle,
        material_url: materialURL,
      };
    } else if (jobType === 'youtube_exercise') {
      data = {
        thumbnail: img,
        operation: operation,
        job_type: jobType,
        job_id: editJob ? editJob.id : '',
        ipst_activity_id: activityID,
        media_title: mediaTitle,
        material_text: materialText,
      };
    }

    axios
      .post(`${process.env.REACT_APP_API_URL}/jobs/job_save`, data)
      .then((response) => {
        const res = response.data;
        if (res && res.status === 'success') {
          Swal.fire({
            title: 'Success!',
            text: 'บันทึกข้อมูลเรียบร้อย',
            icon: 'success',
            padding: '2em',
          }).then(() => {
            onClose();
          });
        }
      })
      .catch((error) => {
        Swal.fire({
          title: 'Error!',
          text: 'Cannot save data to the server!',
          icon: 'warning',
          padding: '2em',
        });
      });
  };

  const uploadFile = async (file, setUploading) => {
    const formData = new FormData();
    formData.append('file', file);

    try {
      setUploading(true);
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/upload_file`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      setUploading(false);

      if (response.data && response.data.file) {
        return response.data.file;
      } else {
        throw new Error('File upload failed');
      }
    } catch (error) {
      toast({
        title: 'Error',
        description: `File upload failed: ${error.message}`,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      setUploading(false);
      return null;
    }
  };

  const handleEditorChange = (content, editor) => {
    setMaterialText(content);
  };

  const handleTextareaChange = (e) => {
    setMaterialText(e.target.value);
  };

  return (
    <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose} size="5xl" scrollBehavior="inside">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>เพิ่มงาน</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Flex>
            <Box
              bgColor={'blue.50'}
              alignContent={'center'}
              textAlign={'center'}
              cursor={'pointer'}
              h={'160px'}
              flex="1"
              {...getRootProps()}
              border="2px dashed"
              borderColor="gray.200"
              p={4}
            >
              <input {...getInputProps()} type="file" accept="image/png, image/gif, image/jpeg" />
              {files.length === 0 && existingImage ? (
                <Image
                  boxSize={'120px'}
                  src={`${process.env.REACT_APP_API_URL}/images/${existingImage}`}
                  alt="Existing Image"
                />
              ) : files.length > 0 ? (
                <Image boxSize={'120px'} src={files[0].preview} alt="Preview" />
              ) : (
                <VStack>
                  <Text>ภาพ Thumbnail</Text>
                  <Text fontSize="xs">(กดเพื่อเพิ่ม)</Text>
                </VStack>
              )}
            </Box>

            <Box flex="3" ml={4}>
              <FormControl mb={4} isInvalid={formErrors.mediaTitle}>
                <FormLabel htmlFor="media_title">ชื่อแบบฝึกหัด/สื่อการเรียน</FormLabel>
                <Input
                  id="media_title"
                  placeholder="ชื่อแบบฝึกหัด/สื่อการเรียน"
                  value={mediaTitle}
                  onChange={(e) => setMediaTitle(e.target.value)}
                />
                {formErrors.mediaTitle && <Text color="red.500">{formErrors.mediaTitle}</Text>}
              </FormControl>

              <FormControl mb={4} isInvalid={formErrors.jobType}>
                <FormLabel htmlFor="job_select">ประเภทงาน</FormLabel>
                <Select id="job_select" value={jobType} onChange={handleJobTypeChange}>
                  <option value="">--- เลือกประเภทงาน ---</option>
                  <option value="exercise">แบบฝึกหัด</option>
                  {role != "TEACHER" && <option value="youtube_exercise">แบบฝึกหัดจาก Youtube</option>}
                  <option value="uploaded_material">สื่อการเรียน (อัพโหลดไฟล์)</option>
                  <option value="outside_material">สื่อการเรียน (ลิงค์)</option>
                  <option value="text">สื่อการเรียน (Free Text)</option>
                </Select>
                {formErrors.jobType && <Text color="red.500">{formErrors.jobType}</Text>}
              </FormControl>

              {jobType === 'exercise' && (
                <>
                  <FormControl mb={4} isInvalid={formErrors.classLevel}>
                    <FormLabel htmlFor="class_select">ระดับชั้น</FormLabel>
                    {isClassesLoading ? (
                      <Spinner size="sm" />
                    ) : (
                      <Select
                        placeholder="เลือกระดับชั้น"
                        onChange={(v) => {
                          setClassLevel(v.target.value);
                        }}
                        id="class_select"
                        value={classLevel}
                      >
                        {classes.map((cls, index) => (
                          <option key={index} value={cls.id}>
                            {cls.name_thai}
                          </option>
                        ))}
                      </Select>
                    )}
                    {formErrors.classLevel && <Text color="red.500">{formErrors.classLevel}</Text>}
                  </FormControl>
                  <FormControl mb={4} isInvalid={formErrors.subject}>
                    <FormLabel htmlFor="subject_select">วิชา</FormLabel>
                    {isSubjectsLoading ? (
                      <Spinner size="sm" />
                    ) : (
                      <Select
                        placeholder="เลือกวิชา"
                        value={subject}
                        onChange={(sub) => {
                          setSubject(sub.target.value);
                        }}
                      >
                        {subjects.map((subject, index) => (
                          <option key={index} value={subject.id}>
                            {subject.subjectThai}
                          </option>
                        ))}
                      </Select>
                    )}
                    {formErrors.subject && <Text color="red.500">{formErrors.subject}</Text>}
                  </FormControl>
                  <FormControl mb={4} isInvalid={formErrors.exercise}>
                    <FormLabel htmlFor="exercise_select">แบบฝึกหัด</FormLabel>
                    {isExerciseLoading ? (
                      <Spinner size="sm" />
                    ) : (
                      <Select
                        placeholder="เลือกแบบฝึกหัด"
                        id="exercise_select"
                        value={exercise}
                        onChange={(sub) => {
                          setExercise(sub.target.value);
                        }}
                      >
                        {exercises.map((ex, index) => (
                          <option key={index} value={ex.id}>
                            {ex.exam_name}
                          </option>
                        ))}
                      </Select>
                    )}
                    {formErrors.exercise && <Text color="red.500">{formErrors.exercise}</Text>}
                  </FormControl>
                  <FormControl mb={4}>
                    <FormLabel htmlFor="is_submit_once">รูปแบบการตอบคำถามของนักเรียน</FormLabel>
                    <Select
                      id="is_submit_once"
                      value={submitOnce}
                      onChange={(e) => setSubmitOnce(e.target.value)}
                    >
                      <option value="1">ส่งคำตอบได้เพียงครั้งเดียว</option>
                      <option value="0">ส่งคำตอบได้หลายครั้ง</option>
                    </Select>
                  </FormControl>
                </>
              )}

              {jobType === 'uploaded_material' && (
                <FormControl mb={4} isInvalid={formErrors.materialPDF}>
                  <FormLabel htmlFor="pdf_file">ไฟล์ PDF</FormLabel>
                  {!uploadingPDF && !materialPDF && (
                    <Input
                      pt={2}
                      type="file"
                      id="pdf_file"
                      accept="application/pdf"
                      onChange={async (e) => {
                        const file = e.target.files[0];
                        if (file) {
                          const uploadedFile = await uploadFile(file, setUploadingPDF);
                          if (uploadedFile) {
                            setMaterialPDF(uploadedFile);
                          }
                        }
                      }}
                    />
                  )}
                  {uploadingPDF && <Spinner />}
                  {materialPDF && (
                    <Link href={`${process.env.REACT_APP_API_URL}/files/${materialPDF}`} isExternal>
                      {materialPDF}
                    </Link>
                  )}
                  {formErrors.materialPDF && <Text color="red.500">{formErrors.materialPDF}</Text>}
                </FormControl>
              )}

              {jobType === 'uploaded_material' && (
                <FormControl mb={4} isInvalid={formErrors.materialFile}>
                  <FormLabel htmlFor="all_file">ไฟล์ต้นฉบับ</FormLabel>
                  {!uploadingMaterial && !materialFile && (
                    <Input
                      pt={2}
                      type="file"
                      id="all_file"
                      onChange={async (e) => {
                        const file = e.target.files[0];
                        if (file) {
                          const uploadedFile = await uploadFile(file, setUploadingMaterial);
                          if (uploadedFile) {
                            setMaterialFile(uploadedFile);
                          }
                        }
                      }}
                    />
                  )}
                  {uploadingMaterial && <Spinner />}
                  {materialFile && (
                    <Link href={`${process.env.REACT_APP_API_URL}/files/${materialFile}`} isExternal>
                      {materialFile}
                    </Link>
                  )}
                  {formErrors.materialFile && <Text color="red.500">{formErrors.materialFile}</Text>}
                </FormControl>
              )}

              {jobType === 'youtube_exercise' && (
                <FormControl mb={4} isInvalid={formErrors.materialText}>
                  <FormLabel htmlFor="youtube_id">YouTube ID</FormLabel>
                  <Flex alignItems="center">
                    <Input
                      id="youtube_id"
                      placeholder="เช่น kvMW6VkKFww"
                      value={materialText}
                      onChange={(e) => setMaterialText(e.target.value)}
                      isDisabled={isCheckingYoutube}
                    />
                    {isCheckingYoutube && <Spinner ml={2} />}
                  </Flex>
                  {formErrors.materialText && <Text color="red.500">{formErrors.materialText}</Text>}
                </FormControl>
              )}

              {jobType === 'outside_material' && (
                <FormControl mb={4} isInvalid={formErrors.materialURL}>
                  <FormLabel htmlFor="material_url">ลิงค์</FormLabel>
                  <Input
                    id="material_url"
                    placeholder="ลิงค์"
                    value={materialURL}
                    onChange={(e) => setMaterialURL(e.target.value)}
                  />
                  {formErrors.materialURL && <Text color="red.500">{formErrors.materialURL}</Text>}
                </FormControl>
              )}

              {jobType === 'text' && (
                <>
                  <ButtonGroup mb={4}>
                    <Button
                      onClick={() => setViewMode('preview')}
                      colorScheme={viewMode === 'preview' ? 'blue' : 'gray'}
                      variant={viewMode === 'preview' ? 'solid' : 'outline'}
                      size="sm"
                    >
                      Preview
                    </Button>
                    <Button
                      onClick={() => setViewMode('editor')}
                      colorScheme={viewMode === 'editor' ? 'blue' : 'gray'}
                      variant={viewMode === 'editor' ? 'solid' : 'outline'}
                      size="sm"
                    >
                      Editor Mode
                    </Button>
                    <Button
                      onClick={() => setViewMode('source')}
                      colorScheme={viewMode === 'source' ? 'blue' : 'gray'}
                      variant={viewMode === 'source' ? 'solid' : 'outline'}
                      size="sm"
                    >
                      Source Mode
                    </Button>
                  </ButtonGroup>

                  {viewMode === 'preview' && (
                    <Box border="1px solid #ccc" p={4} borderRadius="md" bg="white">
                      <div dangerouslySetInnerHTML={{ __html: materialText }} />
                    </Box>
                  )}

                  {viewMode === 'editor' && (
                    <FormControl mb={4} isInvalid={formErrors.materialText}>
                      <Editor
                        apiKey="5r3v7xeymcjhuylsm9dqnjsvkihvroc3t5rvbm6ned87iihu"
                        onInit={(evt, editor) => (editorRef.current = editor)}
                        value={materialText} // Set the initial value from state
                        onEditorChange={handleEditorChange} // Update state on change
                        init={{
                          width: '100%',
                          height: 450,
                          menubar: 'file edit view format table mathmenu imgmenu',
                          menu: {
                            edit: {
                              title: 'Edit',
                              items: 'undo redo | cut copy paste pastetext | selectall',
                            },
                            insert: { title: 'Insert', items: 'link media | template hr' },
                            view: { title: 'View', items: 'visualaid' },
                            format: {
                              title: 'Format',
                              items:
                                'bold italic underline strikethrough superscript subscript | formats | removeformat',
                            },
                            table: {
                              title: 'Table',
                              items: 'inserttable tableprops deletetable | cell row column',
                            },
                            mathmenu: { title: 'Equation', items: 'mathitem' },
                          },
                          plugins: ['image', 'table', 'link', 'media', 'codesample'],
                          toolbar:
                            'undo redo | formatselect | bold italic backcolor | alignleft aligncenter ' +
                            'alignright alignjustify | bullist numlist outdent indent | removeformat |  help ',
                          content_style: 'body { font-family: Helvetica, Arial, sans-serif; font-size: 14px }',
                        }}
                      />
                      {formErrors.materialText && <Text color="red.500">{formErrors.materialText}</Text>}
                    </FormControl>
                  )}

                  {viewMode === 'source' && (
                    <FormControl mb={4} isInvalid={formErrors.materialText}>
                      <Textarea
                        id="material_text"
                        rows="15"
                        value={materialText} // Set the value from state
                        onChange={handleTextareaChange} // Update state on change
                      />
                      {formErrors.materialText && <Text color="red.500">{formErrors.materialText}</Text>}
                    </FormControl>
                  )}
                </>
              )}

              {/*jobType === 'text' && (
                <>
                  <Button
                    onClick={() => setIsSourceMode(!isSourceMode)}
                    mb={4}
                    colorScheme="blue"
                    variant="outline"
                    size="sm"
                  >
                    {isSourceMode ? 'เปิดใน editor mode' : 'เปิดใน source mode'}
                  </Button>

                  {!isSourceMode ? (
                    <FormControl mb={4} isInvalid={formErrors.materialText}>
                      <Editor
                        apiKey="5r3v7xeymcjhuylsm9dqnjsvkihvroc3t5rvbm6ned87iihu"
                        onInit={(evt, editor) => (editorRef.current = editor)}
                        initialValue={materialText}
                        onEditorChange={handleEditorChange}
                        init={{
                          width: '100%',
                          height: 450,
                          menubar: 'file edit view format table mathmenu imgmenu',
                          menu: {
                            edit: {
                              title: 'Edit',
                              items: 'undo redo | cut copy paste pastetext | selectall',
                            },
                            insert: { title: 'Insert', items: 'link media | template hr' },
                            view: { title: 'View', items: 'visualaid' },
                            format: {
                              title: 'Format',
                              items:
                                'bold italic underline strikethrough superscript subscript | formats | removeformat',
                            },
                            table: {
                              title: 'Table',
                              items: 'inserttable tableprops deletetable | cell row column',
                            },
                            mathmenu: { title: 'Equation', items: 'mathitem' },
                          },
                          plugins: ['image', 'table', 'link', 'media', 'codesample'],
                          toolbar:
                            'undo redo | formatselect | bold italic backcolor | alignleft aligncenter ' +
                            'alignright alignjustify | bullist numlist outdent indent | removeformat |  help ',
                          content_style: 'body { font-family: Helvetica, Arial, sans-serif; font-size: 14px }',
                        }}
                      />
                      {formErrors.materialText && <Text color="red.500">{formErrors.materialText}</Text>}
                    </FormControl>
                  ) : (
                    <FormControl mb={4} isInvalid={formErrors.materialText}>
                      <Textarea
                        id="material_text"
                        rows="15"
                        value={materialText}
                        onChange={(e) => setMaterialText(e.target.value)}
                      />
                      {formErrors.materialText && <Text color="red.500">{formErrors.materialText}</Text>}
                    </FormControl>
                  )}
                </>
              )*/}

            </Box>
          </Flex>
        </ModalBody>

        <ModalFooter>
          <Button variant="ghost" onClick={onClose}>
            ปิด
          </Button>
          <Button colorScheme="blue" ml={3} onClick={handleSave}>
            บันทึก
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ManageJobModal;


/*
import React, { useEffect, useState, useRef } from 'react';
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Image,
  Input,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Spinner,
  Text,
  Textarea,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { useDropzone } from 'react-dropzone';
import axios from 'axios';
import Swal from 'sweetalert2';
import { API_URL } from 'variables/constant';
import { getSubjects, getClasses } from 'apis/subjectAPI';
import { showWarningToast, showSuccessToast } from 'util/alertUtil';
import { Editor } from '@tinymce/tinymce-react';

const ManageJobModal = ({ isOpen, operation, onClose, editJob, activityID }) => {
  const [jobType, setJobType] = useState('');
  const [files, setFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isUploadNewFile, setIsUploadNewFile] = useState(false);
  const [mediaTitle, setMediaTitle] = useState('');
  const [materialPDF, setMaterialPDF] = useState('');
  const [materialFile, setMaterialFile] = useState('');
  const [materialText, setMaterialText] = useState('');
  const [materialURL, setMaterialURL] = useState('');
  const [classLevel, setClassLevel] = useState();
  const [subject, setSubject] = useState();
  const [exercise, setExercise] = useState();
  const [submitOnce, setSubmitOnce] = useState('0');
  const [formErrors, setFormErrors] = useState({});
  const [isSubjectsLoading, setSubjectsLoading] = useState(false);
  const [isExerciseLoading, setExerciseLoading] = useState(false);
  const [isClassesLoading, setClassesLoading] = useState(false);
  const [subjects, setSubjects] = useState([]);
  const [classes, setClasses] = useState([]);
  const [exercises, setExercises] = useState([]);
  const [existingImage, setExistingImage] = useState('');
  const [isSourceMode, setIsSourceMode] = useState(false); // New state for switching modes
  
  const [uploadingMaterial, setUploadingMaterial] = useState(false)
  const [uploadingPDF, setUploadingPDF] = useState(false)

  const editorRef = useRef(null);
  const toast = useToast();

  useEffect(() => {
    fetchSubjects();
    fetchClasses();
  }, []);

  useEffect(() => {
    if (classLevel && subject) {
      loadExamList();
    }
  }, [classLevel, subject]);

  useEffect(() => {
    if (editJob) {
      prepareJobUpdate(editJob);
    } else {
      resetForm();
    }
  }, [editJob]);

  const resetForm = () => {
    setMediaTitle('');
    setFiles([]);
    setJobType('');
    setMaterialPDF('');
    setMaterialFile('');
    setMaterialText('')
    setClassLevel('');
    setSubject('');
    setExercise('');
    setSubmitOnce('0');
    setFormErrors({});
    setExistingImage('');
  };

  const prepareJobUpdate = (j) => {
    if (j) {
      setMediaTitle(j.media_title);
      if (j.thumbnail) {
        setFiles([]);
        setExistingImage(`${j.thumbnail}`);
      } else {
        setFiles([]);
        setExistingImage();
      }

      if (j.job_type === "exercise") {
        setJobType("exercise");
        setClassLevel(j.ipst_class_id);
        setSubject(j.ipst_subject_id);
        setExercise(j.ipst_exam_id);
        setSubmitOnce(j.is_submit_once);
      } else if (j.job_type === "uploaded_material") {
        setJobType("uploaded_material");
        setMaterialFile(j.material_file)
        setMaterialPDF(j.material_pdf)
      } else if (j.job_type === "outside_material") {
        setJobType("outside_material");
        setMaterialURL(j.material_url);
        console.log(j.material_url)
      } else if (j.job_type === "text") {
        setJobType("text");
        setMaterialText(j.material_text);
      } else if (j.job_type === "youtube_exercise") {
        setJobType("youtube_exercise");
        setMaterialText(j.material_text);
      }
    }
  };

  const handleJobTypeChange = (e) => {
    setJobType(e.target.value);
    setFormErrors({});
  };

  const onDrop = (acceptedFiles) => {
    setIsUploadNewFile(true);
    setFiles(acceptedFiles.map((file) => Object.assign(file, {
      preview: URL.createObjectURL(file),
    })));
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: 'image/*',
    multiple: false,
  });

  const isValidUrl = (string) => {
    const pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
      '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
    return !!pattern.test(string);
  };

  const validateForm = () => {
    let errors = {};
    if (!mediaTitle) errors.mediaTitle = 'กรุณากรอกชื่อแบบฝึกหัด/สื่อการเรียน';

    if (jobType === 'exercise') {
      if (!classLevel) errors.classLevel = 'กรุณาเลือกระดับชั้น';
      if (!subject) errors.subject = 'กรุณาเลือกวิชา';
      if (!exercise) errors.exercise = 'กรุณาเลือกแบบฝึกหัด';
    }

    if (jobType === 'uploaded_material' && !materialPDF) errors.materialPDF = 'กรุณาอัพโหลดไฟล์ PDF';
    if (jobType === 'outside_material' && (!materialURL || !isValidUrl(materialURL))) errors.materialURL = 'กรุณากรอกลิงค์และเช็คความถูกต้อง';
    if (jobType === 'text' && !materialText) errors.materialText = 'กรุณากรอกข้อความ';
    if (jobType === 'youtube_exercise' && !materialText) errors.materialText = 'กรุณากรอก Youtube ID';

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const fetchSubjects = async () => {
    setSubjectsLoading(true);
    try {
      const subs = await getSubjects();
      setSubjects(subs);
    } catch (error) {
      console.error('Error fetching subjects:', error);
    } finally {
      setSubjectsLoading(false);
    }
  };

  const fetchClasses = async () => {
    setClassesLoading(true);
    try {
      const cls = await getClasses();
      setClasses(cls);
    } catch (error) {
      console.error('Error fetching classes:', error);
    } finally {
      setClassesLoading(false);
    }
  };

  function loadExamList() {
    setExerciseLoading(true);
    axios.post(`${process.env.REACT_APP_API_URL}/exams/load_exam_by_subject_and_class`, {
      subject_id: subject,
      class_id: classLevel
    }, { withCredentials: true })
      .then(function (response) {
        if (response.data && response.data.status === "success") {
          setExercises(response.data.exams);
        }
      })
      .catch(function (error) {
        showWarningToast(toast, "แจ้งเตือน", "ไม่สามารถโหลดชุดข้อสอบได้");
      })
      .finally(function () {
        setExerciseLoading(false);
      });
  }

  const handleSave = () => {
    if (editorRef.current) {
      setMaterialText(editorRef.current.getContent());
    }

    if(!files[0] && !existingImage){
        toast({
            title: 'แจ้งเตือน',
            description: 'กรุณาอัพโหลดไฟล์ภาพ',
            status: 'warning',
            duration: 5000,
            isClosable: true,
          });
    }
    else if (validateForm()) {
      if(files[0]){
        uploadNewJob(files[0]);
      }
      else{
        saveMaterialInfo(existingImage)
      }
    } else {
      toast({
        title: 'แจ้งเตือน',
        description: 'กรุณากรอกข้อมูลให้ครบถ้วน.',
        status: 'warning',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const uploadNewJob = async (file) => {
    if (isUploadNewFile) {
      const formData = new FormData();
      formData.append('file', file);

      if (!jobType && jobType.trim() === "") {
        Swal.fire({
          title: 'แจ้งเตือน!',
          text: "กรุณาเลือกประเภทงาน",
          icon: 'warning',
          padding: '2em'
        });
        return;
      }

      setIsLoading(true);

      try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/upload`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        if (response.data && response.data.image_file) {
          const image_file = response.data.image_file;
          saveMaterialInfo(image_file);
        } else {
          setIsLoading(false);
          toast({
            title: 'แจ้งเตือน',
            description: 'กรุณาเลือกภาพหน้าปก',
            status: 'warning',
            duration: 5000,
            isClosable: true,
          });
        }
      } catch (error) {
        setIsLoading(false);
        toast({
          title: 'เกิดข้อผิดพลาด',
          description: error.toString(),
          status: 'warning',
          duration: 5000,
          isClosable: true,
        });
      }
    } else {
      saveMaterialInfo('');
    }
  };

  const saveMaterialInfo = (img) => {
    var data =  {}; 
    
    if(jobType === "exercise"){
      data = {
        thumbnail: img,
        operation: operation,
        job_type: jobType,
        job_id: (editJob)?editJob.id:"",
        ipst_activity_id: activityID,
        media_title: mediaTitle,
        ipst_class_id: classLevel,
        ipst_subject_id: subject,
        ipst_exam_id: exercise,
        is_submit_once: submitOnce
      };
    } else if (jobType === "uploaded_material") {
      data = {
        thumbnail: img,
        operation: operation,
        job_type: jobType,
        job_id: (editJob)?editJob.id:"",
        ipst_activity_id: activityID,
        media_title: mediaTitle,
        material_pdf: materialPDF,
        material_file: materialFile
      };
    } else if (jobType === "text") {
      data = {
        thumbnail: img,
        operation: operation,
        job_type: jobType,
        job_id: (editJob)?editJob.id:"",
        ipst_activity_id: activityID,
        media_title: mediaTitle,
        material_text: materialText
      };
    }
    else if (jobType === "outside_material") {
      data = {
        thumbnail: img,
        operation: operation,
        job_type: jobType,
        job_id: (editJob)?editJob.id:"",
        ipst_activity_id: activityID,
        media_title: mediaTitle,
        material_url: materialURL
      };
    }
    else if (jobType === "youtube_exercise") {
      data = {
        thumbnail: img,
        operation: operation,
        job_type: jobType,
        job_id: (editJob)?editJob.id:"",
        ipst_activity_id: activityID,
        media_title: mediaTitle,
        material_text: materialText
      };
    }

    axios.post(`${process.env.REACT_APP_API_URL}/jobs/job_save`, data)
      .then(response => {
        const res = response.data;
        if (res && res.status === "success") {
          Swal.fire({
            title: 'Success!',
            text: "บันทึกข้อมูลเรียบร้อย",
            icon: 'success',
            padding: '2em'
          }).then(() => {
            onClose();
          });
        }
      })
      .catch(error => {
        Swal.fire({
          title: 'Error!',
          text: "Cannot save data to the server!",
          icon: 'warning',
          padding: '2em'
        });
      });
  };

  const uploadFile = async (file, setUploading) => {
    const formData = new FormData();
    formData.append('file', file);
  
    try {
      setUploading(true)
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/upload_file`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      setUploading(false)
  
      if (response.data && response.data.file) {
        return response.data.file;
      } else {
        throw new Error('File upload failed');
      }
    } catch (error) {
      toast({
        title: 'Error',
        description: `File upload failed: ${error.message}`,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      setUploading(false)
      return null;
    }
  };
  

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="4xl" scrollBehavior="inside">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>เพิ่มงาน</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Flex>
            <Box bgColor={"blue.50"} alignContent={"center"} textAlign={"center"} cursor={"pointer"} h={"160px"} flex="1" {...getRootProps()} border="2px dashed" borderColor="gray.200" p={4}>
              <input {...getInputProps()} type="file" accept="image/*" />
              {files.length === 0 && existingImage ? (
                <Image boxSize={"120px"} src={`${process.env.REACT_APP_API_URL}/images/${existingImage}`} alt="Existing Image" />
              ) : files.length > 0 ? (
                <Image boxSize={"120px"} src={files[0].preview} alt="Preview" />
              ) : (
                <VStack>
                  <Text>ภาพ Thumbnail</Text>
                  <Text fontSize='xs'>(กดเพื่อเพิ่ม)</Text>
                </VStack>
              )}
            </Box>

            <Box flex="3" ml={4}>
              <FormControl mb={4} isInvalid={formErrors.mediaTitle}>
                <FormLabel htmlFor="media_title">ชื่อแบบฝึกหัด/สื่อการเรียน</FormLabel>
                <Input id="media_title" placeholder="ชื่อแบบฝึกหัด/สื่อการเรียน" value={mediaTitle} onChange={(e) => setMediaTitle(e.target.value)} />
                {formErrors.mediaTitle && <Text color="red.500">{formErrors.mediaTitle}</Text>}
              </FormControl>

              <FormControl mb={4} isInvalid={formErrors.jobType}>
                <FormLabel htmlFor="job_select">ประเภทงาน</FormLabel>
                <Select id="job_select" value={jobType} onChange={handleJobTypeChange}>
                  <option value="">--- เลือกประเภทงาน ---</option>
                  <option value="exercise">แบบฝึกหัด</option>
                  <option value="youtube_exercise">แบบฝึกหัดจาก Youtube</option>
                  <option value="uploaded_material">สื่อการเรียน (อัพโหลดไฟล์)</option>
                  <option value="outside_material">สื่อการเรียน (ลิงค์)</option>
                  <option value="text">สื่อการเรียน (Free Text)</option>
                </Select>
                {formErrors.jobType && <Text color="red.500">{formErrors.jobType}</Text>}
              </FormControl>

              {jobType === 'exercise' && (
                <>
                  <FormControl mb={4} isInvalid={formErrors.classLevel}>
                    <FormLabel htmlFor="class_select">ระดับชั้น</FormLabel>
                    {isClassesLoading ? (
                      <Spinner size="sm" />
                    ) : (
                      <Select placeholder="เลือกระดับชั้น" 
                        onChange={(v) => { setClassLevel(v.target.value); }}
                        id="class_select" 
                        value={classLevel}>
                        {classes.map((cls, index) => (
                          <option key={index} value={cls.id}>{cls.name_thai}</option>
                        ))}
                      </Select>
                    )}
                    {formErrors.classLevel && <Text color="red.500">{formErrors.classLevel}</Text>}
                  </FormControl>
                  <FormControl mb={4} isInvalid={formErrors.subject}>
                    <FormLabel htmlFor="subject_select">วิชา</FormLabel>
                    {isSubjectsLoading ? (
                      <Spinner size="sm" />
                    ) : (
                      <Select placeholder="เลือกวิชา" value={subject}
                        onChange={(sub) => { setSubject(sub.target.value); }}
                      >
                        {subjects.map((subject, index) => (
                          <option key={index} value={subject.id}>{subject.subjectThai}</option>
                        ))}
                      </Select>
                    )}
                    {formErrors.subject && <Text color="red.500">{formErrors.subject}</Text>}
                  </FormControl>
                  <FormControl mb={4} isInvalid={formErrors.exercise}>
                    <FormLabel htmlFor="exercise_select">แบบฝึกหัด</FormLabel>
                    {isExerciseLoading ? (
                      <Spinner size="sm" />
                    ) : (
                      <Select placeholder="เลือกแบบฝึกหัด" 
                        id="exercise_select" 
                        value={exercise}
                        onChange={(sub) => { setExercise(sub.target.value); }}>
                        {exercises.map((ex, index) => (
                          <option key={index} value={ex.id}>{ex.exam_name}</option>
                        ))}
                      </Select>
                    )}
                    {formErrors.exercise && <Text color="red.500">{formErrors.exercise}</Text>}
                  </FormControl>
                  <FormControl mb={4}>
                    <FormLabel htmlFor="is_submit_once">รูปแบบการตอบคำถามของนักเรียน</FormLabel>
                    <Select id="is_submit_once" value={submitOnce} onChange={(e) => setSubmitOnce(e.target.value)}>
                      <option value="0">ส่งคำตอบได้หลายครั้ง</option>
                      <option value="1">ส่งคำตอบได้เพียงครั้งเดียว</option>
                    </Select>
                  </FormControl>
                </>
              )}

              {jobType === 'uploaded_material' && <FormControl mb={4} isInvalid={formErrors.materialPDF}>
                <FormLabel htmlFor="pdf_file">ไฟล์ PDF</FormLabel>
                {!uploadingPDF && !materialPDF && <Input
                  pt={2}
                  type="file"
                  id="pdf_file"
                  accept="application/pdf"
                  onChange={async (e) => {
                    const file = e.target.files[0];
                    if (file) {
                      const uploadedFile = await uploadFile(file, setUploadingPDF);
                      if (uploadedFile) {
                        setMaterialPDF(uploadedFile);
                      }
                    }
                  }}
                />}
                {uploadingPDF && <Spinner/>}
                {materialPDF && <Link href={`${process.env.REACT_APP_API_URL}/files/${materialPDF}`} isExternal>{materialPDF}</Link>}
                {formErrors.materialPDF && <Text color="red.500">{formErrors.materialPDF}</Text>}
              </FormControl>}

              {jobType === 'uploaded_material' && <FormControl mb={4} isInvalid={formErrors.materialFile}>
                <FormLabel htmlFor="all_file">ไฟล์ต้นฉบับ</FormLabel>
                {!uploadingMaterial && !materialFile && <Input
                  pt={2}
                  type="file"
                  id="all_file"
                  onChange={async (e) => {
                    const file = e.target.files[0];
                    if (file) {
                      const uploadedFile = await uploadFile(file, setUploadingMaterial);
                      if (uploadedFile) {
                        setMaterialFile(uploadedFile);
                      }
                    }
                  }}
                />}
                {uploadingMaterial && <Spinner/>}
                {materialFile && <Link href={`${process.env.REACT_APP_API_URL}/files/${materialFile}`} isExternal>{materialFile}</Link>}
                {formErrors.materialFile && <Text color="red.500">{formErrors.materialFile}</Text>}
              </FormControl>}

              {jobType === 'youtube_exercise' && (
                <FormControl mb={4} isInvalid={formErrors.materialText}>
                  <FormLabel htmlFor="youtube_id">Youtube ID</FormLabel>
                  <Input id="youtube_id" placeholder="เช่น kvMW6VkKFww" value={materialText} onChange={(e) => setMaterialText(e.target.value)} />
                  {formErrors.FormLabel && <Text color="red.500">{formErrors.FormLabel}</Text>}
                </FormControl>
              )}

              {jobType === 'outside_material' && (
                <FormControl mb={4} isInvalid={formErrors.materialURL}>
                  <FormLabel htmlFor="material_url">ลิงค์</FormLabel>
                  <Input id="material_url" placeholder="ลิงค์" value={materialURL} onChange={(e) => setMaterialURL(e.target.value)} />
                  {formErrors.materialURL && <Text color="red.500">{formErrors.materialURL}</Text>}
                </FormControl>
              )}

              {jobType === 'text' && (
                <>
                  <Button
                    onClick={() => setIsSourceMode(!isSourceMode)}
                    mb={4}
                    colorScheme="blue"
                    variant='outline'
                    size='sm'
                  >
                    {isSourceMode ? 'เปิดใน editor mode' : 'เปิดใน source mode'}
                  </Button>

                  {!isSourceMode ? (
                    <FormControl mb={4} isInvalid={formErrors.materialText}>
                      <Editor
                        apiKey="5r3v7xeymcjhuylsm9dqnjsvkihvroc3t5rvbm6ned87iihu"
                        onInit={(evt, editor) => (editorRef.current = editor)}
                        initialValue={materialText}
                        init={{
                          width: '100%',
                          height: 450,
                          menubar: 'file edit view format table mathmenu imgmenu',
                          menu: {
                            edit: {
                              title: 'Edit',
                              items: 'undo redo | cut copy paste pastetext | selectall',
                            },
                            insert: { title: 'Insert', items: 'link media | template hr' },
                            view: { title: 'View', items: 'visualaid' },
                            format: {
                              title: 'Format',
                              items:
                                'bold italic underline strikethrough superscript subscript | formats | removeformat',
                            },
                            table: {
                              title: 'Table',
                              items: 'inserttable tableprops deletetable | cell row column',
                            },
                            mathmenu: { title: 'Equation', items: 'mathitem' },
                          },
                          plugins: ['image', 'table', 'link', 'media', 'codesample'],
                          toolbar:
                            'undo redo | formatselect | bold italic backcolor | alignleft aligncenter ' +
                            'alignright alignjustify | bullist numlist outdent indent | removeformat |  help ',
                          content_style: 'body { font-family: Helvetica, Arial, sans-serif; font-size: 14px }',
                        }}
                      />
                      {formErrors.materialText && <Text color="red.500">{formErrors.materialText}</Text>}
                    </FormControl>
                  ) : (
                    <FormControl mb={4} isInvalid={formErrors.materialText}>
                      <Textarea
                        id="material_text"
                        rows="15"
                        value={materialText}
                        onChange={(e) => setMaterialText(e.target.value)}
                      />
                      {formErrors.materialText && <Text color="red.500">{formErrors.materialText}</Text>}
                    </FormControl>
                  )}
                </>
              )}

            </Box>
          </Flex>
        </ModalBody>

        <ModalFooter>
          <Button variant="ghost" onClick={onClose}>
            ปิด
          </Button>
          <Button colorScheme="blue" ml={3} onClick={handleSave}>
            บันทึก
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ManageJobModal;
*/