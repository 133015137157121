import React, { useEffect, useState } from 'react';
import { Flex, Text, Image, FormControl, Checkbox, CheckboxGroup, useColorModeValue } from '@chakra-ui/react';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import CardHeader from 'components/Card/CardHeader.js';
import ScienceSVG from 'assets/svg/science.svg';
import { getSubjects } from 'apis/subjectAPI';

const SubjectSelectionCard = ({setSelectedSubjectIDs}) => {
  const textColor = useColorModeValue('gray.700', 'white');
  const [checkedItems, setCheckedItems] = useState([]);
  const [subjects, setSubjects] = useState([]);

  useEffect(() => {
    async function loadSubjects(){
      const subs = await getSubjects()
      setSubjects(subs)
    }
    loadSubjects();
  },[])

  const handleChange = (checkedValues) => {
    setCheckedItems(checkedValues);
    setSelectedSubjectIDs(checkedValues)
  };

  return (
    <Card>
      <CardHeader>
        <Flex align="center">
          <Image src={ScienceSVG} w="34px" h="34px" me="14px" alignSelf="center" justifySelf="center" />
          <Flex direction="column">
            <Text fontSize="xl" color={textColor} fontWeight="bold">
              ชื่อรายวิชา
            </Text>
          </Flex>
        </Flex>
      </CardHeader>
      <CardBody>
        <Flex direction="column" mt="16px">
          <FormControl>
            <CheckboxGroup value={checkedItems} onChange={handleChange}>
              <Flex direction={'column'}>
              {subjects.map((s, index) => {
                return <Checkbox key={`sub${s.id}`} mb={2} value={`${s.id}`}>{s.subjectThai}</Checkbox>
              })}
                {/*<Checkbox mb={2} value="s0">คณิตศาสตร์มัธยมศึกษา</Checkbox>
                <Checkbox mb={2} value="s1">คณิตศาสตร์ประถมศึกษา</Checkbox>
                <Checkbox mb={2} value="s2">ฟิสิกส์</Checkbox>
                <Checkbox mb={2} value="s3">เคมี</Checkbox>
                <Checkbox mb={2} value="s4">ชีววิทยา</Checkbox>
                <Checkbox mb={2} value="s5">โลก ดาราศาสตร์ และอวกาศ</Checkbox>
                <Checkbox mb={2} value="s6">การออกแบบและเทคโนโลยี</Checkbox>
                <Checkbox mb={2} value="s7">เทคโนโลยี</Checkbox>
                <Checkbox mb={2} value="s8">วิทยาศาสตร์มัธยมศึกษาตอนต้น</Checkbox>
                <Checkbox mb={2} value="s9">วิทยาศาสตร์ประถมศึกษา</Checkbox>
                <Checkbox mb={2} value="s10">วิทยาการคำนวณ</Checkbox>*/}
              </Flex>
            </CheckboxGroup>
          </FormControl>
        </Flex>
      </CardBody>
    </Card>
  );
};

export default SubjectSelectionCard;
