import React, { useEffect, useState, useMemo } from 'react';
import axios from 'axios';
import {
    Flex,
    Text,
    Stack,
    Grid,
    Image,
    Button,
    Icon,
    Input,
    Box,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    useColorModeValue,
    IconButton
} from '@chakra-ui/react';
import { AddIcon, ArrowUpDownIcon } from '@chakra-ui/icons';

import Card from 'components/Card/Card';
import { HSeparator } from 'components/Separator/Separator';
import { useHistory } from 'react-router-dom';
import bgAutomotiveCard from 'assets/img/classroom-background-card.png';
import saturnImg from 'assets/img/saturn3.png';
import star1 from 'assets/img/star1.gif';
import star2 from 'assets/img/star2.gif';
import star3 from 'assets/img/star3.gif';
import star4 from 'assets/img/star4.gif';
import { useAuth } from 'auth/AuthProvider';
import TerrainAnimation from './TerrainAnimation';

const IPSTHeader = () => {
    const bgCard = useColorModeValue(
        'linear-gradient(81.62deg, #313860 2.25%, #151928 79.87%)',
        'navy.800'
    );
    return (
        <Box position="relative">
            <Card
                bgImage={bgAutomotiveCard}
                bgSize="cover"
                p={{ sm: '22px', lg: '60px 40px', xl: '100px 80px' }}
            >
                <Flex
                    justify="center"
                    align={{ sm: 'center', lg: 'normal' }}
                    w="100%"
                    direction={{ sm: 'column', lg: 'row' }}
                >
                    <Flex align={"center"} direction="column" my={{ sm: '10px', lg: '0px' }}>
                        <Text color="white" fontSize="4xl" fontWeight="bold">
                            MyIPST Classroom
                        </Text>
                        <HSeparator mt="11px" mb="25px" />

                        <Flex direction="row">
                            <Text color="white" fontSize="md">ระบบชันเรียนออนไลน์โดย สสวท.</Text>
                        </Flex>
                    </Flex>
                </Flex>
            </Card>

            <Box w="200px" position="absolute" top="50%" left="90%" transform="translate(-50%, -50%)">
                <Image src={saturnImg} />
            </Box>

            <Box w="20px" position="absolute" top="15%" right="10%" transform="translate(-50%, -50%)">
                <Image src={star1} />
            </Box>
            <Box w="15px" position="absolute" top="25%" right="18%" transform="translate(-50%, -50%)">
                <Image src={star2} />
            </Box>
            <Box w="10px" position="absolute" top="25%" right="5%" transform="translate(-50%, -50%)">
                <Image src={star3} />
            </Box>
            <Box w="10px" position="absolute" top="45%" right="20%" transform="translate(-50%, -50%)">
                <Image src={star4} />
            </Box>

            <TerrainAnimation widthPercent="100" heightPercent="100" starColor={"white"} />
        </Box>
    );
};

export default IPSTHeader;