import React, { useState } from 'react';
import { Flex, Text, FormControl, Checkbox, CheckboxGroup, VStack, useColorModeValue } from '@chakra-ui/react';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import CardHeader from 'components/Card/CardHeader.js';
import { SlackLogo } from 'components/Icons/Icons';
import { EDUCATION_LEVEL_OPTIONS, EDUCATION_LEVEL_OPTIONS_LONG_PREFIX } from 'variables/constant';

const GradeLevelSelectionCard = ({ setSelectedGradeLevels, useLongPrefix=false }) => {
  const textColor = useColorModeValue('gray.700', 'white');
  const [checkedItems, setCheckedItems] = useState([]);

  const handleChange = (checkedValues) => {
    setCheckedItems(checkedValues);
    setSelectedGradeLevels(checkedValues);
  };

  const data = (!useLongPrefix? EDUCATION_LEVEL_OPTIONS: EDUCATION_LEVEL_OPTIONS_LONG_PREFIX);
  const halfLength = Math.ceil(data.length / 2);
  const firstHalfOptions = data.slice(0, halfLength);
  const secondHalfOptions = data.slice(halfLength);

  return (
    <Card>
      <CardHeader>
        <Flex align="center">
          <SlackLogo w="34px" h="34px" me="14px" />
          <Flex direction="column">
            <Text fontSize="xl" color={textColor} fontWeight="bold">
              ระดับชั้นเรียน
            </Text>
          </Flex>
        </Flex>
      </CardHeader>
      <CardBody>
        <Flex direction="column" mt="16px">
          <FormControl>
            <CheckboxGroup value={checkedItems} onChange={handleChange}>
              <Flex direction="row">
                <VStack align="start" mr={5}>
                  {firstHalfOptions.map((option) => (
                    <Checkbox key={option.value} mb={2} value={option.value}>
                      {option.label}
                    </Checkbox>
                  ))}
                </VStack>
                <VStack align="start">
                  {secondHalfOptions.map((option) => (
                    <Checkbox key={option.value} mb={2} value={option.value}>
                      {option.label}
                    </Checkbox>
                  ))}
                </VStack>
              </Flex>
            </CheckboxGroup>
          </FormControl>
        </Flex>
      </CardBody>
    </Card>
  );
};

export default GradeLevelSelectionCard;
