import axios from 'axios';
import Swal from 'sweetalert2';

export const loadActivityCount = async () => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/dashboard/count_activity`, {}, { withCredentials: true });
    if (response.data && response.data.status === "success") {
      return response.data.count;
    }
  } catch (error) {
    Swal.fire('Error!', "Cannot connect to the server!", 'warning');
  }
};

export const loadActivitySetCount = async () => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/dashboard/count_activity_set`, {}, { withCredentials: true });
    if (response.data && response.data.status === "success") {
      return response.data.count;
    }
  } catch (error) {
    Swal.fire('Error!', "Cannot connect to the server!", 'warning');
  }
};

export const loadClassroomCount = async () => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/dashboard/count_classroom`, {}, { withCredentials: true });
    if (response.data && response.data.status === "success") {
      return response.data.count;
    }
  } catch (error) {
    Swal.fire('Error!', "Cannot connect to the server!", 'warning');
  }
};

export const loadLessonCount = async () => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/dashboard/count_lesson`, {}, { withCredentials: true });
    if (response.data && response.data.status === "success") {
      return response.data.count;
    }
  } catch (error) {
    Swal.fire('Error!', "Cannot connect to the server!", 'warning');
  }
};
