export const YOUTUBE_API_KEY = "AIzaSyAZH-xOF-DrAZk7n9-TemkMKjNKMjE986Y";
export const API_URL = "http://localhost:3300"

export const EDUCATION_LEVEL_OPTIONS_LONG_PREFIX = [
  {
    value: 'K',
    label: 'อนุบาลและปฐมวัย',
  },
  {
    value: 'PS1',
    label: 'ป.1',
  },
  {
    value: 'PS2',
    label: 'ป.2',
  },
  {
    value: 'PS3',
    label: 'ป.3',
  },
  {
    value: 'PS4',
    label: 'ป.4',
  },
  {
    value: 'PS5',
    label: 'ป.5',
  },
  {
    value: 'PS6',
    label: 'ป.6',
  },
  {
      value: 'M1',
      label: 'ม.1',
  },
  {
    value: 'M2',
    label: 'ม.2',
  },
  {
    value: 'M3',
    label: 'ม.3',
  },
  {
    value: 'M4',
    label: 'ม.4',
  },
  {
    value: 'M5',
    label: 'ม.5',
  },
  {
    value: 'M6',
    label: 'ม.6',
  },
];

export const EDUCATION_LEVEL_OPTIONS = [
    {
      value: 'K',
      label: 'อนุบาลและปฐมวัย',
    },
    {
      value: 'P1',
      label: 'ป.1',
    },
    {
      value: 'P2',
      label: 'ป.2',
    },
    {
      value: 'P3',
      label: 'ป.3',
    },
    {
      value: 'P4',
      label: 'ป.4',
    },
    {
      value: 'P5',
      label: 'ป.5',
    },
    {
      value: 'P6',
      label: 'ป.6',
    },
    {
        value: 'M1',
        label: 'ม.1',
    },
    {
      value: 'M2',
      label: 'ม.2',
    },
    {
      value: 'M3',
      label: 'ม.3',
    },
    {
      value: 'M4',
      label: 'ม.4',
    },
    {
      value: 'M5',
      label: 'ม.5',
    },
    {
      value: 'M6',
      label: 'ม.6',
    },
  ];
