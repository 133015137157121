import React, { useEffect, useState } from 'react';
import { Box, Flex, Text, Icon } from '@chakra-ui/react';
import { FaClipboardList, FaLayerGroup, FaChalkboardTeacher, FaBook } from 'react-icons/fa';
import { loadActivityCount, loadActivitySetCount, loadClassroomCount, loadLessonCount } from '../../../util/dashboardAPI';

const DashboardCounts = () => {
  const [counts, setCounts] = useState({
    activity: 0,
    activitySet: 0,
    classroom: 0,
    lesson: 0,
  });

  const formatNumber = (number) => {
    return new Intl.NumberFormat().format(number);
  };

  useEffect(() => {
    const fetchCounts = async () => {
      const activity = await loadActivityCount();
      const activitySet = await loadActivitySetCount();
      const classroom = await loadClassroomCount();
      const lesson = await loadLessonCount();

      setCounts({
        activity: activity || 0,
        activitySet: activitySet || 0,
        classroom: classroom || 0,
        lesson: lesson || 0,
      });
    };

    fetchCounts();
  }, []);

  return (
    <Flex justifyContent="space-around" p={4}>
      <Box textAlign="center">
        <Icon as={FaClipboardList} boxSize={6} color="blue.500" />
        <Text mt={2}>จำนวนกิจกรรมทั้งหมด</Text>
        <Text fontSize="2xl" fontWeight="bold">{formatNumber(counts.activity)}</Text>
      </Box>
      <Box textAlign="center">
        <Icon as={FaLayerGroup} boxSize={6} color="green.500" />
        <Text mt={2}>จำนวนชุดกิจกรรมทั้งหมด</Text>
        <Text fontSize="2xl" fontWeight="bold">{formatNumber(counts.activitySet)}</Text>
      </Box>
      <Box textAlign="center">
        <Icon as={FaChalkboardTeacher} boxSize={6} color="purple.500" />
        <Text mt={2}>จำนวนชั้นเรียนทั้งหมด</Text>
        <Text fontSize="2xl" fontWeight="bold">{formatNumber(counts.classroom)}</Text>
      </Box>
      <Box textAlign="center">
        <Icon as={FaBook} boxSize={6} color="orange.500" />
        <Text mt={2}>จำนวนบทเรียนทั้งหมด</Text>
        <Text fontSize="2xl" fontWeight="bold">{formatNumber(counts.lesson)}</Text>
      </Box>
    </Flex>
  );
};

export default DashboardCounts;
