import { YOUTUBE_API_KEY } from "variables/constant";

export function parseDuration(duration) {
    const match = duration.match(/PT(\d+H)?(\d+M)?(\d+S)?/);
    const hours = parseInt(match[1]) || 0;
    const minutes = parseInt(match[2]) || 0;
    const seconds = parseInt(match[3]) || 0;
    const totalSeconds = hours * 3600 + minutes * 60 + seconds;
    return totalSeconds;
}

export function secondsToMMSS(seconds) {
  // Calculate hours, minutes, and remaining seconds
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;

  // Add leading zero if necessary
  const formattedHours = String(hours).padStart(2, '0');
  const formattedMinutes = String(minutes).padStart(2, '0');
  const formattedSeconds = String(remainingSeconds).padStart(2, '0');

  if (hours > 0) {
      return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  } else {
      return `${formattedMinutes}:${formattedSeconds}`;
  }
}

export function shortenText (text, maxLength) {
    if (text.length <= maxLength) {
        return text;
    } else {
        return text.substring(0, maxLength) + '...';
    }
}

export async function getVideoDetails(videoId) {
    //const apiKey = 'AIzaSyC3MTeIatVPZolAxKJvlvfphTfVBoPFhjc'; // Replace with your API key
    const apiUrl = `https://www.googleapis.com/youtube/v3/videos?id=${videoId}&key=${YOUTUBE_API_KEY}&part=snippet,contentDetails`;

    try {
      const response = await fetch(apiUrl);
      const data = await response.json();

      // Extract relevant information from the response
      const videoInfo = {
        id: videoId,
        title: data.items[0].snippet.title,
        thumbnailPath: data.items[0].snippet.thumbnails.default.url,
        duration: parseDuration(data.items[0].contentDetails.duration),
        description: data.items[0].snippet.description,
      };

      console.log(data.items[0].snippet)

      return videoInfo;
    } catch (error) {
      console.error('Error fetching video details:', error);
      return null;
    }
  }