/*!

=========================================================
* Argon Dashboard Chakra PRO - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-chakra-pro
* Copyright 2022 Creative Tim (https://www.creative-tim.com/)

* Designed and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import { mode } from "@chakra-ui/theme-tools";


export const globalStyles = {
  colors: {
    gray: {
      700: "#1f2733",
    },
    navy: {
      50: "#d0dcfb",
      100: "#aac0fe",
      200: "#a3b9f8",
      300: "#728fea",
      400: "#3652ba",
      500: "#1b3bbb",
      600: "#24388a",
      700: "#1b254b",
      800: "#111c44",
      900: "#0b1437",
    },
  },
  fonts: {
    anuphan: "'Anuphan', sans-serif",
    anuphanBold: "'Anuphan-Bold', sans-serif",
    anuphanBoldItalic: "'Anuphan-BoldItalic', sans-serif",
    anuphanExtraBold: "'Anuphan-ExtraBold', sans-serif",
    anuphanExtraBoldItalic: "'Anuphan-ExtraBoldItalic', sans-serif",
    anuphanExtraLight: "'Anuphan-ExtraLight', sans-serif",
    anuphanExtraLightItalic: "'Anuphan-ExtraLightItalic', sans-serif",
    anuphanItalic: "'Anuphan-Italic', sans-serif",
    anuphanLight: "'Anuphan-Light', sans-serif",
    anuphanLightItalic: "'Anuphan-LightItalic', sans-serif",
    anuphanMedium: "'Anuphan-Medium', sans-serif",
    anuphanMediumItalic: "'Anuphan-MediumItalic', sans-serif",
    anuphanRegular: "'Anuphan-Regular', sans-serif",
    anuphanSemiBold: "'Anuphan-SemiBold', sans-serif",
    anuphanSemiBoldItalic: "'Anuphan-SemiBoldItalic', sans-serif",
    anuphanThin: "'Anuphan-Thin', sans-serif",
    anuphanThinItalic: "'Anuphan-ThinItalic', sans-serif",
  },
  styles: {
    global: (props) => ({
      body: {
        overflowX: "hidden",
        bg: mode("gray.50", "#1B254B")(props),
        fontFamily: "anuphan",
      },
      html: {
        fontFamily: "anuphan",
      },
      ".default": {
        fontFamily: "'Anuphan'",
        src: "url('font/Anuphan/Anuphan-Regular.ttf')",
        fontWeight: "normal",
        fontStyle: "normal",
      },
      // Define @font-face rules for each variant of the Anuphan font
      "@font-face": {
        fontFamily: "'Anuphan'",
        src: "url('font/Anuphan/Anuphan-Regular.ttf')",
        fontWeight: "normal",
        fontStyle: "normal",
      },
      "@font-face": {
        fontFamily: "'Anuphan-Bold'",
        src: "url('font/Anuphan/Anuphan-Bold.ttf')",
        fontWeight: "bold",
        fontStyle: "normal",
      },
      "@font-face": {
        fontFamily: "'Anuphan-Light'",
        src: "url('font/Anuphan/Anuphan-Light.ttf')",
        fontWeight: "300",
        fontStyle: "normal",
      },
      "@font-face": {
        fontFamily: "'Anuphan-Medium'",
        src: "url('font/Anuphan/Anuphan-Medium.ttf')",
        fontWeight: "500",
        fontStyle: "normal",
      },
      "@font-face": {
        fontFamily: "'Anuphan-SemiBold'",
        src: "url('font/Anuphan/Anuphan-SemiBold.ttf')",
        fontWeight: "600",
        fontStyle: "normal",
      },
      "@font-face": {
        fontFamily: "'Anuphan-Thin'",
        src: "url('font/Anuphan/Anuphan-Thin.ttf')",
        fontWeight: "100",
        fontStyle: "normal",
      },
      // Define @font-face rules for other variants similarly
      // Make sure to update the paths accordingly
    }),
  },
};


/*export const globalStyles = {
  colors: {
    gray: {
      700: "#1f2733",
    },
    navy: {
      50: "#d0dcfb",
      100: "#aac0fe",
      200: "#a3b9f8",
      300: "#728fea",
      400: "#3652ba",
      500: "#1b3bbb",
      600: "#24388a",
      700: "#1b254b",
      800: "#111c44",
      900: "#0b1437",
    },
  },
  fonts: {
    anuphan: "'Anuphan', sans-serif",
    anuphanBold: "'Anuphan-Bold', sans-serif",
    anuphanLight: "'Anuphan-Light', sans-serif",
    anuphanMedium: "'Anuphan-Medium', sans-serif",
    anuphanRegular: "'Anuphan-Regular', sans-serif",
    anuphanSemiBold: "'Anuphan-SemiBold', sans-serif",
    anuphanThin: "'Anuphan-Thin', sans-serif"
  },
  styles: {
    global: (props) => ({
      body: {
        overflowX: "hidden",
        bg: mode("gray.50", "#1B254B")(props),
        fontFamily: "anuphan",
      },
      html: {
        fontFamily: "anuphan",
      },
      // Define @font-face rules for each variant of the Anuphan font
      "@font-face": {
        fontFamily: "'Anuphan'",
        src: "url('font/Anuphan/Anuphan-Regular.ttf')",
        fontWeight: "normal",
        fontStyle: "normal",
      },
      "@font-face": {
        fontFamily: "'Anuphan-Bold'",
        src: "url('font/Anuphan/Anuphan-Bold.ttf')",
        fontWeight: "bold",
        fontStyle: "normal",
      },
      "@font-face": {
        fontFamily: "'Anuphan-Bold'",
        src: "url('font/Anuphan/Anuphan-Bold.ttf')",
        fontWeight: "800",
        fontStyle: "normal",
      },
      "@font-face": {
        fontFamily: "'Anuphan-Light'",
        src: "url('font/Anuphan/Anuphan-Light.ttf')",
        fontWeight: "300",
        fontStyle: "normal",
      },
      "@font-face": {
        fontFamily: "'Anuphan-LightItalic'",
        src: "url('font/Anuphan/Anuphan-LightItalic.ttf')",
        fontWeight: "300",
        fontStyle: "italic",
      },
      "@font-face": {
        fontFamily: "'Anuphan-Medium'",
        src: "url('font/Anuphan/Anuphan-Medium.ttf')",
        fontWeight: "500",
        fontStyle: "normal",
      },
      "@font-face": {
        fontFamily: "'Anuphan-SemiBold'",
        src: "url('font/Anuphan/Anuphan-SemiBold.ttf')",
        fontWeight: "600",
        fontStyle: "normal",
      },
      "@font-face": {
        fontFamily: "'Anuphan-Thin'",
        src: "url('font/Anuphan/Anuphan-Thin.ttf')",
        fontWeight: "100",
        fontStyle: "normal",
      },
    }),
  },
};*/
