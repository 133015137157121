

import React, { useEffect } from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    Input,
    useToast,
} from '@chakra-ui/react';
import axios from 'axios';
import { API_URL } from 'variables/constant';

const AddActivityGroupModal = ({ isOpen, onClose, activityGroupID, activityGroupName, activityGroupStatus, lessonID, setActivityGroupName, isEditing, editingIndex, setLessons }) => {
    const toast = useToast();

    useEffect(() => {
        if (isEditing && isOpen) {
            setActivityGroupName(activityGroupName);
        } else {
            setActivityGroupName('');
        }
    }, [isEditing, isOpen]);

    const handleSaveActivity = async () => {
        if (!activityGroupName) {
            toast({
                title: "กรุณากรอกชื่อชุดกิจกรรม",
                status: "warning",
                duration: 3000,
                isClosable: true,
            });
            return;
        }

        const payload = {
            opt: isEditing ? 'edit' : 'insert',
            group_id: isEditing ? activityGroupID : null,
            lesson_id: lessonID,
            group_name: activityGroupName,
            status: activityGroupStatus, // default status; adjust as necessary
        };

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/activity_group/new_activity_group_insert`, payload);
            if (response.data && response.data.status === "success") {
                if (isEditing) {
                    // Update the specific activity group in the lessons state
                    setLessons(prevLessons => {
                        const updatedLessons = [...prevLessons];
                        updatedLessons[editingIndex].group_name = activityGroupName;
                        return updatedLessons;
                    });
                } else {
                    // Reload the lessons or add the new activity group to the state
                    // This might depend on how you want to handle the new data in your parent component
                    setLessons(prevLessons => [
                        ...prevLessons,
                        { id: response.data.activity_group_id, group_name: activityGroupName, status: 'draft', activities: [] }
                    ]);
                }

                toast({
                    title: "บันทึกข้อมูลเรียบร้อย",
                    status: "success",
                    duration: 3000,
                    isClosable: true,
                });
                onClose();
            } else {
                throw new Error("Failed to save the activity group");
            }
        } catch (error) {
            toast({
                title: 'Error!',
                description: "Cannot connect to the server!",
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
        }
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>{isEditing ? 'แก้ไขชุดกิจกรรม' : 'เพิ่มชุดกิจกรรม'}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Input
                        placeholder="ชื่อชุดกิจกรรม"
                        value={activityGroupName}
                        onChange={(e) => setActivityGroupName(e.target.value)}
                    />
                </ModalBody>
                <ModalFooter>
                    <Button colorScheme="blue" mr={3} onClick={handleSaveActivity}>
                        {isEditing ? 'บันทึก' : 'เพิ่ม'}
                    </Button>
                    <Button variant="ghost" onClick={onClose}>ยกเลิก</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default AddActivityGroupModal;


/*import React, { useEffect } from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    Input,
} from '@chakra-ui/react';

const AddActivityModal = ({ isOpen, onClose, activityGroupName, lessonID, setActivityGroupName, handleSaveActivity, isEditing }) => {
    useEffect(() => {
        if (isEditing && isOpen) {
            setActivityGroupName(activityGroupName);
        } else {
            setActivityGroupName('');
        }
    }, [isEditing, isOpen]);

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>{isEditing ? 'แก้ไขชุดกิจกรรม' : 'เพิ่มชุดกิจกรรม'}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Input
                        placeholder="ชื่อชุดกิจกรรม"
                        value={activityGroupName}
                        onChange={(e) => setActivityGroupName(e.target.value)}
                    />
                </ModalBody>
                <ModalFooter>
                    <Button colorScheme="blue" mr={3} onClick={handleSaveActivity}>
                        {isEditing ? 'บันทึก' : 'เพิ่ม'}
                    </Button>
                    <Button variant="ghost" onClick={onClose}>ยกเลิก</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default AddActivityModal;
*/